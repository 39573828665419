import { RootState, StoreState } from './../store';
import axios from 'axios';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { AnyAction, Dispatch } from 'redux';

import StoreTypes from '../StoreTypes';
import DispatchPayload from '../DispatchPayload';
import { AuthState } from '../reducers/AuthReducer';
import { LoginResponse } from '../../shared/types/response/LoginResponse';
import { GroupResponse } from '../../shared/types/response/GroupResponse';
import { AzureTokenData } from '../../shared/types/auth/AzureTokenData';

const baseUrl = window.appConfig.BASE_API_URL || 'http://localhost';

const LOGIN_ENDPOINT = `${baseUrl}/api/v1/auth/login`;
const REFRESH_ENDPOINT = `${baseUrl}/api/v1/auth/refresh`;
const LOGOUT_ENDPOINT = `${baseUrl}/api/v1/auth/logout`;

const GROUP_ENDPOINT = `${baseUrl}/api/v1/auth/groups`;

/**
 * Handles logging into the platform
 * @param result
 */
export function login(
  result: AzureTokenData
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    const returned = await axios.post<LoginResponse>(LOGIN_ENDPOINT, result);

    if (!returned) {
      dispatch({
        type: StoreTypes.LOGIN_FAILED,
        payload: {
          success: false,
          message: 'Login with Azure AD failed'
        }
      } as DispatchPayload);

      return;
    }

    const data = returned.data;

    if (!data.success) {
      dispatch({
        type: StoreTypes.LOGIN_FAILED,
        payload: {
          success: false,
          message: 'Login with Azure AD failed'
        }
      } as DispatchPayload);

      return;
    }

    dispatch({
      type: StoreTypes.LOGIN_SUCCESS,
      payload: {
        success: true,
        message: 'Logged in successfully',
        data: data
      }
    } as DispatchPayload);
  };
}

/**
 * Handles logging out of Omni
 */
export function logout(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      // Handle logout
      await axios.post(LOGOUT_ENDPOINT);
    } catch (error) {
      // Ignored
    }

    dispatch({
      type: StoreTypes.LOGOUT_SUCCESS,
      payload: {
        success: true,
        message: 'Logged out'
      }
    } as DispatchPayload);
  };
}

/**
 * Handles logging out of Omni
 */
export function refresh(): (
  dispatch: Dispatch,
  getState: () => StoreState
) => Promise<void> {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    const auth = getState().auth;
    await asyncRefresh(auth, dispatch);
  };
}

export async function asyncRefresh(
  auth: AuthState,
  dispatch: Dispatch<AnyAction>
): Promise<AuthState | undefined> {
  try {
    // Validates refresh token time and if expired log out forcefully
    if (
      !auth.refreshToken ||
      (auth.refreshTokenExpiry &&
        Date.now() >= parseInt(auth.refreshTokenExpiry))
    ) {
      dispatch({
        type: StoreTypes.REFRESH_FAILED,
        payload: {
          success: false,
          message: 'Refreshing token failed'
        }
      } as DispatchPayload);
      return;
    }

    const post = {
      token: auth.token,
      refreshToken: auth.refreshToken
    };

    const response = await axios.post<LoginResponse>(REFRESH_ENDPOINT, post);
    const data = response.data;

    // Verifies integrity, if it can't get a new token, chances are refresh has expired and it should logout
    if (!data.success) {
      dispatch({
        type: StoreTypes.REFRESH_FAILED,
        payload: {
          success: false,
          message: 'Refreshing token failed'
        }
      } as DispatchPayload);
      return;
    }

    const finalData = auth;
    finalData.token = data.token;
    finalData.tokenExpiry = data.tokenExpiry;
    finalData.refreshToken = data.refreshToken;
    finalData.refreshTokenExpiry = data.refreshTokenExpiry;

    dispatch({
      type: StoreTypes.REFRESH_SUCCESS,
      payload: {
        success: true,
        message: 'Refreshed token',
        data: finalData
      }
    } as DispatchPayload);

    return finalData;
  } catch (e) {
    console.error(e);
    dispatch({
      type: StoreTypes.REFRESH_FAILED,
      payload: {
        success: false,
        message: 'Refreshing token failed'
      }
    } as DispatchPayload);
  }
}

/**
 * Handles getting the accounts current group
 */
export function getLatestGroup(): (
  dispatch: Dispatch,
  getState: () => StoreState
) => Promise<void> {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    const auth = getState().auth;

    if (!auth.account) {
      dispatch({
        type: StoreTypes.GET_GROUP_FAILED,
        payload: {
          success: false,
          message: 'Could not get group'
        }
      } as DispatchPayload);
      return;
    }

    try {
      const response = await axios.get<GroupResponse>(
        `${GROUP_ENDPOINT}/account/${auth.account.id}`
      );
      const data = response.data;

      const group = data.group;
      const scope: string[] = data.scope;

      dispatch({
        type: StoreTypes.GET_GROUP_SUCCESS,
        payload: {
          success: true,
          message: 'Updated group successfully',
          data: {
            group: group,
            scope: scope
          }
        }
      } as DispatchPayload);
    } catch (err) {
      console.error(err);
      dispatch({
        type: StoreTypes.GET_GROUP_FAILED,
        payload: {
          success: false,
          message: 'Could not get group'
        }
      } as DispatchPayload);
    }
  };
}
