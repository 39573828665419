import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SERVICE_ENDPOINT } from '../../../constants';
import { Badge } from '../../../shared/components/Badge';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { CheckResult } from '../../../shared/types/provisioning/CheckResult';
import {
  Service,
  ServiceState
} from '../../../shared/types/provisioning/Service';
import { ServiceModal } from './ServiceModal';

export function Services() {
  useDocumentTitle('Services');
  const navigate = useNavigate();
  const [adding, setAdding] = useState(false);

  function hasCheckResult(service: Service, result: string) {
    if (service.lastCheck) {
      const checks = JSON.parse(service.lastCheck?.result) as Record<
        string,
        CheckResult
      >;

      if (checks) {
        return !!Object.keys(checks).find((x) => checks[x].result === result);
      }
    }

    return false;
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          title="Services"
          searchPrompt="Find a service..."
          endpoint={SERVICE_ENDPOINT}
          columns={['ID', 'Device', 'Product', 'State', 'Health', '']}
          generateRow={(result: Service) => [
            <>
              <Link to={`/provisioning/services/${result.id}`}>
                {ProvisioningIdPrefix.SVC}-{result.id}
              </Link>{' '}
              {result.device?.test && <Badge>TEST</Badge>}
            </>,
            <>
              <Link to={`/provisioning/devices/${result.deviceId}`}>
                {ProvisioningIdPrefix.DEV}-{result.deviceId}
              </Link>{' '}
              <span className="text-muted">({result.device?.serial})</span>
            </>,
            <>
              <Link to={`/provisioning/products/${result.productId}`}>
                {ProvisioningIdPrefix.PRD}-{result.productId}
              </Link>{' '}
              <span className="text-muted">({result.product?.name})</span>
            </>,
            <>
              <Badge
                type={
                  result.state === ServiceState.NOT_PROVISIONED
                    ? undefined
                    : result.state === ServiceState.ERROR
                    ? 'red'
                    : result.state === ServiceState.PROVISIONED
                    ? 'green'
                    : 'blue'
                }
              >
                {result.state}
              </Badge>
            </>,
            <>
              {result.lastCheck && result.lastCheck.result && (
                <>
                  {hasCheckResult(result, 'ERROR') ? (
                    <Badge type="red">ERROR</Badge>
                  ) : hasCheckResult(result, 'WARNING') ? (
                    <Badge type="yellow">WARNING</Badge>
                  ) : (
                    <Badge type="green">HEALTHY</Badge>
                  )}
                </>
              )}
            </>
          ]}
          newButtonText="New Service"
          onNewButtonClick={() => setAdding(true)}
        />
      </div>

      {adding && (
        <ServiceModal
          onClose={(newId?: number) => {
            setAdding(false);

            if (newId) {
              navigate(`/provisioning/services/${newId}`);
            } else {
              navigate('/provisioning/services');
            }
          }}
        />
      )}
    </>
  );
}
