import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { hasAccess } from '../../helpers';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';
import { SearchComponent } from './SearchComponent';

const links = [
  {
    name: 'Dashboard',
    path: '/'
  },
  {
    name: 'Provision',
    path: '/provisioning',
    navigate: false,
    permission: 'provisioning:panel.view',
    subnavigation: [
      {
        name: 'services',
        path: '/provisioning/services',
        permission: 'provisioning:service.get'
      },

      {
        name: 'devices',
        path: '/provisioning/devices',
        permission: 'provisioning:device.get'
      },
      {
        name: 'device types',
        path: '/provisioning/device-types',
        permission: 'provisioning:devicetype.get'
      },
      {
        name: 'products',
        path: '/provisioning/products',
        permission: 'provisioning:product.get'
      },
      {
        name: 'manufacturers',
        path: '/provisioning/manufacturers',
        permission: 'provisioning:manufacturer.get'
      },
      {
        name: 'olts',
        path: '/provisioning/olts',
        permission: 'provisioning:olt.get'
      }
      // {
      //   name: 'switches',
      //   path: '/provisioning/switches',
      //   permission: 'provisioning:switch.get'
      // }
      // {
      //   name: 'attributes',
      //   path: '/provisioning/attributes',
      //   permission: 'provisioning:attribute.get'
      // }
    ]
  },
  {
    name: 'Monitoring',
    path: '/monitoring',
    permission: 'monitoring:panel.view',
    subnavigation: [
      {
        name: 'incidents',
        path: '/monitoring/incidents',
        permission: 'monitoring:incident.get'
      }
    ]
  },
  {
    name: 'Build',
    path: '/build',
    permission: 'netbox:panel.view',
    subnavigation: [
      {
        name: 'clone netbox template',
        path: '/build/clone',
        permission: 'netbox:general.get'
      }
    ]
  },
  {
    name: 'Admin',
    path: '/admin',
    permission: 'admin:panel.view',
    subnavigation: [
      {
        name: 'users',
        path: '/admin/users',
        permission: 'auth:accounts.get'
      },
      {
        name: 'applications',
        path: '/admin/applications',
        permission: 'auth:applications.get'
      },
      {
        name: 'groups',
        path: '/admin/groups',
        permission: 'auth:groups.get'
      },
      {
        name: 'roles',
        path: '/admin/roles',
        permission: 'auth:roles.get'
      },
      {
        name: 'settings',
        path: '/admin/settings',
        permission: 'auth:settings.get'
      }
    ]
  }
];

export function Navigation() {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  function renderSubmenu() {
    const link = links.find((x) =>
      x.path === '/'
        ? location.pathname === x.path
        : location.pathname.startsWith(x.path)
    );

    if (!link || !link.subnavigation) {
      return <></>;
    }

    return (
      <div className="subnavigation-wrapper">
        <div className="subnavigation-content">
          <ul className="subnavigation-links">
            {link.subnavigation
              .filter(
                (x) =>
                  !x.permission ||
                  (auth.account && hasAccess(auth.account, [x.permission]))
              )
              .map((x) => (
                <li
                  key={x.path}
                  className={`${
                    (
                      x.path === '/'
                        ? location.pathname === x.path
                        : location.pathname.startsWith(x.path)
                    )
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => navigate(x.path)}
                >
                  {x.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="navigation-wrapper">
        <div className="navigation-content">
          <div>
            <Link to="/">
              <img src={logo} width={80} />
            </Link>
          </div>

          <ul className="navigation-links">
            {links
              .filter(
                (x) =>
                  !x.permission ||
                  (auth.account && hasAccess(auth.account, [x.permission]))
              )
              .map((x) => (
                <li
                  key={x.path}
                  className={`${
                    (
                      x.path === '/'
                        ? location.pathname === x.path
                        : location.pathname.startsWith(x.path)
                    )
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    navigate(
                      x.subnavigation && x.subnavigation.length > 0
                        ? x.subnavigation[0].path
                        : x.path
                    )
                  }
                >
                  {x.name}
                </li>
              ))}
          </ul>

          {location.pathname !== '/search' && <SearchComponent />}
        </div>
      </div>
      {renderSubmenu()}
    </>
  );
}
