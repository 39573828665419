import { Icon } from '@iconify/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = window.appConfig.BASE_API_URL || 'http://localhost';
export const SEARCH_ENDPOINT = `${baseUrl}/api/v1/search`;

type ObjectsResponse = Response & {
  objects: SearchObject[];
};

export type Property = {
  keyword: string;
  property?: string;
};

type SearchData = {
  keywords: Property[];
  in?: string;
};

export type Result = {
  object: string;
  id: string;
  display: string;
  url: string;
};

type SearchResponse = Response & {
  results: Result[];
  total: number;
};

export type SearchObject = {
  name: string;
  properties: string[];
};

const locationDict: { [object: string]: string } = {
  manufacturer: '/provisioning/manufacturers/{id}',
  devicetype: '/provisioning/device-types/{id}',
  device: '/provisioning/devices/{id}',
  product: '/provisioning/products/{id}',
  service: '/provisioning/services/{id}/view',
  user: '/admin/users/{id}/view',
  group: '/admin/groups/{id}',
  role: '/admin/roles/{id}'
};

type SearchComponentProps = {
  external?: boolean;
  page?: number;
  _object?: SearchObject;
  _properties?: Property[];
  _value?: string;
  onNewResults?: (results: Result[]) => void;
  onNewTotalResults?: (totalResults: number) => void;
  onSearchChange?: () => void;
};

export function SearchComponent({
  external,
  page,
  _object,
  _properties,
  _value,
  onNewResults,
  onNewTotalResults,
  onSearchChange
}: SearchComponentProps): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(_value || '');
  const navigate = useNavigate();
  const [availableObjects, setAvailableObjects] = useState<SearchObject[]>([]);
  const [object, setObject] = useState<SearchObject | undefined>(_object);
  const [properties, setProperties] = useState<Property[]>(_properties || []);
  const [selected, setSelected] = useState(0);
  const [results, setResults] = useState<Result[]>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (_object || _properties || _value) {
      void search();
    }
  }, [_object, _properties, _value]);

  useEffect(() => {
    function onKeyPress(e: KeyboardEvent) {
      if (e.key === '/') {
        if (searchRef && searchRef.current) {
          // If the / key is pressed, focus the input and open the dropdown
          searchRef.current.focus();
          setOpen(true);
        }
      }
    }

    function onClick(e: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        // If a click occures outside the search bar, close it
        setOpen(false);
      }
    }

    window.addEventListener('keyup', onKeyPress);
    window.addEventListener('mouseup', onClick);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
      window.removeEventListener('mouseup', onClick);
    };
  }, []);

  useEffect(() => {
    // Get all searchable objects and properties
    async function load() {
      const response = await axios.get<ObjectsResponse>(
        `${SEARCH_ENDPOINT}/objects`
      );
      setAvailableObjects(response.data.objects);
      setSelected(0);
    }
    void load();
  }, []);

  useEffect(() => {
    // Sort available objects by the text being entered into the field
    if (availableObjects && Object.keys(availableObjects).length > 0) {
      if (value !== '') {
        setAvailableObjects(
          availableObjects.sort((_a, b) =>
            b.name.includes(value.includes(':') ? value.split(':')[1] : value)
              ? 1
              : -1
          )
        );
      }
    }

    if (results) {
      setResults(undefined);
    }

    if (searchRef && searchRef.current) {
      searchRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }, [availableObjects, value]);

  /**
   * Check if enough information is specified to run a search
   */
  function canSearch() {
    if (value || object) {
      return true;
    }

    return false;
  }

  /**
   * Add the specified object to the search
   */
  function addObject(object: SearchObject) {
    setValue('');
    setObject(object);
    setSelected(0);
    searchRef.current?.focus();

    if (onSearchChange) {
      onSearchChange();
    }
  }

  /**
   * Add the specified property to the search
   */
  function addProperty(
    property: string,
    valueOverride?: string,
    propertiesOverride?: Property[]
  ) {
    // Set values or overriden values if the state would not have updated in time
    let _value = valueOverride !== undefined ? valueOverride : value;
    let _properties =
      propertiesOverride !== undefined ? propertiesOverride : [...properties];

    if (!_value) {
      // If no value is entered into the field then just add the text "{property}:" ready for the user to enter a value
      _value = `${property}:`;
      setValue(_value);
    } else {
      // If a value is entered, add the property to the search
      _properties = [
        ..._properties,
        {
          // The property would be specified with the format {property}:{value}
          keyword: _value.includes(':') ? _value.split(':')[1] : _value,
          property
        }
      ];

      setProperties(_properties);
      _value = '';
      setValue(_value);
    }

    // Reset the selected element in the dropdown and focus in the input for the user to resume typing
    setSelected(0);
    searchRef.current?.focus();

    // Push a search change to any parent components
    if (onSearchChange) {
      onSearchChange();
    }

    // Return updated values incase they are required before a state update
    return { value: _value, properties: _properties };
  }

  /**
   * Remove the object from the search
   */
  function removeObject() {
    setObject(undefined);

    // Because each object has different properties, they must also be reset ready for the next object to be selected
    setProperties([]);
    setSelected(0);
    setResults(undefined);

    // Push a search change to any parent components
    if (onSearchChange) {
      onSearchChange();
    }
  }

  /**
   * Remove the specified property from the search
   */
  function removeProperty(property: Property) {
    setProperties(
      properties.filter(
        (x) =>
          x.property !== property.property && x.keyword !== property.keyword
      )
    );
    setSelected(0);
    setResults(undefined);

    // Push a search change to any parent components
    if (onSearchChange) {
      onSearchChange();
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.code === 'Backspace' && value === '') {
      // If backspace is pressed while there is no text value, remove properties or object blocks if able
      if (properties.length > 0) {
        removeProperty(properties[properties.length - 1]);
      } else {
        removeObject();
      }
    }

    if (e.code === 'ArrowDown') {
      // If the down arrow is pressed, move the selection further down the dropdown
      e.preventDefault();
      // Calculate how many options are displaying in the dropdown
      const max =
        results && results.length > 0
          ? results.length - 1
          : (canSearch() ? 1 : 0) +
            (object && object.properties.length > 0
              ? object.properties.length
              : availableObjects.length < 3
                ? availableObjects.length
                : 2);

      setSelected(selected + 1 > max ? max : selected + 1);
    }

    if (e.code === 'ArrowUp') {
      // If the up arrow is pressed, move the selection further up the dropdown
      e.preventDefault();
      setSelected(selected - 1 < 0 ? 0 : selected - 1);
    }

    if (e.code === 'Tab' || e.code === 'Enter') {
      // If tab or enter is pressed
      e.preventDefault();

      // If the search field is visible and selected, run a search
      if (canSearch() && selected === 0) {
        const { value, object, properties, success } = parseValueForData();

        if (success) {
          e.preventDefault();
        }

        if (results) {
          // If there are rendered results that are selected, navigate to that result
          navigate(resolveUrl(results[selected]));
          setOpen(false);
        } else {
          // If there are no results yet, run a search
          void search(value, object, properties);
        }
      } else {
        if (object && object.properties.length > 0) {
          // Get the currently highlighted property
          const property = object.properties[selected + (canSearch() ? -1 : 0)];
          addProperty(property);
        } else {
          // Get the currently selected object
          const object = availableObjects[selected + (canSearch() ? -1 : 0)];
          addObject(object);
        }
      }
    }

    if (e.code === 'Space') {
      // If space is pressed, parse the data to see if any objects or properties are present
      if (parseValueForData().success) {
        e.preventDefault();
      }
    }
  }

  /**
   * Parse the raw text value to see if any valid objects or properties are present, such as in:{object} or {property}:{value}
   */
  function parseValueForData(valueOverride?: string) {
    let _value = value ? `${value}` : undefined;
    let _object = object;
    let _properties = [...properties];
    let success = false;

    const v = valueOverride !== undefined ? valueOverride : value;

    if (v.includes(' ')) {
      // If there are spaces, parse each word separately
      const words = v.split(' ');

      const parsedWords = [];

      for (const word of words) {
        parsedWords.push(parseWord(word));
      }

      _value = parsedWords.filter((x) => !!x).join(' ');
    } else {
      // Otherwise just parse the whole value as one word
      _value = parseWord(v);
    }

    /**
     * Parse the word
     */
    function parseWord(word: string) {
      if (!_object && word.startsWith('in:')) {
        // If there is no object already specified and the word starts with "in:", check if that object is available to search
        const object = availableObjects.find(
          (x) => x.name === word.trim().split(':')[1]
        );

        if (object) {
          // If the object was available, add it to the search
          addObject(object);
          _object = object;
          success = true;
          return '';
        }
      } else if (_object && word.includes(':')) {
        // If an object is already specified, there may be properties to parse, check if the word includes ":"
        const arr: string[] = word.trim().split(':');
        const property = arr[0];

        if (
          _object.properties.indexOf(property) > -1 &&
          arr[1] &&
          arr[1] !== ''
        ) {
          // If the property exists on the current object, add it as a property block, if not then ignore it and treat it as a raw value
          const { value, properties } = addProperty(
            property,
            word,
            _properties
          );
          _value = value;
          _properties = properties;
          success = true;

          return value;
        }
      } else {
        return word;
      }
    }

    setValue(_value || '');

    // Return all the updated data incase its needed before a state change
    return {
      value: _value,
      object: _object,
      properties: _properties,
      success
    };
  }

  // If the page is updated, run the search again with the new page number
  useEffect(() => {
    void search();
  }, [page]);

  /**
   * Run a search
   */
  async function search(
    valueOverride?: string,
    objectOverride?: SearchObject,
    propertiesOverride?: Property[]
  ) {
    // Overrides are used if the search function is ran before the state has been updated
    const _value = valueOverride !== undefined ? valueOverride : value;
    const _object = objectOverride !== undefined ? objectOverride : object;
    const _properties =
      propertiesOverride !== undefined ? propertiesOverride : properties;

    // Create the search data object ready to send to the API
    const searchData: SearchData = {
      keywords: [
        ..._properties,
        ...(_value
          ? _value
              .trim()
              .split(' ')
              .map((x) => ({ keyword: x }))
          : [])
      ],
      in: _object ? _object.name : undefined
    };

    // If the minimum amount of information is available, send it to the API
    if (searchData.keywords.length > 0 || searchData.in) {
      const response = await axios.post<SearchResponse>(SEARCH_ENDPOINT, {
        ...searchData,
        page
      });

      // Parse all the results and add the client url to them
      const results = response.data.results.map((x) => ({
        ...x,
        url: resolveUrl(x)
      }));

      if (external) {
        // If the search results are rendered externally by the parent
        if (onNewResults) {
          onNewResults(results);
        }

        if (onNewTotalResults) {
          onNewTotalResults(response.data.total);
        }

        setOpen(false);
        searchRef.current?.blur();
      } else {
        // Otherwise, set the quick results here on the search bar
        setResults(results.splice(0, 6));
      }

      setSelected(0);
    }
  }

  /**
   * Resolve a url string by replacing {id} with a results actual id
   */
  function resolveUrl(result: Result) {
    return locationDict[result.object]
      ? locationDict[result.object].replaceAll('{id}', result.id)
      : '/';
  }

  return (
    <>
      <div ref={containerRef} className="search-wrapper">
        <div className="search-bar">
          <span className="search-icon">
            <Icon icon="mono-icons:search" />
          </span>

          <div className="search-scroll-wrapper">
            <div
              className="search-scroll"
              onWheel={(e) => {
                e.preventDefault();
                e.currentTarget.scrollTo({
                  left: e.currentTarget.scrollLeft + e.deltaY,
                  behavior: 'smooth'
                });
              }}
            >
              {object && (
                <span className="search-block hover" onClick={removeObject}>
                  in:{object.name}
                </span>
              )}

              {properties.map((x) => (
                <span
                  key={x.property}
                  className="search-block hover"
                  onClick={() => {
                    removeProperty(x);
                    setValue(`${x.property as string}:`);
                    searchRef.current?.focus();
                  }}
                >
                  {x.property}:{x.keyword}
                </span>
              ))}

              <input
                ref={searchRef}
                className="search-field"
                type="text"
                placeholder="Search..."
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);

                  if (onSearchChange) {
                    onSearchChange();
                  }
                }}
                onKeyDown={onKeyDown}
                onClick={() => setOpen(true)}
                onPaste={(e) => {
                  e.preventDefault();

                  const res = parseValueForData(
                    e.clipboardData.getData('Text')
                  );

                  void search(res.value, res.object, res.properties);
                }}
              />
            </div>
          </div>

          <span className="search-slash">/</span>
        </div>
        {open && (
          <div className="search-dropdown">
            {!results ? (
              <ul>
                {canSearch() && (
                  <li className={selected === 0 ? 'selected' : ''}>
                    {value}{' '}
                    <span className="search-block">
                      {object ? `in:${object.name}` : 'global'}
                    </span>{' '}
                    {properties.map((x) => (
                      <>
                        <span key={x.property} className="search-block">
                          {x.property}:{x.keyword}
                        </span>{' '}
                      </>
                    ))}{' '}
                    - Press Enter to search
                  </li>
                )}
                {object && object.properties.length > 0
                  ? object.properties.map((x, i) => (
                      <li
                        className={
                          selected === (canSearch() ? i + 1 : i)
                            ? 'selected'
                            : ''
                        }
                        key={x}
                        onClick={() => addProperty(x)}
                      >
                        <span className="search-block">in:{object.name}</span>{' '}
                        <span className="search-block">
                          {x}:
                          {value.includes(':') ? value.split(':')[1] : value}
                        </span>
                      </li>
                    ))
                  : availableObjects.slice(0, 3).map((x, i) => (
                      <li
                        className={
                          selected === (canSearch() ? i + 1 : i)
                            ? 'selected'
                            : ''
                        }
                        key={x.name}
                        onClick={() => addObject(x)}
                      >
                        <span className="search-block">in:{x.name}</span>{' '}
                        {!value.startsWith('in:') ? value : ''}
                      </li>
                    ))}
              </ul>
            ) : results && results.length === 0 ? (
              <p>No results found</p>
            ) : (
              <ul>
                {results &&
                  results.map((x, i) => (
                    <li
                      key={i}
                      className={selected === i ? 'selected' : ''}
                      onClick={() => {
                        navigate(x.url);
                        setOpen(false);
                      }}
                    >
                      {!object && (
                        <span className="search-block">in:{x.object}</span>
                      )}{' '}
                      {x.display}
                    </li>
                  ))}
              </ul>
            )}
            {!external && results && results.length > 0 && (
              <div className="show-all-results">
                <a
                  className="text-primary hover"
                  onClick={() =>
                    navigate('/search', {
                      state: {
                        object,
                        properties,
                        value
                      }
                    })
                  }
                >
                  Show all results...
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
