import { Link } from 'react-router-dom';
import { Badge } from '../../../../shared/components/Badge';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Device,
  DeviceState
} from '../../../../shared/types/provisioning/Device';

type Props = {
  device: Device;
};

export function DeviceInfoBar({ device }: Props) {
  return (
    <div className="flex gap-2 items-center">
      <Link to={`/provisioning/devices/${device.id}`}>
        {ProvisioningIdPrefix.DEV}-{device.id}
      </Link>
      <div>{device.serial}</div>
      <div className="flex-1">
        Port {device?.ponPort ?? device.rawPort ?? 'Not found'}
      </div>
      <div>
        <Badge
          type={
            device.state === DeviceState.IN_DISCOVERY
              ? undefined
              : device.state === DeviceState.ERROR
                ? 'red'
                : device.state === DeviceState.PROVISIONED
                  ? 'green'
                  : 'blue'
          }
        >
          {device.state === DeviceState.IN_DISCOVERY
            ? 'DISABLED'
            : device.state === DeviceState.DEPROVISIONING
              ? 'DISABLING'
              : device.state}
        </Badge>
      </div>
    </div>
  );
}
