import { AxiosError } from 'axios';
import { AlertType } from '../context/AlertContext';
import { Account } from './types/auth/Account';
import { Response } from './types/response/Response';

export function alertError(
  createAlert: (title: string, body: string, type: AlertType) => void,
  err: unknown
) {
  if (
    err instanceof AxiosError &&
    err.response &&
    err.response.data &&
    (err.response.data as Response).message
  ) {
    createAlert(
      'An error occurred',
      (err.response.data as Response).message || 'An unknown error occurred',
      'attention'
    );
  } else {
    createAlert('An error occurred', 'An unknown error occurred', 'attention');
  }
}

export function hasPermissions(account: Account, nodes: string[]): boolean {
  if (!account || !nodes) {
    return false;
  }

  const scope = account.scope;

  // Compare each listed node
  for (const key of nodes) {
    // Check if has any of the listed permissions
    if (scope.includes(key)) {
      return true;
    }
  }

  return false;
}

export function toFormData(
  formData: FormData,
  data: unknown,
  parentKey?: string
) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) =>
      toFormData(
        formData,
        (data as keyof unknown)[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    );
  } else {
    formData.append(parentKey || '', data === null ? '' : (data as string));
  }
}

/**
 * Generates a file from a CSV string and downloads through the browser
 */
export function downloadCsv(csvContent: string, fileName?: string) {
  const file = new File(
    [csvContent],
    fileName ?? `${new Date().toISOString()}.csv`,
    {
      type: 'text/csv'
    }
  );

  /**
   * Note: normally we could just use window.location.assign() on the created object url
   * but `fileName` is bugged in Chrome and hasn't worked for years.
   * Creating an `a` tag with a download property and simulating click
   * is the only way to get file names to work consistently atm
   */
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = file.name;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  );
  link.remove();
  URL.revokeObjectURL(link.href);
}
