import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SERVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { MessageBanner } from '../../../../shared/components/MessageBanner';
import { alertError } from '../../../../shared/helpers';
import useDocumentTitle from '../../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import { CheckResult } from '../../../../shared/types/provisioning/CheckResult';
import { DeviceState } from '../../../../shared/types/provisioning/Device';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { DeviceHealth } from '../../shared/DeviceHealth';
import { ServiceHealth } from '../../shared/ServiceHealth';
import { ServiceModal } from '../ServiceModal';
import { AuditLog } from './AuditLog';
import { Controls } from './Controls';
import { Details } from './Details';

type Props = {
  edit?: boolean;
};

export function ServiceView({ edit }: Props) {
  const { createAlert } = useAlerts();
  const { id } = useParams<{ id: string }>();
  useDocumentTitle(`${ProvisioningIdPrefix.SVC}-${id || '?'}`);
  const navigate = useNavigate();
  const [service, setService] = useState<Service>();
  const [lastCheckResult, setLastCheckResult] =
    useState<Record<string, CheckResult>>();
  const [lastDeviceCheckResult, setLastDeviceCheckResult] =
    useState<Record<string, CheckResult>>();
  const [inSync, setInSync] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmReprovision, setConfirmReprovision] = useState(false);

  useEffect(() => {
    async function get() {
      if (id) {
        try {
          const response = await axios.get<ObjectResponse<Service>>(
            `${SERVICE_ENDPOINT}/${id}`
          );
          setService(response.data.result);

          if (response.data.result.lastCheck) {
            setLastCheckResult(
              JSON.parse(response.data.result.lastCheck.result) as Record<
                string,
                CheckResult
              >
            );

            setInSync(response.data.result.lastCheck.inSync);
          }

          if (response.data.result.device?.lastCheck) {
            setLastDeviceCheckResult(
              JSON.parse(
                response.data.result.device?.lastCheck.result
              ) as Record<string, CheckResult>
            );
          }
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }
      }
    }

    const interval = setInterval(() => {
      void get();
    }, 5000);

    void get();

    return () => {
      clearInterval(interval);
    };
  }, [id]);

  async function reprovision() {
    if (!service) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post<ObjectResponse<Service>>(
        `${SERVICE_ENDPOINT}/${service.id}/reprovision`,
        {}
      );

      if (response && response.data) {
        if (service) {
          setService({
            ...service,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  if (!service) {
    return null;
  }

  return (
    <>
      <div className="page-wrapper">
        {!inSync && service.state === ServiceState.PROVISIONED && (
          <MessageBanner
            type="warning"
            title="Out of Sync"
            message="This service is out of sync with the latest configuration, please reprovision this service to update."
            actions={[
              {
                name: 'Reprovision',
                onClick: () => setConfirmReprovision(true),
                disabled: loading
              }
            ]}
          />
        )}

        <div className="flex items-center">
          <div className="flex items-center gap-1 flex-1">
            <h1>Service</h1>
            <span className="text-muted mt-1">
              ({ProvisioningIdPrefix.SVC}-{service.id})
            </span>
          </div>
          <div>
            <Controls service={service} setService={setService} />
          </div>
        </div>

        {service.errorMessage && (
          <MessageBanner
            title="An error has occurred"
            message={service.errorMessage}
            className="mb-2"
          />
        )}

        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <Details service={service} setService={setService} />
            <AuditLog service={service} />
          </div>

          <div className="flex-1">
            <div className="flex flex-col gap-2">
              <DeviceHealth
                state={service.device?.state || DeviceState.ERROR}
                timestamp={service.device?.lastCheck?.createdAt}
                checks={lastDeviceCheckResult}
              />
              <ServiceHealth
                state={service.state}
                timestamp={service.lastCheck?.createdAt}
                checks={lastCheckResult}
              />
            </div>
          </div>
        </div>
      </div>

      {confirmReprovision && (
        <ConfirmationModal
          title="Reprovision Service"
          prompt={<>Are you sure you want to reprovision this service?</>}
          onResolve={(confirmed) => {
            if (confirmed) {
              setConfirmReprovision(false);
              void reprovision();
            } else {
              setConfirmReprovision(false);
            }
          }}
        />
      )}

      {edit && (
        <ServiceModal
          onClose={() => navigate(`/provisioning/services/${id ?? ''}`)}
          serviceId={id}
        />
      )}
    </>
  );
}
