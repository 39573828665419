import { useState } from 'react';
import { Button } from '../../../../shared/components/Button';
import { Settings } from '../../../../shared/types/auth/Settings';
import { EmailItem } from './EmailItem';

type Props = {
  emails: Settings['incidentConfirmedNotificationEmails'];
  updateSettings: (
    newSettings: Partial<Settings>,
    alertTitle?: string
  ) => Promise<void>;
};

export function EmailList({ emails, updateSettings }: Props) {
  const [newEmail, setNewEmail] = useState<string>('');
  const [isAdding, setIsAdding] = useState<boolean>(false);

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault();
    if (!newEmail) return;
    setIsAdding(true);

    // new email input may be comma separated list
    const newEmails = Array.from(
      new Set(newEmail.split(',').map((email) => email.trim()))
    );

    await updateSettings(
      {
        incidentConfirmedNotificationEmails: Array.from(
          new Set([...newEmails, ...emails])
        )
      },
      `${newEmails.length} new email${newEmail.length === 1 ? '' : 's'} added`
    );

    setNewEmail('');
    setIsAdding(false);
  }

  async function deleteEmail(emailToDelete: string): Promise<void> {
    await updateSettings(
      {
        incidentConfirmedNotificationEmails: emails.filter(
          (email) => email !== emailToDelete
        )
      },
      `Email ${emailToDelete} deleted`
    );
  }

  return (
    <>
      <form
        className="flex gap-1"
        onSubmit={async (event) => await handleSubmit(event)}
      >
        <input
          type="email"
          placeholder="example@substantial.group"
          value={newEmail}
          onChange={(event) => {
            setNewEmail(event.currentTarget.value);
          }}
          multiple={true}
        />
        <Button submit disabled={!newEmail.length || isAdding}>
          {isAdding ? 'Adding to list...' : 'Add email(s)'}
        </Button>
      </form>
      {emails.length ? (
        <ul className="email-list">
          {emails.map((email) => (
            <EmailItem key={email} email={email} deleteEmail={deleteEmail} />
          ))}
        </ul>
      ) : (
        <p>No configured email addresses found.</p>
      )}
    </>
  );
}
