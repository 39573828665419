import { DateTime } from 'luxon';
import { Badge } from '../../../../shared/components/Badge';

type Props = {
  resolvedTimestamp: string | null;
  confirmedTimestamp: string | null;
};

export function IncidentStatusBadge({
  resolvedTimestamp,
  confirmedTimestamp
}: Props) {
  return (
    <>
      {resolvedTimestamp ? (
        <Badge
          type="green"
          title={`Resolved at ${DateTime.fromISO(resolvedTimestamp).toFormat(
            'dd/MM/yyyy, HH:mm:ss'
          )}`}
        >
          Resolved
        </Badge>
      ) : confirmedTimestamp ? (
        <Badge
          type="yellow"
          title={`Confirmed at ${DateTime.fromISO(confirmedTimestamp).toFormat(
            'dd/MM/yyyy, HH:mm:ss'
          )}`}
        >
          Confirmed
        </Badge>
      ) : (
        <Badge type="grey">Not confirmed</Badge>
      )}
    </>
  );
}
