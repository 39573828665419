import axios from 'axios';
import { useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MANUFACTURER_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { Manufacturer } from '../../../shared/types/provisioning/Manufacturer';
import { ManufacturerModal } from './ManufacturerModal';

export function Manufacturers() {
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(id ? `${ProvisioningIdPrefix.MAN}-${id}` : 'Manufacturers');
  const navigate = useNavigate();
  const { createAlert } = useAlerts();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState<Manufacturer>();

  async function deleteManufacturer(manufacturer: Manufacturer) {
    try {
      await axios.delete(`${MANUFACTURER_ENDPOINT}/${manufacturer.id}`);

      createAlert(
        'Manufacturer Deleted',
        `Manufacturer ${manufacturer.name} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          reloader={reloader}
          title="Manufacturers"
          searchPrompt="Find a manufacturer..."
          endpoint={MANUFACTURER_ENDPOINT}
          columns={['ID', 'Name', '']}
          generateRow={(result: Manufacturer) => [
            `${ProvisioningIdPrefix.MAN}-${result.id}`,
            result.name,
            <div key="" className="table-controls">
              <Button
                type="secondary"
                onClick={() =>
                  navigate(`/provisioning/manufacturers/${result.id}`)
                }
              >
                Edit
              </Button>
              <Button type="secondary" onClick={() => setDeleting(result)}>
                Delete
              </Button>
            </div>
          ]}
          newButtonText="New Manufacturer"
          onNewButtonClick={() => setAdding(true)}
        />
      </div>

      {(adding || id) && (
        <ManufacturerModal
          manufacturerId={id}
          onClose={(shouldReload: boolean) => {
            setAdding(false);
            navigate('/provisioning/manufacturers');

            if (shouldReload) {
              reload();
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Manufacturer"
          prompt={
            <>
              Are you sure you want to delete manufacturer{' '}
              <strong>{deleting.name}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteManufacturer(deleting);
              reload();
            }

            setDeleting(undefined);
          }}
        />
      )}
    </>
  );
}
