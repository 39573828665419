import axios from 'axios';
import { useEffect, useState } from 'react';
import { SERVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { Audit } from '../../../../shared/components/audit/Audit';
import { alertError } from '../../../../shared/helpers';
import { AuditObjectType } from '../../../../shared/types/audit/AuditObjectType';
import { AuditQuery } from '../../../../shared/types/audit/AuditQuery';
import { Service } from '../../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';

type Props = {
  service: Service;
};

export function AuditLog({ service }: Props) {
  const { createAlert } = useAlerts();
  const [query, setQuery] = useState<AuditQuery>();

  useEffect(() => {
    async function load() {
      try {
        const query = [
          {
            objectId: service.id.toString(),
            objectType: AuditObjectType.PROVISIONING_SERVICE
          },
          {
            objectId: service.id.toString(),
            objectType: AuditObjectType.PROVISIONING_SERVICE_CHECK
          }
        ];

        const attributes = await axios.get<
          ObjectResponse<{
            compiled: { [key: string]: string };
            keys: string[];
            values: string[];
          }>
        >(`${SERVICE_ENDPOINT}/${service.id}/attributes`);

        if (attributes && attributes.data) {
          for (const id of attributes.data.result.values) {
            query.push({
              objectId: id,
              objectType: AuditObjectType.PROVISIONING_ATTRIBUTE_VALUE
            });
          }
        }

        setQuery(query);
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }
    void load();
  }, []);

  if (query) {
    return <Audit title="Service History" query={query} />;
  }

  return null;
}
