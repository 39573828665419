import { UseFormReturn } from 'react-hook-form';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Prefix } from '../../../shared/types/Prefix';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  index: number;
  prefixes: Prefix[];
  onClose: VoidFunction;
};

export function PrefixBoxTemplate({ form, index, prefixes, onClose }: Props) {
  return (
    <div className="box my-2">
      <div className="box-header">
        <p className="box-title">Container Prefix</p>
        <div className="close" onClick={onClose}>
          &times;
        </div>
      </div>

      <div className="input-group-list">
        <InputGroup
          form={form}
          name={`prefixIds.${index}.prefixId`}
          type="select"
          placeholder="Select a prefix"
          options={prefixes.map((x) => ({ label: x.prefix, value: `${x.id}` }))}
          validationOptions={{ required: 'Please select a prefix' }}
        />

        <InputGroup
          form={form}
          name={`prefixIds.${index}.cidr`}
          label="Desired Prefix Size (CIDR)"
          placeholder="Enter prefix size..."
          validationOptions={{ required: 'Please enter a prefix size' }}
        />
      </div>
    </div>
  );
}
