import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type AlertType = 'attention' | 'notice' | 'success' | 'update';

export type Alert = {
  id: string;
  title: string;
  body: string;
  type: AlertType;
  createdAt: number;
};

type AlertContextProps = {
  createAlert: (title: string, body: string, type: AlertType) => void;
};

const AlertContext = React.createContext<AlertContextProps>({
  createAlert: () => {}
});

export function useAlerts(): AlertContextProps {
  return useContext(AlertContext);
}

type AlertProviderProps = {
  children: React.ReactNode;
};

export function AlertProvider({ children }: AlertProviderProps): JSX.Element {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (alerts.length > 0) {
        setAlerts(
          alerts.filter((x) => new Date().getTime() - x.createdAt < 5000)
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [alerts]);

  function createAlert(title: string, body: string, type: AlertType) {
    setAlerts([
      ...alerts,
      {
        id: uuidv4(),
        title,
        body,
        type,
        createdAt: new Date().getTime()
      }
    ]);
  }

  function closeAlert(id: string) {
    setAlerts(alerts.filter((x) => x.id !== id));
  }

  return (
    <AlertContext.Provider
      value={{
        createAlert
      }}
    >
      {children}
      <div className="alert-container">
        {alerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.type}`}>
            <div className="alert-header">
              <p className="alert-title">{alert.title}</p>
              <div className="alert-close" onClick={() => closeAlert(alert.id)}>
                <Icon icon="clarity:window-close-line" />
              </div>
            </div>
            <p className="alert-body">{alert.body}</p>
          </div>
        ))}
      </div>
    </AlertContext.Provider>
  );
}
