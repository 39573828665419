import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { SWITCH_ENDPOINT } from '../../../constants';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { Link } from 'react-router-dom';
import { Badge } from '../../../shared/components/Badge';
import { Switch, SwitchState } from '../../../shared/types/provisioning/Switch';

export function Switches() {
  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          title="Switches"
          searchPrompt="Find a Switch..."
          endpoint={SWITCH_ENDPOINT}
          columns={['ID', 'Name', 'IP', 'Model', 'Manufacturer']}
          generateRow={(result: Switch) => [
            <>
              <Link to={`/provisioning/switches/${result.id}`}>
                {ProvisioningIdPrefix.SWT}-{result.id}
              </Link>
            </>,
            result.name,
            result.ip,
            result.model,
            result.manufacturer,
            <>
              <Badge
                type={
                  result.state === SwitchState.DEPROVISIONED
                    ? undefined
                    : result.state === SwitchState.ERROR
                    ? 'red'
                    : result.state === SwitchState.PROVISIONED
                    ? 'green'
                    : 'blue'
                }
              >
                {result.state}
              </Badge>
            </>
          ]}
        />
      </div>
    </>
  );
}
