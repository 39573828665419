import { UseFormReturn } from 'react-hook-form';
import { InputGroup } from '../../../shared/components/InputGroup';
import { InterfaceType } from '../../../shared/types/provisioning/Interface';
import { ProductType } from '../../../shared/types/provisioning/Product';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  index: number;
  onClose: VoidFunction;
};

export function InterfaceBox({ form, index, onClose }: Props) {
  return (
    <div className="box">
      <div className="box-header">
        <p className="box-title">
          Interface<span className="text-primary">*</span>
        </p>
        <div className="close" onClick={onClose}>
          &times;
        </div>
      </div>

      <div className="input-group-list">
        <InputGroup
          form={form}
          name={`interfaces.${index}.name`}
          placeholder="Enter interface name"
          validationOptions={{ required: 'Please enter an interface name' }}
        />

        <InputGroup
          form={form}
          name={`interfaces.${index}.type`}
          type="select"
          label="Type"
          options={Object.keys(InterfaceType).map((x) => ({
            label: x,
            value: x
          }))}
          validationOptions={{ required: 'Please select an interface type' }}
        />

        <div>
          <div className="mb-1">
            Supported Product Types<span className="text-primary">*</span>
          </div>

          {Object.keys(ProductType).map((x) => (
            <InputGroup
              key={x}
              form={form}
              name={`interfaces.${index}.supportedProductTypes.${x}`}
              type="checkbox"
              label={x}
              id={`interfaces.${index}.supportedProductTypes.${x}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
