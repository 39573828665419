import { Device } from './Device';
import { Interface } from './Interface';
import { Product } from './Product';

export type Service = {
  id: number;
  device?: Device;
  deviceId: number;
  product?: Product;
  productId: number;
  interfaceId: number;
  interface?: Interface;
  state: ServiceState;
  templateVersion?: number;
  errorMessage?: string;
  failMessage?: string;
  externalReference?: string;
  attributes?: { [key: string]: string };
  lastCheck?: {
    id: string;
    seviceId: number;
    inSync: boolean;
    result: string;
    createdAt: string;
  };
  portEnabled?: boolean;
};

export enum ServiceState {
  NOT_PROVISIONED = 'NOT_PROVISIONED',
  PROVISIONING = 'PROVISIONING',
  DEPROVISIONING = 'DEPROVISIONING',
  PROVISIONED = 'PROVISIONED',
  CHECKING = 'CHECKING',
  ERROR = 'ERROR',
  REBOOTING = 'REBOOTING'
}
