export type Switch = {
  id: number;
  netboxId: number;
  name: string;
  ip: string;
  updateState?: string;
  model: string;
  manufacturer: string;
  state: SwitchState;
  lastCheck?: {
    id: string;
    seviceId: number;
    inSync: boolean;
    result: string;
    createdAt: string;
  };
  errorMessage?: string;
};

export enum SwitchState {
  DEPROVISIONED = 'DEPROVISIONED',
  DEPROVISIONING = 'DEPROVISIONING',
  CHECKING = 'CHECKING',
  ERROR = 'ERROR',
  REBOOTING = 'REBOOTING',
  UPGRADING = 'UPGRADING',
  PROVISIONING = 'PROVISIONING',
  PROVISIONED = 'PROVISIONED'
}
