import { useSelector } from 'react-redux';
import { AuthState } from '../store/reducers/AuthReducer';
import { RootState } from '../store/store';
import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { UserBar } from '../shared/components/UserBar';
import { Navigation } from '../shared/components/Navigation';

type Props = {
  children: ReactNode;
};

export default function AuthRoute({ children }: Props) {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  if (!auth || !auth.account) {
    return <Navigate to="/auth/signin" />;
  }

  if (auth && auth.account && !auth.account.group) {
    return <Navigate to="/auth/approval" />;
  }

  return (
    <>
      <UserBar />
      <Navigation />
      {children}
    </>
  );
}
