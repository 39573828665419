import { Icon } from '@iconify/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEVICE_ENDPOINT, SERVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { hasAccess } from '../../../../helpers';
import { Button } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { alertError } from '../../../../shared/helpers';
import { Device } from '../../../../shared/types/provisioning/Device';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';
import { AuthState } from '../../../../store/reducers/AuthReducer';
import { RootState } from '../../../../store/store';
import { useNavigate } from 'react-router-dom';

type Props = {
  service: Service;
  setService: (service: Service) => void;
};

export function Controls({ service, setService }: Props) {
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { createAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<string>();
  const [togglePortModal, setTogglePortModal] = useState(false);
  const [showRebootModal, setShowRebootModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [debugDropdown, setDebugDropdown] = useState(false);
  const [debugModal, setDebugModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const provisionBtnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!provisionBtnRef.current?.contains(e.target as Node)) {
        setDebugDropdown(false);
      }
    }

    window.addEventListener('click', onClick);
  }, [provisionBtnRef]);

  async function deleteService() {
    try {
      await axios.delete(`${SERVICE_ENDPOINT}/${service.id}`);

      createAlert(
        'Service Deleted',
        `Service ${service.id} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  const disableControls =
    loading ||
    service?.state === ServiceState.PROVISIONING ||
    service?.state === ServiceState.DEPROVISIONING ||
    service?.state === ServiceState.CHECKING ||
    service?.device?.locked;

  async function createJob(
    type: 'check' | 'provision' | 'deprovision' | 'setPort',
    debug?: boolean
  ) {
    if (!service) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post<ObjectResponse<Service>>(
        type === 'setPort'
          ? `${SERVICE_ENDPOINT}/${service.id}/setPort/${
              service.portEnabled ? 'false' : 'true'
            }`
          : `${SERVICE_ENDPOINT}/${service.id}/${type}`,
        debug
          ? {
              debug
            }
          : {}
      );

      if (response && response.data) {
        if (service) {
          setService({
            ...service,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function rebootDevice() {
    if (!service) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post<ObjectResponse<Device>>(
        `${DEVICE_ENDPOINT}/${service.deviceId}/reboot`,
        {}
      );
      setService({
        ...service,
        device: response.data.result
      });
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function updateDevice() {
    if (!service) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post<ObjectResponse<Device>>(
        `${DEVICE_ENDPOINT}/${service.deviceId}/update`,
        {}
      );
      setService({
        ...service,
        device: response.data.result
      });
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  if (
    !auth.account ||
    !hasAccess(auth.account, ['provisioning:service.patch'])
  ) {
    return null;
  }

  return (
    <>
      <div className="flex gap-1">
        {service?.state === ServiceState.NOT_PROVISIONED && (
          <Button type="secondary" onClick={() => setDeleting(true)}>
            Delete
          </Button>
        )}

        <Button
          type="secondary"
          url={`/provisioning/services/${service.id}/edit`}
          disabled={disableControls}
        >
          Edit
        </Button>

        {(service?.state === ServiceState.ERROR ||
          service?.state === ServiceState.NOT_PROVISIONED ||
          service?.state === ServiceState.PROVISIONING) && (
          <div
            ref={provisionBtnRef}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setDebugDropdown(true);
            }}
            className="relative"
          >
            <Button
              type="primary"
              onClick={() => setConfirmationModal('provision')}
              disabled={disableControls}
            >
              {service?.state === ServiceState.PROVISIONING ? (
                <>
                  {service.device?.updateState || 'Provisioning'}{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Provision</>
              )}
            </Button>

            {debugDropdown && (
              <div className="dropdown">
                <Button
                  type="secondary"
                  onClick={() => setDebugModal(true)}
                  disabled={disableControls}
                >
                  {service?.state === ServiceState.PROVISIONING ? (
                    <>
                      {service.device?.updateState || 'Provisioning'}{' '}
                      <Icon className="loader" icon="codicon:loading" />
                    </>
                  ) : (
                    <>Provision (Debug)</>
                  )}
                </Button>
              </div>
            )}
          </div>
        )}

        {(service?.state === ServiceState.ERROR ||
          (service?.state !== ServiceState.NOT_PROVISIONED &&
            service?.state !== ServiceState.PROVISIONING)) && (
          <>
            <Button
              type="secondary"
              onClick={() => setTogglePortModal(true)}
              disabled={disableControls}
            >
              {service.portEnabled ? 'Disable Port' : 'Enable Port'}
            </Button>

            <Button
              type="secondary"
              onClick={() => setConfirmationModal('deprovision')}
              disabled={disableControls}
            >
              {service?.state === ServiceState.DEPROVISIONING ? (
                <>
                  Deprovisioning{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Deprovision</>
              )}
            </Button>

            <Button
              type="primary"
              onClick={() => void createJob('check')}
              disabled={disableControls}
            >
              {service?.state === ServiceState.CHECKING ? (
                <>
                  Checking <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Check</>
              )}
            </Button>
          </>
        )}
      </div>

      {confirmationModal && (
        <ConfirmationModal
          title={`${
            confirmationModal === 'provision' ? 'Provision' : 'Deprovision'
          } Service`}
          prompt={`Are you sure you want to ${confirmationModal} this service?`}
          onResolve={(confirmed) => {
            if (confirmed) {
              setConfirmationModal(undefined);
              void createJob(
                confirmationModal === 'provision' ? 'provision' : 'deprovision'
              );
            } else {
              setConfirmationModal(undefined);
            }
          }}
          denyText="Cancel"
          confirmText={
            confirmationModal === 'provision' ? 'Provision' : 'Deprovision'
          }
        />
      )}

      {togglePortModal && (
        <ConfirmationModal
          title={`${service.portEnabled ? 'Disable' : 'Enable'} Port`}
          prompt={`Are you sure you want to ${
            service.portEnabled ? 'disable' : 'enable'
          } the port associated with this service?`}
          onResolve={(confirmed) => {
            if (confirmed) {
              setTogglePortModal(false);
              void createJob('setPort');
            } else {
              setTogglePortModal(false);
            }
          }}
          denyText="Cancel"
          confirmText={service.portEnabled ? 'Disable' : 'Enable'}
        />
      )}

      {showRebootModal && (
        <ConfirmationModal
          title="Reboot ONT"
          prompt="Are you sure you want to reboot this ONT? Services on this ONT will will be unavailable, this may take several minutes."
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowRebootModal(false);
              void rebootDevice();
            } else {
              setShowRebootModal(false);
            }
          }}
        />
      )}

      {showUpdateModal && (
        <ConfirmationModal
          title="Upgrade ONT"
          prompt="Are you sure you want to upgrade this ONT? Services on this ONT will will be unavailable, this may take several minutes."
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowUpdateModal(false);
              void updateDevice();
            } else {
              setShowUpdateModal(false);
            }
          }}
        />
      )}

      {debugModal && (
        <ConfirmationModal
          title="Provision Debug"
          prompt={
            <>
              Are you sure you want to provision this service in debug mode?
              <br />
              If the service fails to provision or any checks fail, config will
              not be automatically removed and the service will be marked as
              provisioned even if it isn&apos;t.
              <br />
              <br />
              <strong>
                WARNING: Only do this if you know what you are doing!!!
              </strong>
              <br />
              <br />
              Once you have finished debugging, deprovision the service and
              ensure all related config has been removed from the OLT.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setDebugModal(false);
              void createJob('provision', true);
            } else {
              setDebugModal(false);
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Service"
          prompt={
            <>
              Are you sure you want to delete service{' '}
              <strong>SVC-{service.id}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteService();
              navigate('/provisioning/services');
            }

            setDeleting(false);
          }}
        />
      )}
    </>
  );
}
