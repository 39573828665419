import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type ButtonType = 'primary' | 'secondary';

type Props = {
  children: ReactNode;
  type?: ButtonType;
  disabled?: boolean;
  onClick?: (() => Promise<void>) | (() => void);
  full?: boolean;
  submit?: boolean;
  url?: string;
  title?: string;
};

export function Button({
  children,
  type,
  disabled,
  onClick,
  full,
  submit,
  url,
  title
}: Props) {
  return url ? (
    <Link
      to={disabled ? '#' : url}
      className={`button ${
        (type || 'primary') === 'primary' ? 'primary' : 'secondary'
      } ${disabled ? 'disabled' : ''} ${full ? 'full' : ''}`}
      title={title}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`button ${
        (type || 'primary') === 'primary' ? 'primary' : 'secondary'
      } ${disabled ? 'disabled' : ''} ${full ? 'full' : ''}`}
      onClick={
        disabled ? () => {} : async () => (onClick ? await onClick() : {})
      }
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      title={title}
    >
      {children}
    </button>
  );
}
