import { Icon } from '@iconify/react';
import { Button } from './Button';

type MessageBannerProps = {
  title: string;
  message: string;
  type?: 'error' | 'warning' | 'success';
  className?: string;
  actions?: {
    name: string;
    onClick: VoidFunction;
    disabled?: boolean;
  }[];
};

export function MessageBanner({
  title,
  message,
  type,
  className,
  actions
}: MessageBannerProps) {
  const data = {
    error: {
      color: 'red',
      icon: 'codicon:error'
    },
    warning: {
      color: 'yellow',
      icon: 'codicon:warning'
    },
    success: {
      color: 'green',
      icon: 'codicon:pass'
    }
  };

  return (
    <div
      className={`banner banner-${data[type || 'error'].color} ${
        className || ''
      }`}
    >
      <div className="banner-icon">
        <Icon icon={data[type || 'error'].icon} width={40} />
      </div>
      <div className="banner-text">
        <h4>{title}</h4>
        <p>{message}</p>
      </div>
      <div>
        {actions &&
          actions.map((x, i) => (
            <Button
              key={i}
              onClick={x.onClick}
              type="secondary"
              disabled={x.disabled}
            >
              {x.name}
            </Button>
          ))}
      </div>
    </div>
  );
}
