import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';
import logo from '../../assets/logo.png';
import { Button } from '../../shared/components/Button';
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
  hash53,
  randomString
} from '../../helpers';

const tenantId = window.appConfig.AZURE_TENANT_ID || '';
const clientId = window.appConfig.AZURE_CLIENT_ID || '';
const redirectUri = window.appConfig.AZURE_REDIRECT_URI || '';

export function SignInPage() {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  async function login() {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
    const nonce = randomString(16);
    const hashedNonce = hash53(nonce);

    window.sessionStorage.setItem('omni.codeVerifier', codeVerifier);
    window.sessionStorage.setItem('omni.nonce', nonce);

    window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&scope=openid&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256&nonce=${hashedNonce}&redirect_uri=${redirectUri}`;
  }

  if (auth && auth.account) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="auth-page-wrapper">
        <div className="auth-page">
          <div>
            <img src={logo} alt="logo" width="80" />
          </div>
          <div className="content">
            <h4>Sign in to continue using Omni.</h4>
            <Button onClick={login}>Sign in</Button>
          </div>
        </div>
      </div>
    </>
  );
}
