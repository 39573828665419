import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { Modal } from '../../../shared/components/Modal';
import { DEVICE_ENDPOINT, DEVICE_TYPE_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import { AttributeEditor } from '../../../shared/components/AttributeEditor';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { Attribute } from '../../../shared/types/provisioning/Attribute';
import { parseAttributes } from '../../../helpers';
import { Device } from '../../../shared/types/provisioning/Device';
import { RemoteDropdownSelect } from '../../../shared/components/RemoteDropdownSelect';
import { DeviceType } from '../../../shared/types/provisioning/DeviceType';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { InputGroup } from '../../../shared/components/InputGroup';

type Props = {
  onClose: (id?: number) => void;
  deviceId?: number;
};

type Form = {
  deviceTypeId?: number;
  serial: string;
  address: string;
  externalReference: string;
  test?: boolean;
};

export function DeviceModal({ onClose, deviceId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  useEffect(() => {
    async function get() {
      if (deviceId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<Device>>(
            `${DEVICE_ENDPOINT}/${deviceId}`
          );

          if (response.data.result.attributes) {
            setAttributes(parseAttributes(response.data.result.attributes));
          }

          const device = response.data.result;
          form.setValue('deviceTypeId', device.deviceTypeId);
          form.setValue('serial', device.serial);
          form.setValue('address', device.address || '');
          form.setValue('externalReference', device.externalReference || '');
          form.setValue('test', device.test || false);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    void get();
  }, [deviceId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request<ObjectResponse<Device>>({
        url: deviceId ? `${DEVICE_ENDPOINT}/${deviceId}` : DEVICE_ENDPOINT,
        method: deviceId ? 'PATCH' : 'POST',
        data: { ...data, attributes }
      });

      console.log(response.data);

      onClose(deviceId ? undefined : response.data.result.id);

      createAlert(
        `Device ${deviceId ? 'Updated' : 'Added'}`,
        `Device ${deviceId ? 'updated' : 'added'} successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${deviceId ? 'Edit' : 'Add'} Device`}
      onClose={() => onClose()}
      size="xl"
    >
      <div className="flex gap-3">
        <div className="flex-1">
          <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
            <RemoteDropdownSelect
              form={form}
              name="deviceTypeId"
              label="Device Type"
              searchPrompt="Find a device type..."
              endpoint={`${DEVICE_TYPE_ENDPOINT}`}
              generateItem={(item: DeviceType) => ({
                key: `${ProvisioningIdPrefix.DVT}-${item.id} (${item.name})`,
                value: `${item.id}`
              })}
              placeholder="Select a device type"
              validationOptions={{
                required: 'Please select a device type'
              }}
            />

            <InputGroup
              form={form}
              name="serial"
              label="Serial Number"
              placeholder="Device Serial Number"
              validationOptions={{
                required: 'Please enter a device serial number'
              }}
            />

            <InputGroup
              form={form}
              name="address"
              type="textarea"
              label="Address"
              placeholder="Device Address"
            />

            <InputGroup
              form={form}
              name="externalReference"
              type="textarea"
              label="External Reference"
              placeholder="Device External Reference"
            />

            <InputGroup
              form={form}
              name="test"
              type="checkbox"
              label="Test Device"
              placeholder="Test Device"
            />
          </form>
        </div>
        <div className="flex-2">
          <AttributeEditor
            attributes={attributes}
            setAttributes={setAttributes}
          />
        </div>
      </div>

      <div className="flex justify-end mt-2 gap-1">
        <Button type="secondary" disabled={loading} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
