import { useEffect } from 'react';

const siteName = 'Omni';

function useDocumentTitle(pageName: string, prevailOnUnmount = false): void {
  useEffect(() => {
    document.title = `${pageName} | ${siteName}`;
  }, [pageName]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = siteName;
      }
    },
    []
  );
}

export default useDocumentTitle;
