import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../shared/components/Button';
import { Paginator } from '../../shared/components/Paginator';
import {
  Property,
  Result,
  SearchComponent,
  SearchObject
} from '../../shared/components/SearchComponent';

type LocationState = {
  object: SearchObject | undefined;
  properties: Property[];
  value: string;
};

type PageData = {
  page: number;
  results: Result[];
};

export function SearchResults(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [results, setResults] = useState<Result[]>();
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState<PageData[]>([]);
  const [subPage, setSubPage] = useState(0);
  const [searchChanged, setSearchChanged] = useState(false);
  const pageSize = 25;

  const totalPages =
    totalResults % pageSize > 0
      ? Math.floor(totalResults / pageSize) + 1
      : totalResults / pageSize;

  // Listen for changes to the page and sub page
  useEffect(() => {
    if (subPage === pages.length && pages.length > 0 && subPage < totalPages) {
      // If you are on the last loaded page but there are more pages to load, pre-load them now
      setPage(page + 1);
    }

    const _page = pages[subPage];

    if (_page) {
      // Get the current page and render its results
      setResults(_page.results);

      if (_page.results.length < pageSize && pages.length < totalPages) {
        // If the page results have less than the page size and there are more pages to load, do that now
        setPage(page + 1);
      }
    }
  }, [pages, subPage]);

  /**
   * Parse the results returned from the search component
   */
  function parseNewResults(results: Result[]) {
    const _pages: PageData[] = searchChanged ? [] : [...pages];
    const newPages: PageData[] = [];

    // If the search has changed, go back to page 1
    if (searchChanged) {
      setSubPage(0);
      setPage(1);
    }

    if (results.length > pageSize) {
      // If the search returned more results than the page size, split them up into pages
      const pages = Math.floor(
        results.length / pageSize + (results.length % pageSize > 0 ? 1 : 0)
      );

      for (let i = 0; i < pages; i++) {
        newPages.push({
          page,
          results: results.slice(i * pageSize, i * pageSize + pageSize)
        });
      }
    } else {
      // Otherwise just create a single page with the results
      newPages.push({
        page,
        results
      });
    }

    const updatedNewPages: PageData[] = [];

    // Check if there are pages to load and the last page previously loaded has less than the required amount of results
    if (_pages.length > 0) {
      const lastPageSize = _pages[_pages.length - 1].results.length;
      if (lastPageSize < pageSize && newPages.length > 0) {
        // If the last page has less than the required amount of results, work out the offset
        const difference = pageSize - lastPageSize;

        // Loop through all new pages and shift the results back by the difference to render full pages
        for (let i = 0; i < newPages.length; i++) {
          if (i == 0) {
            // If its the first new page, add the results to the last page that was previously loaded
            _pages[_pages.length - 1].results = [
              ..._pages[_pages.length - 1].results,
              ...newPages[0].results.slice(0, difference)
            ];
          } else if (i < newPages.length - 1) {
            // If its not the last new page, shift the results down by the calculated difference
            updatedNewPages.push({
              ...newPages[i],
              results: [
                ...newPages[i].results.slice(
                  difference,
                  newPages[i].results.length - 1
                ),
                ...newPages[i + 1].results.slice(0, difference)
              ]
            });
          } else {
            // If its the last new page, just remove the results that were shifted down to not include duplicate results
            updatedNewPages.push({
              ...newPages[i],
              results: [
                ...newPages[i].results.slice(
                  difference,
                  newPages[i].results.length - 1
                )
              ]
            });
          }
        }
      }
    }

    // If any new pages needed results to be shifted, use the updatedNewPages array, otherwise just push the new pages
    _pages.push(...(updatedNewPages.length > 0 ? updatedNewPages : newPages));

    setPages(_pages);
    setSearchChanged(false);
  }

  return (
    <div className="page-wrapper">
      <SearchComponent
        external={true}
        page={page}
        _object={(location.state as LocationState).object}
        _properties={(location.state as LocationState).properties}
        _value={(location.state as LocationState).value}
        onNewResults={parseNewResults}
        onNewTotalResults={(total) => setTotalResults(total)}
        onSearchChange={() => setSearchChanged(true)}
      />

      {results && results.length > 0 && (
        <>
          <h2>Results</h2>
          <ul className="search-results">
            <table>
              <tbody>
                <tr></tr>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>
                      <span className="search-block">
                        in:
                        {result.object}
                      </span>
                    </td>
                    <td>
                      <span
                        className="text-primary hover"
                        onClick={() => navigate(result.url)}
                      >
                        {result.display}
                      </span>
                    </td>
                    <td className="table-controls">
                      <Button
                        onClick={() => navigate(result.url)}
                        type="secondary"
                      >
                        Open
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ul>
          <Paginator
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </>
      )}
    </div>
  );
}
