import { Route, Routes } from 'react-router-dom';
import { Applications } from './applications/Applications';
import { Groups } from './groups/Groups';
import { Roles } from './roles/Roles';
import { SettingsPage } from './settings/Settings';
import { Users } from './users/Users';
import { UserView } from './users/view/UserView';

export function Admin() {
  return (
    <Routes>
      <Route path="/roles" element={<Roles />} />
      <Route path="/roles/:id" element={<Roles />} />
      <Route path="/groups" element={<Groups />} />
      <Route path="/groups/:id" element={<Groups />} />
      <Route path="/applications" element={<Applications />} />
      <Route path="/applications/:id" element={<Applications />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/:id" element={<Users />} />
      <Route path="/users/:id/view" element={<UserView />} />
      <Route path="/settings" element={<SettingsPage />} />
    </Routes>
  );
}
