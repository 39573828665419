import { Account } from '../../shared/types/auth/Account';
import { Group } from '../../shared/types/auth/Group';
import DispatchPayload from '../DispatchPayload';
import StoreTypes from '../StoreTypes';

export interface AuthState {
  account?: Account;
  sessionId?: string;
  token?: string;
  tokenExpiry?: string;
  refreshToken?: string;
  refreshTokenExpiry?: string;
  error?: string;
}

type LoginPayloadType = {
  account?: Account;
  sessionId?: string;
  token?: string;
  tokenExpiry?: string;
  refreshToken?: string;
  refreshTokenExpiry?: string;
};

type RefreshPayloadType = {
  account?: Account;
  sessionId?: string;
  token?: string;
  tokenExpiry?: string;
  refreshToken?: string;
  refreshTokenExpiry?: string;
};

type GroupPayloadType = {
  group?: Group;
  scope: string[];
};

const initialState: AuthState = {};

export const AuthReducer = (
  state: AuthState = initialState,
  action: DispatchPayload
): AuthState => {
  if (!action || !action.payload) {
    return state;
  }

  if (action.type === StoreTypes.LOGIN_SUCCESS) {
    const data: LoginPayloadType = action.payload.data as LoginPayloadType;
    return {
      account: data.account,
      sessionId: data.sessionId,
      token: data.token,
      tokenExpiry: data.tokenExpiry,
      refreshToken: data.refreshToken,
      refreshTokenExpiry: data.refreshTokenExpiry
    };
  } else if (action.type === StoreTypes.LOGIN_FAILED) {
    return {
      error: action.payload.message
    };
  } else if (action.type === StoreTypes.LOGOUT_SUCCESS) {
    return {};
  } else if (action.type === StoreTypes.REFRESH_SUCCESS) {
    const data: RefreshPayloadType = action.payload.data as RefreshPayloadType;
    return {
      account: state.account,
      sessionId: data.sessionId,
      token: data.token,
      tokenExpiry: data.tokenExpiry,
      refreshToken: data.refreshToken,
      refreshTokenExpiry: data.refreshTokenExpiry
    };
  } else if (action.type === StoreTypes.REFRESH_FAILED) {
    return {};
  } else if (action.type === StoreTypes.GET_GROUP_SUCCESS) {
    const data: GroupPayloadType = action.payload.data as GroupPayloadType;
    const account = state.account;

    if (account) {
      account.group = data.group;
      account.scope = data.scope;
    }

    return {
      account: account,
      sessionId: state.sessionId,
      token: state.token,
      tokenExpiry: state.tokenExpiry,
      refreshToken: state.refreshToken,
      refreshTokenExpiry: state.refreshTokenExpiry
    };
  }

  return state;
};
