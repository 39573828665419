import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../error/NotFoundPage';
import { Incidents } from './incidents/Incidents';
import { IncidentView } from './incidents/IncidentView';

export function Monitoring() {
  return (
    <Routes>
      <Route path="/incidents" element={<Incidents />} />
      <Route path="/incidents/:id" element={<IncidentView />} />
      <Route
        path="/incidents/:id/edit"
        element={<IncidentView edit={true} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
