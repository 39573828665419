export type Olt = {
  id: number;
  netboxId: number;
  name: string;
  ip: string;
  updateState?: string;
  state: OltState;
  model?: string;
  manufacturer?: string;
  lastCheck?: {
    id: string;
    seviceId: number;
    inSync: boolean;
    result: string;
    createdAt: string;
  };
  errorMessage?: string;
  legacy?: boolean;
  fullConfigProvisioning: boolean;
};

export enum OltState {
  DEPROVISIONED = 'DEPROVISIONED',
  DEPROVISIONING = 'DEPROVISIONING',
  CHECKING = 'CHECKING',
  ERROR = 'ERROR',
  REBOOTING = 'REBOOTING',
  UPGRADING = 'UPGRADING',
  PROVISIONING = 'PROVISIONING',
  PROVISIONED = 'PROVISIONED'
}
