import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { AuditActorType } from '../../types/audit/AuditActorType';
import { AuditLogEntry } from '../../types/audit/AuditLogEntry';
import { actionDict } from './Audit';

type Props = {
  results: AuditLogEntry[];
};

export function AuditLogListSmall({ results }: Props) {
  return (
    <div className="audit-log-list">
      <ul>
        {results.slice(0, 5).map((log) => (
          <li key={log.id}>
            <span
              className={`audit-icon text-${actionDict[log.actionId].color}`}
            >
              <Icon icon={actionDict[log.actionId].icon} />
            </span>
            <div>
              <div>
                {log.actorTypeId === AuditActorType.ACCOUNT ||
                log.actorTypeId === AuditActorType.APPLICATION ? (
                  <Link
                    to={
                      log.actorTypeId === AuditActorType.ACCOUNT
                        ? `/admin/users/${log.actorId}/view`
                        : `/admin/applications/${log.actorId}`
                    }
                  >
                    {log.actorName}
                  </Link>
                ) : (
                  <span className="text-primary">{log.actorName}</span>
                )}{' '}
                {actionDict[log.actionId].display}{' '}
                {log.objectType.split(':')[1]}
              </div>
              {log?.summary ? (
                <div>
                  <span className="text-muted">{log.summary}</span>
                </div>
              ) : null}
              <div>
                <span className="text-muted">
                  {DateTime.fromISO(log.timestamp).toFormat(
                    'dd/MM/yyyy, HH:mm:ss'
                  )}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
