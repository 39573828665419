import { Button } from '../../../../shared/components/Button';

type Props = {
  email: string;
  deleteEmail: (email: string) => Promise<void>;
};

export function EmailItem({ email, deleteEmail }: Props) {
  return (
    <li className="card">
      <div className="email-list__text">{email}</div>
      <Button type="secondary" onClick={async () => await deleteEmail(email)}>
        Delete
      </Button>
    </li>
  );
}
