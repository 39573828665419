import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { diffObject } from '../../../helpers';
import { AuditLogEntry } from '../../types/audit/AuditLogEntry';
import { ChangeData, ChangeType } from '../../types/ChangeData';

type Props = {
  log: AuditLogEntry;
};

export function AuditLogDiff({ log }: Props) {
  const [changes, setChanges] = useState<{ [key: string]: ChangeData }>({});

  useEffect(() => {
    setChanges(diffObject(log.original, log.updated));
  }, [log]);

  /**
   * Format the changes to render on the page
   */
  function renderChanges(updated: boolean) {
    /**
     * Get the code block lines to render
     */
    function getLines(depth: number, changes: { [key: string]: ChangeData }) {
      const lines: JSX.Element[] = [];

      // Loop through all the changes
      for (const key of Object.keys(changes)) {
        const change = changes[key];

        // Get the action taken as a string (for CSS classes)
        const action =
          change.type === ChangeType.ADDED
            ? 'added'
            : change.type === ChangeType.DELETED
            ? 'deleted'
            : change.type === ChangeType.MODIFIED
            ? 'modified'
            : '';

        // Check if the render is for the "before" side and the type is not unchanged
        if (!updated && change.type !== ChangeType.UNCHANGED) {
          // Add an icon for the change
          lines.push(
            <Icon
              className={`changelog-icon ${action}`}
              icon={
                change.type === ChangeType.ADDED
                  ? `clarity:add-line`
                  : change.type === ChangeType.DELETED
                  ? `clarity:minus-line`
                  : 'clarity:pencil-line'
              }
            />
          );
        }

        // Check if the render is for the "before" side and the type is added
        if (!updated && change.type === ChangeType.ADDED) {
          // Added elements dont need to render in the "before" side so just add a blank line
          lines.push(<span className="added"></span>);
        }

        // Check if the render is for the "after" side and the type is deleted
        if (updated && change.type === ChangeType.DELETED) {
          // Added elements dont need to render in the "after" side so just add a blank line
          lines.push(<span className="deleted"></span>);
        }

        // Work out the indent size based on the recursive depth
        const indent = new Array(depth * 4 + 1).join(' ');

        // Check if this change has children
        if (change.children) {
          // Check if the change should render on this section and render the key
          if (
            !(change.type === ChangeType.ADDED && !updated) &&
            !(change.type === ChangeType.DELETED && updated)
          ) {
            lines.push(
              <span className={action}>
                {indent}&quot;{change.key}&quot;: {'{'}
              </span>
            );
          }

          // Check if the change type is added and it shouldn't render on this section and render a blank line with an icon
          if (change.type === ChangeType.ADDED && !updated) {
            lines.push(
              <Icon
                className={`changelog-icon ${action}`}
                icon="clarity:add-line"
              />
            );
            lines.push(<span className={action}>{indent}</span>);
          }

          // Get all the lines from the chilren and add them to the lines array
          getLines(depth + 1, change.children).forEach((line) =>
            lines.push(line)
          );

          // Check if the change type is deleted
          if (change.type === ChangeType.DELETED) {
            // If rendering the "before" section, render an icon, otherwise render a blank line
            if (!updated) {
              lines.push(
                <Icon
                  className={`changelog-icon ${action}`}
                  icon="clarity:minus-line"
                />
              );
            } else {
              lines.push(<span className={action}>{indent}</span>);
            }
          }

          // Check if the change should render on this section and render the closing "}"
          if (
            !(change.type === ChangeType.ADDED && !updated) &&
            !(change.type === ChangeType.DELETED && updated)
          ) {
            lines.push(
              <span
                className={
                  change.type === ChangeType.ADDED ||
                  change.type === ChangeType.DELETED
                    ? action
                    : ''
                }
              >
                {indent}
                {'}'},
              </span>
            );
          }

          continue;
        }

        // If the key/value pair should not be rendered in this section, skip
        if (
          (change.type === ChangeType.ADDED && !updated) ||
          (change.type === ChangeType.DELETED && updated)
        ) {
          continue;
        }

        // Add the key/value pair to the lines array
        lines.push(
          <span className={action}>
            {indent}&quot;{change.key}&quot;: &quot;
            {(updated ? change.updated : change.original) as string}
            &quot;,
          </span>
        );
      }

      return lines;
    }

    // Return a code tag with all the changes
    return (
      <pre className="code">
        <span>{'{'}</span>
        {getLines(1, changes)}
        <span>{'}'}</span>
      </pre>
    );
  }

  return (
    <>
      <div className="code-block">
        <strong>Before</strong>
        {renderChanges(false)}
      </div>
      <div className="code-block">
        <strong>After</strong>
        {renderChanges(true)}
      </div>
    </>
  );
}
