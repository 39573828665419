import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SWITCH_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { alertError } from '../../../../shared/helpers';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import { Details } from './Details';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { Controls } from './Controls';
import { MessageBanner } from '../../../../shared/components/MessageBanner';
import { CheckResult } from '../../../../shared/types/provisioning/CheckResult';
import { Switch } from '../../../../shared/types/provisioning/Switch';
import { SwitchHealth } from '../../shared/SwitchHealth';

export function SwitchView() {
  const { createAlert } = useAlerts();
  const { id } = useParams<{ id: string }>();
  const [swtch, setSwitch] = useState<Switch>();
  const [lastCheckResult, setLastCheckResult] =
    useState<Record<string, CheckResult>>();

  useEffect(() => {
    async function get() {
      if (id) {
        try {
          const response = await axios.get<ObjectResponse<Switch>>(
            `${SWITCH_ENDPOINT}/${id}`
          );

          setSwitch(response.data.result);

          if (response.data.result.lastCheck) {
            setLastCheckResult(
              JSON.parse(response.data.result.lastCheck.result) as Record<
                string,
                CheckResult
              >
            );
          }
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }
      }
    }

    const interval = setInterval(() => {
      void get();
    }, 5000);

    void get();

    return () => {
      clearInterval(interval);
    };
  }, [id]);

  if (!swtch) {
    return null;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="flex items-center">
          <div className="flex items-center gap-1 flex-1">
            <h1>Switch</h1>
            <span className="text-muted mt-1">
              ({ProvisioningIdPrefix.SWT}-{swtch.id})
            </span>
          </div>
          <div>
            <Controls swich={swtch} setSwitch={setSwitch} />
          </div>
        </div>

        {swtch.errorMessage && (
          <MessageBanner
            title="An error has occurred"
            message={swtch.errorMessage}
            className="mb-2"
          />
        )}

        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <Details swtch={swtch} />
          </div>

          <div className="flex-1">
            <div className="flex gap-2 flex-col">
              <SwitchHealth
                state={swtch.state}
                timestamp={swtch.lastCheck?.createdAt}
                checks={lastCheckResult}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
