import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import logo from '../../assets/logo.png';
import { logout } from '../../store/actions/AuthActions';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';

const tenantId = window.appConfig.AZURE_TENANT_ID || '';
const redirectUri = window.appConfig.AZURE_REDIRECT_URI || '';

export function SignOutPage() {
  const dispatch = useDispatch();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  useEffect(() => {
    dispatch(logout() as unknown as AnyAction);

    setTimeout(
      () =>
        (window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/logout?post_logout_redirect_uri=${redirectUri}`),
      1000
    );
  }, []);

  if (!auth.account) {
    return <Navigate to="/auth/signin" />;
  }

  return (
    <>
      <div className="auth-page-wrapper">
        <div className="auth-page-content">
          <div className="header">
            <img src={logo} alt="logo" width={80} />
          </div>
          <div className="content">
            <h4>Signing out</h4>
            <p>Signing you out of Omni, please wait...</p>
          </div>
        </div>
      </div>
    </>
  );
}
