import axios from 'axios';
import debounce from 'lodash.debounce';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useAlerts } from '../../context/AlertContext';
import { alertError } from '../helpers';
import { PagedResponse } from '../types/response/PagedResponse';
import { Button } from './Button';
import { Paginator } from './Paginator';

const ANY_VALUE = 'ANY_VALUE';

type Option = {
  label: string;
  value: string;
  isDefaultSelected?: boolean;
};

type Filter = {
  label: string;
  key: string;
  options: Option[];
  parseBool?: boolean;
};

type Props<T> = {
  reloader?: number;
  title?: string;
  searchPrompt?: string;
  endpoint: string;
  columns: ReactNode[];
  generateRow: (result: T) => ReactNode[];
  newButtonText?: string;
  onNewButtonClick?: () => void;
  filters?: Filter[];
};

export function PaginatedSearchTable<T>({
  reloader,
  title,
  searchPrompt,
  endpoint,
  columns,
  generateRow,
  newButtonText,
  onNewButtonClick,
  filters
}: Props<T>) {
  const { createAlert } = useAlerts();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [results, setResults] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rawSearchTerm, setRawSearchTerm] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filtersState, setFiltersState] = useState<
    Record<string, string | boolean> | undefined
  >(undefined);

  const updateSearch = useMemo(
    () => debounce((term: string) => setSearchTerm(term), 500),
    []
  );

  async function get() {
    if (filtersState === undefined) {
      return;
    }

    setLoading(true);

    let url = `${endpoint}?page=${page}`;

    if (searchPrompt && searchTerm) {
      url += `&search=${searchTerm}`;
    }

    if (filters?.length) {
      const selectedFilters = Object.values(filtersState).filter(
        (value) => !!value
      );
      if (selectedFilters.length) {
        url += '&filters=' + encodeURIComponent(JSON.stringify(filtersState));
      }
    }

    try {
      const response = await axios.get<PagedResponse<T>>(url);

      setTotalResults(response.data.pagination.total);
      setPageSize(response.data.pagination.limit);
      setResults(response.data.results);
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    void get();
  }, [reloader, page, filtersState]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      void get();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!filters?.length) {
      setFiltersState({});
      return;
    }

    const defaultFiltersState: Record<string, string> = {};

    for (const filter of filters) {
      const defaultOption = filter.options.find(
        (option) => option.isDefaultSelected
      );
      if (defaultOption) {
        defaultFiltersState[filter.key] = defaultOption.value;
      }
    }

    setFiltersState(defaultFiltersState);
  }, []);

  return (
    <>
      <div>
        <div className="flex items-center">
          <div className="flex-1">{title && <h1>{title}</h1>}</div>
          <div className="flex items-center gap-2">
            {
              // Render filter dropdowns
              filters?.map((filter) => (
                <label className="caption-group" key={filter.key}>
                  <span className="caption">{filter.label}</span>
                  <select
                    defaultValue={
                      filter.options.find((option) => option.isDefaultSelected)
                        ?.value ?? ANY_VALUE
                    }
                    onChange={(e) =>
                      setFiltersState({
                        ...filtersState,
                        [filter.key]:
                          filter.parseBool === true &&
                          e.currentTarget.value !== ANY_VALUE
                            ? e.currentTarget.value === 'true'
                            : e.currentTarget.value
                      })
                    }
                    title={`${filter.label} filter`}
                  >
                    <option value={ANY_VALUE}>Any</option>
                    {filter.options.map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>
              ))
            }
            {searchPrompt && (
              <input
                type="text"
                placeholder={searchPrompt}
                value={rawSearchTerm}
                onChange={(e) => {
                  setRawSearchTerm(e.target.value);
                  updateSearch(e.target.value);
                }}
              />
            )}
            {onNewButtonClick && newButtonText && (
              <Button onClick={onNewButtonClick}>{newButtonText}</Button>
            )}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {columns.map((x, i) => (
                <th key={`thead-${i}`}>{x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>Loading...</td>
              </tr>
            ) : (
              <>
                {results.length === 0 && (
                  <tr>
                    <td colSpan={5}>No results found.</td>
                  </tr>
                )}

                {results.map((x, i) => (
                  <tr key={`tr-${i}`}>
                    {generateRow(x).map((y, i2) => (
                      <td key={`td-${i2}`}>{y}</td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {results.length > 0 && (
          <Paginator
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            totalResults={totalResults}
            disabled={loading}
          />
        )}
      </div>
    </>
  );
}
