import { DeviceType } from './DeviceType';
import { Olt } from './Olt';

export type Device = {
  id: number;
  deviceType?: DeviceType;
  deviceTypeId?: number;
  externalReference?: string;
  oltId: number;
  olt: Olt;
  address?: string;
  serial: string;
  onuId?: string;
  ponPort?: string;
  rawPort: string;
  errorMessage?: string;
  updateState?: string;
  locked?: boolean;
  attributes?: { [key: string]: string };
  state: DeviceState;
  lastCheck?: {
    id: string;
    seviceId: number;
    inSync: boolean;
    result: string;
    createdAt: string;
  };
  serviceActive?: boolean;
  test?: boolean;
};

export enum DeviceState {
  IN_DISCOVERY = 'IN_DISCOVERY',
  DISCOVERING = 'DISCOVERING',
  PROVISIONING = 'PROVISIONING',
  DEPROVISIONING = 'DEPROVISIONING',
  PROVISIONED = 'PROVISIONED',
  CHECKING = 'CHECKING',
  ERROR = 'ERROR',
  REBOOTING = 'REBOOTING',
  UPGRADING = 'UPGRADING'
}
