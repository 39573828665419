import axios from 'axios';
import qs from 'qs';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import { AzureTokenData } from '../../shared/types/auth/AzureTokenData';
import { login } from '../../store/actions/AuthActions';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';

const tenantId = window.appConfig.AZURE_TENANT_ID || '';
const clientId = window.appConfig.AZURE_CLIENT_ID || '';
const redirectUri = window.appConfig.AZURE_REDIRECT_URI || '';

export function SignInCallback({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth && auth.token) {
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    async function run() {
      const query = new URLSearchParams(location.search);
      const code = query.get('code');
      const sessionState = query.get('session_state');

      if (query && code && sessionState) {
        const codeVerifier = window.sessionStorage.getItem('omni.codeVerifier');

        const data = {
          grant_type: 'authorization_code',
          code,
          client_id: clientId,
          redirect_uri: redirectUri,
          code_verifier: codeVerifier
        };

        try {
          const response = await axios.request<{
            id_token: string;
          }>({
            method: 'POST',
            url: `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(data)
          });

          navigate(location.pathname, {
            state: {
              search: ''
            }
          });

          dispatch(
            login(response.data as AzureTokenData) as unknown as AnyAction
          );
        } catch (err) {
          console.error(err);
          navigate('auth/signin');
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    void run();
  }, [location.hash]);

  return loading ? (
    <div className="mx-2 my-2">Loading...</div>
  ) : (
    <>{children}</>
  );
}
