import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../error/NotFoundPage';
import { Clone } from './clone/Clone';

export function Build() {
  return (
    <Routes>
      <Route path="/clone" element={<Clone />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
