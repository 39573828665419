import { Icon } from '@iconify/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DEVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { hasAccess } from '../../../../helpers';
import { Button } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { alertError } from '../../../../shared/helpers';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Device,
  DeviceState
} from '../../../../shared/types/provisioning/Device';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { AuthState } from '../../../../store/reducers/AuthReducer';
import { RootState } from '../../../../store/store';

type Props = {
  device: Device;
  setDevice: (device: Device) => void;
  services: Service[];
};

export function Controls({ device, setDevice, services }: Props) {
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { createAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<string>();
  const [showRebootModal, setShowRebootModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [debugDropdown, setDebugDropdown] = useState(false);
  const provisionBtnRef = useRef<HTMLDivElement>(null);
  const [debugModal, setDebugModal] = useState(false);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!provisionBtnRef.current?.contains(e.target as Node)) {
        setDebugDropdown(false);
      }
    }

    window.addEventListener('click', onClick);
  }, [provisionBtnRef]);

  const disableControls =
    loading ||
    device.state === DeviceState.DISCOVERING ||
    device.state === DeviceState.PROVISIONING ||
    device.state === DeviceState.DEPROVISIONING ||
    device.state === DeviceState.CHECKING ||
    device.state === DeviceState.REBOOTING ||
    device.state === DeviceState.UPGRADING;

  async function createJob(
    type: 'check' | 'provision' | 'deprovision' | 'reboot' | 'update',
    debug?: boolean
  ) {
    try {
      setLoading(true);
      const response = await axios.post<ObjectResponse<Device>>(
        `${DEVICE_ENDPOINT}/${device.id}/${type}`,
        debug
          ? {
              debug
            }
          : {}
      );

      if (response && response.data) {
        if (device) {
          setDevice({
            ...device,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function deleteDevice() {
    if (!device) {
      return;
    }

    try {
      await axios.delete(`${DEVICE_ENDPOINT}/${device.id}`);

      createAlert(
        'Device Deleted',
        `Device ${device.id} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  if (
    !auth.account ||
    !hasAccess(auth.account, ['provisioning:device.patch'])
  ) {
    return null;
  }

  return (
    <>
      <div className="flex gap-1">
        {device.state === DeviceState.IN_DISCOVERY && (
          <Button type="secondary" onClick={() => setDeleting(true)}>
            Delete
          </Button>
        )}

        <Button
          type="secondary"
          url={`/provisioning/devices/${device.id}/edit`}
          disabled={disableControls}
        >
          Edit
        </Button>

        {(device.state === DeviceState.ERROR ||
          device?.state === DeviceState.IN_DISCOVERY ||
          device?.state === DeviceState.PROVISIONING) && (
          <div
            ref={provisionBtnRef}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setDebugDropdown(true);
            }}
            className="relative"
          >
            <Button
              type="primary"
              onClick={() => setConfirmationModal('provision')}
              disabled={disableControls}
            >
              {device.state === DeviceState.PROVISIONING ? (
                <>
                  {device.updateState || 'Provisioning'}{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Provision</>
              )}
            </Button>

            {debugDropdown && (
              <div className="dropdown">
                <Button
                  type="secondary"
                  onClick={() => setDebugModal(true)}
                  disabled={disableControls}
                >
                  {device?.state === DeviceState.PROVISIONING ? (
                    <>
                      Provisioning{' '}
                      <Icon className="loader" icon="codicon:loading" />
                    </>
                  ) : (
                    <>Provision (Debug)</>
                  )}
                </Button>
              </div>
            )}
          </div>
        )}

        {(device.state === DeviceState.ERROR ||
          (device.state !== DeviceState.IN_DISCOVERY &&
            device.state !== DeviceState.PROVISIONING)) && (
          <>
            <Button
              type="secondary"
              onClick={() => setConfirmationModal('deprovision')}
              disabled={disableControls}
            >
              {device.state === DeviceState.DEPROVISIONING ? (
                <>
                  Disabling <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Disable</>
              )}
            </Button>

            <Button
              type="secondary"
              onClick={() => setShowRebootModal(true)}
              disabled={disableControls}
            >
              {device.state === DeviceState.REBOOTING ? (
                <>
                  Rebooting <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Reboot</>
              )}
            </Button>

            <Button
              type="secondary"
              onClick={() => setShowUpdateModal(true)}
              disabled={disableControls}
            >
              {device.state === DeviceState.UPGRADING ? (
                <>
                  {device.updateState}{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Upgrade</>
              )}
            </Button>

            <Button
              type="primary"
              onClick={() => void createJob('check')}
              disabled={disableControls}
            >
              {device.state === DeviceState.CHECKING ? (
                <>
                  Checking <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Check</>
              )}
            </Button>
          </>
        )}
      </div>

      {confirmationModal && (
        <ConfirmationModal
          title={`${
            confirmationModal === 'provision' ? 'Provision' : 'Disable'
          } Device`}
          prompt={
            <>
              Are you sure you want to{' '}
              {confirmationModal === 'deprovision'
                ? 'disable'
                : confirmationModal}{' '}
              this device?
              {confirmationModal === 'deprovision' &&
                services &&
                services.filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                  .length > 0 && (
                  <>
                    <br />
                    These services will be deprovisioned:{' '}
                    <ul>
                      {services
                        .filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                        .map((x) => (
                          <li key={x.id}>
                            <Link to={`/provisioning/services/${x.id}`}>
                              {ProvisioningIdPrefix.SVC}-{x.id}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setConfirmationModal(undefined);
              void createJob(
                confirmationModal === 'provision' ? 'provision' : 'deprovision'
              );
            } else {
              setConfirmationModal(undefined);
            }
          }}
          denyText="Cancel"
          confirmText={
            confirmationModal === 'provision' ? 'Provision' : 'Disable'
          }
        />
      )}

      {showRebootModal && (
        <ConfirmationModal
          title="Reboot Device"
          prompt={
            <>
              Are you sure you want to reboot this device? This may take several
              minutes.
              {services &&
                services.filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                  .length > 0 && (
                  <>
                    <br />
                    These services will be unavailable while rebooting:{' '}
                    <ul>
                      {services
                        .filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                        .map((x) => (
                          <li key={x.id}>
                            <Link to={`/provisioning/services/${x.id}`}>
                              {ProvisioningIdPrefix.SVC}-{x.id}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowRebootModal(false);
              void createJob('reboot');
            } else {
              setShowRebootModal(false);
            }
          }}
        />
      )}

      {showUpdateModal && (
        <ConfirmationModal
          title="Upgrade Device"
          prompt={
            <>
              Are you sure you want to upgrade this device? This may take
              several minutes.
              {services &&
                services.filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                  .length > 0 && (
                  <>
                    <br />
                    These services will be unavailable while upgrading:{' '}
                    <ul>
                      {services
                        .filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                        .map((x) => (
                          <li key={x.id}>
                            <Link to={`/provisioning/services/${x.id}`}>
                              {ProvisioningIdPrefix.SVC}-{x.id}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowUpdateModal(false);
              void createJob('update');
            } else {
              setShowUpdateModal(false);
            }
          }}
        />
      )}

      {deleting && device && (
        <ConfirmationModal
          title="Delete Device"
          prompt={
            <>
              Are you sure you want to delete device{' '}
              <strong>{device.id}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteDevice();
              navigate(`/provisioning/devices/${device.id}`);
            }

            setDeleting(false);
          }}
        />
      )}

      {debugModal && (
        <ConfirmationModal
          title="Provision Debug"
          prompt={
            <>
              Are you sure you want to provision this device in debug mode?
              <br />
              If the device fails to provision or any checks fail, config will
              not be automatically removed and the device will be marked as
              provisioned even if it isn&apos;t.
              <br />
              <br />
              <strong>
                WARNING: Only do this if you know what you are doing!!!
              </strong>
              <br />
              <br />
              Once you have finished debugging, deprovision the device and
              ensure all related config has been removed from the OLT.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setDebugModal(false);
              void createJob('provision', true);
            } else {
              setDebugModal(false);
            }
          }}
        />
      )}
    </>
  );
}
