import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Modal } from '../../../shared/components/Modal';
import { MANUFACTURER_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { Manufacturer } from '../../../shared/types/provisioning/Manufacturer';

type Props = {
  onClose: (reload: boolean) => void;
  manufacturerId?: string;
};

type Form = {
  name: string;
};

export function ManufacturerModal({ onClose, manufacturerId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function get() {
      if (manufacturerId) {
        setLoading(true);

        const response = await axios.get<ObjectResponse<Manufacturer>>(
          `${MANUFACTURER_ENDPOINT}/${manufacturerId}`
        );

        const manufacturer = response.data.result;
        form.setValue('name', manufacturer.name);

        setLoading(false);
      }
    }

    void get();
  }, [manufacturerId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      await axios.request({
        url: manufacturerId
          ? `${MANUFACTURER_ENDPOINT}/${manufacturerId}`
          : MANUFACTURER_ENDPOINT,
        method: manufacturerId ? 'PATCH' : 'POST',
        data
      });

      onClose(true);

      createAlert(
        `Manufacturer ${manufacturerId ? 'Updated' : 'Added'}`,
        `Manufacturer ${data.name} ${
          manufacturerId ? 'updated' : 'added'
        } successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${manufacturerId ? 'Edit' : 'Add'} Manufacturer`}
      onClose={() => onClose(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup
          form={form}
          name="name"
          label="Name"
          placeholder="Manufacturer Name"
          validationOptions={{
            required: 'Please enter a manufacturer name'
          }}
        />

        <div className="flex justify-end mt-2 gap-1">
          <Button
            type="secondary"
            disabled={loading}
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button disabled={loading} submit>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
