import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DEVICE_ENDPOINT,
  INCIDENT_ENDPOINT,
  SERVICE_ENDPOINT
} from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { MessageBanner } from '../../../shared/components/MessageBanner';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import {
  Incident,
  IncidentState
} from '../../../shared/types/monitoring/Incident';
import { MonitoringIdPrefix } from '../../../shared/types/monitoring/MonitoringIdPrefix';
import { Service } from '../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { ObjectsResponse } from '../../../shared/types/response/ObjectsResponse';
import { LegacyOltDeviceGroups, OltDeviceGroups } from './types';
import { AuditLog } from './view/AuditLog';
import { Controls } from './view/Controls';
import { Details } from './view/Details';
import { IncidentModal } from './view/IncidentModal';
import { NearbySiteAlarmsTable } from './view/NearbySiteAlarmsTable';
import { RelatedDevices } from './view/RelatedDevices';

type Props = {
  edit?: boolean;
};

export function IncidentView({ edit }: Props) {
  const { createAlert } = useAlerts();
  const { id } = useParams<{ id: string }>();
  useDocumentTitle(`${MonitoringIdPrefix.INC}-${id || '?'}`);
  const navigate = useNavigate();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [incident, setIncident] = useState<Incident>();
  const [services, setServices] = useState<Service[]>([]);
  const [oltDeviceGroups, setOltDeviceGroups] = useState<
    OltDeviceGroups | LegacyOltDeviceGroups
  >({});
  const [isLoadingDevices, setIsLoadingDevices] = useState<boolean>(false);

  // retrieve services and devices when incident is populated
  useEffect(() => {
    async function getServices() {
      if (!id || !incident || !incident.linkedDeviceIds.length) return;

      // Omni only - Legacy linkedDeviceIds will always be empty
      try {
        const response = await axios.get<ObjectsResponse<Service>>(
          `${SERVICE_ENDPOINT}/services-by-devices?deviceIds=${incident.linkedDeviceIds.join(
            ','
          )}`
        );

        setServices(response.data.results);
      } catch (err) {
        console.error(err);

        alertError(createAlert, err);
      }
    }

    async function getDevices() {
      if (!id || !incident) return;

      try {
        setIsLoadingDevices(true);

        if (incident.isLegacySite) {
          // legacy site doesn't have devices, just get serials from alarms
          const response = await axios.get<
            ObjectResponse<LegacyOltDeviceGroups>
          >(
            `${INCIDENT_ENDPOINT}/incident-grouped-alarms?incidentId=${incident.id}`
          );

          setOltDeviceGroups(response.data.result);
        } else {
          if (!incident.linkedDeviceIds.length) return;

          // omni sites have full access to devices
          const response = await axios.get<ObjectResponse<OltDeviceGroups>>(
            `${DEVICE_ENDPOINT}/devices-by-ids?deviceIds=${incident.linkedDeviceIds.join(
              ','
            )}`
          );

          setOltDeviceGroups(response.data.result);
        }
      } catch (err) {
        console.error(err);

        alertError(createAlert, err);
      } finally {
        setIsLoadingDevices(false);
      }
    }

    void getServices();
    void getDevices();

    const interval = setInterval(() => {
      void getServices();
      void getDevices();
    }, 7500);

    return () => {
      clearInterval(interval);
    };
  }, [incident?.id]);

  // retrieve incident
  useEffect(() => {
    async function getIncident() {
      if (!id) return;

      try {
        const response = await axios.get<ObjectResponse<Incident>>(
          `${INCIDENT_ENDPOINT}/${id}`
        );
        setIncident(response.data.result);
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }

    void getIncident();

    const interval = setInterval(() => {
      void getIncident();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [id, reloader]);

  function incidentBanner(state: IncidentState): JSX.Element | null {
    switch (state) {
      case IncidentState.CHECKING:
        return (
          <MessageBanner
            type="warning"
            title="Checking"
            message="Checks are running on the OLTs in this incident."
          />
        );
      default:
        return null;
    }
  }

  if (!incident) {
    return null;
  }

  const impactedCount = incident.isLegacySite
    ? Object.values(oltDeviceGroups as LegacyOltDeviceGroups).reduce<number>(
        (total, group) => total + group.devices.length,
        0
      )
    : services.length;

  return (
    <div className="page-wrapper">
      {incidentBanner(incident.state)}

      <div className="flex items-center">
        <div className="flex items-center gap-1 flex-1">
          <h1>Incident</h1>
          <span className="text-muted mt-1">
            ({MonitoringIdPrefix.INC}-{incident.id})
          </span>
        </div>
        <div>
          <Controls
            incident={incident}
            oltDeviceGroups={oltDeviceGroups}
            reload={reload}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <div className="flex flex-col gap-2">
          <Details incident={incident} impactedCount={impactedCount} />
          <AuditLog incident={incident} />
        </div>

        <div className="flex-1">
          <div className="flex gap-2 flex-col">
            <RelatedDevices
              oltDeviceGroups={oltDeviceGroups}
              services={services}
              isLegacySite={incident.isLegacySite}
              isLoading={isLoadingDevices}
            />

            <NearbySiteAlarmsTable
              incidentId={parseInt(id ?? '0')}
              isLegacy={incident.isLegacySite}
              reload={reload}
            />
          </div>
        </div>
      </div>

      {edit && (
        <IncidentModal
          onClose={() => navigate(`/monitoring/incidents/${incident.id}`)}
          incidentId={incident.id}
        />
      )}
    </div>
  );
}
