import { Attribute } from './shared/types/provisioning/Attribute';
import { Account } from './shared/types/auth/Account';
import { ChangeData, ChangeType } from './shared/types/ChangeData';

export function hasAccess(account: Account, nodes: string[]): boolean {
  if (!account || !nodes) {
    return false;
  }

  const scope = account.scope;

  // Compare each listed node
  for (const key of nodes) {
    // Check if has any of the listed permissions
    if (scope.includes(key)) {
      return true;
    }
  }

  return false;
}

function dec2hex(dec: number): string {
  return ('0' + dec.toString(16)).substring(-2);
}

export function generateCodeVerifier(): string {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

function sha256(plain: string): Promise<ArrayBuffer> {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(array: ArrayBuffer): string {
  let str = '';
  const bytes = new Uint8Array(array);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export async function generateCodeChallengeFromVerifier(
  verifier: string
): Promise<string> {
  const hashed = await sha256(verifier);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

export function hash53(str: string, seed = 0): number {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function randomString(length: number): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function parseAttributes(attributes: {
  [key: string]: string;
}): Attribute[] {
  return Object.keys(attributes).map((key) =>
    attributes[key]
      ? {
          id: key,
          value: attributes[key]
        }
      : { id: key }
  );
}

export function diffObject(original: string, updated: string) {
  /**
   * Get the changes between 2 objects
   */
  function diff(
    original: unknown,
    updated: unknown
  ): { [key: string]: ChangeData } {
    const changes: { [key: string]: ChangeData } = {};

    // Verify the data is an object
    if (
      (original && typeof original !== 'object') ||
      original === null ||
      (updated && typeof updated !== 'object' && updated !== null) ||
      updated === null
    ) {
      return {};
    }

    // Convert the variables to objects
    const originalData = original as { [key: string]: unknown };
    const updatedData = updated as { [key: string]: unknown };

    // Loop through every value in the original object to find modifications or deletions
    for (const key of Object.keys(originalData)) {
      const originalValue = originalData[key];
      const updatedValue = updatedData ? updatedData[key] : undefined;

      // Check if the value has been deleted or modified
      let type = ChangeType.UNCHANGED;
      if (updatedValue === undefined) {
        type = ChangeType.DELETED;
      } else if (originalValue !== updatedValue) {
        type = ChangeType.MODIFIED;
      }

      let children: { [key: string]: ChangeData } | undefined = undefined;

      // If the current value is another object
      if (typeof originalValue === 'object') {
        // If both values (extracted via the key) arent undefined then the key is unchanged
        if (
          (originalValue !== undefined && updatedValue !== undefined) ||
          (originalValue !== null && updatedValue !== null)
        ) {
          type = ChangeType.UNCHANGED;
        }

        // Run this diff function with the values to get the changes
        children = diff(
          originalValue as { [key: string]: unknown },
          updatedValue
        );
      }

      // Add the change (and its children, if applicable) to the changes array
      changes[key] = {
        key,
        type,
        original: originalValue,
        updated: updatedValue,
        children
      };
    }

    // Check if there is any updated data
    if (updatedData) {
      // Look through every value in the updated object to find additions
      for (const key of Object.keys(updatedData)) {
        const originalValue = originalData[key];
        const updatedValue = updatedData[key];

        // If the original object does not contain this key, it is a new addition
        if (originalValue === undefined) {
          let children: { [key: string]: ChangeData } | undefined = undefined;
          if (typeof updatedValue === 'object') {
            children = diff({}, updatedValue);
          }

          // Add the change (and its children, if applicable) to the changes array
          changes[key] = {
            key,
            type: ChangeType.ADDED,
            original: originalValue,
            updated: updatedValue,
            children
          };
        }
      }
    }

    // Return all the changes (and child changes)
    return changes;
  }

  // Return all differences between the objects
  return diff(JSON.parse(original), JSON.parse(updated));
}
