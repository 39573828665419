import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Modal } from '../../../shared/components/Modal';
import { PRODUCT_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import {
  Product,
  ProductType
} from '../../../shared/types/provisioning/Product';
import { AttributeEditor } from '../../../shared/components/AttributeEditor';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { Attribute } from '../../../shared/types/provisioning/Attribute';
import { parseAttributes } from '../../../helpers';

type Props = {
  onClose: (reload: boolean) => void;
  productId?: string;
};

type Form = {
  name: string;
  type: ProductType;
};

export function ProductModal({ onClose, productId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  useEffect(() => {
    async function get() {
      if (productId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<Product>>(
            `${PRODUCT_ENDPOINT}/${productId}`
          );

          if (response.data.result.attributes) {
            setAttributes(parseAttributes(response.data.result.attributes));
          }

          const product = response.data.result;
          form.setValue('name', product.name);
          form.setValue('type', product.type);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    void get();
  }, [productId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request({
        url: productId ? `${PRODUCT_ENDPOINT}/${productId}` : PRODUCT_ENDPOINT,
        method: productId ? 'PATCH' : 'POST',
        data: { ...data, attributes }
      });

      console.log(response.data);

      onClose(true);

      createAlert(
        `Product ${productId ? 'Updated' : 'Added'}`,
        `Product ${data.name} ${productId ? 'updated' : 'added'} successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${productId ? 'Edit' : 'Add'} Product`}
      onClose={() => onClose(false)}
      size="xl"
    >
      <div className="flex gap-3">
        <div className="flex-1">
          <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
            <InputGroup
              form={form}
              name="name"
              label="Name"
              placeholder="Product Name"
              validationOptions={{
                required: 'Please enter a product name'
              }}
            />

            <InputGroup
              form={form}
              name="type"
              label="Type"
              type="select"
              placeholder="Select Product Type"
              validationOptions={{
                required: 'Please select a product type'
              }}
              options={Object.keys(ProductType).map((x) => ({
                label: x,
                value: x
              }))}
            />
          </form>
        </div>
        <div className="flex-2">
          <AttributeEditor
            attributes={attributes}
            setAttributes={setAttributes}
          />
        </div>
      </div>

      <div className="flex justify-end mt-2 gap-1">
        <Button
          type="secondary"
          disabled={loading}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
