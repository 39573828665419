import { NETBOX_DCIM_ENDPOINT } from '../../../../constants';
import { Badge } from '../../../../shared/components/Badge';
import {
  Switch,
  SwitchState
} from '../../../../shared/types/provisioning/Switch';

type Props = {
  swtch: Switch;
};

export function Details({ swtch }: Props) {
  return (
    <div>
      <div className="card flex flex-col gap-2 sidepanel">
        <div>
          <div className="mb-1">
            <strong>State</strong>
          </div>
          <Badge
            type={
              swtch.state === SwitchState.DEPROVISIONED
                ? undefined
                : swtch.state === SwitchState.ERROR
                ? 'red'
                : swtch.state === SwitchState.PROVISIONED
                ? 'green'
                : 'blue'
            }
          >
            {swtch.state}
          </Badge>
        </div>
        <div>
          <div className="mb-1">
            <strong>Name</strong>
          </div>
          {swtch.name}
        </div>
        <div>
          <div className="mb-1">
            <strong>Model</strong>
          </div>
          {swtch.model}
        </div>
        <div>
          <div className="mb-1">
            <strong>Manufacturer</strong>
          </div>
          {swtch.manufacturer}
        </div>
        <div>
          <div className="mb-1">
            <strong>Netbox</strong>
          </div>
          <a
            href={`${NETBOX_DCIM_ENDPOINT}/devices/${swtch.netboxId}`}
            target="_blank"
            rel="noreferrer"
          >
            {swtch.name}
          </a>{' '}
          <span className="text-muted">({swtch.ip})</span>
        </div>
      </div>
    </div>
  );
}
