import { ProductType } from './Product';

export type Interface = {
  id?: number;
  name: string;
  type: InterfaceType;
  supportedProductTypes: ProductType[];
  deviceTypeId: number;
};

export enum InterfaceType {
  HundredMegEthernet = 'HundredMegEthernet',
  GigabitEthernet = 'GigabitEthernet',
  TenGigabitEthernet = 'TenGigabitEthernet',
  POTS = 'POTS',
  TwoPointFiveGigabitEthernet = 'TwoPointFiveGigabitEthernet'
}
