import { RegisterOptions, UseFormReturn } from 'react-hook-form';

type Props = {
  // We dont know or need to know the type for this but a generic doesnt work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form?: UseFormReturn<any>;
  id?: string;
  name: string;
  label?: string;
  type?: 'text' | 'password' | 'select' | 'textarea' | 'checkbox' | 'file';
  placeholder?: string;
  validationOptions?: RegisterOptions;
  options?: {
    label: string;
    value: string;
  }[];
  disabled?: boolean;
};

export function InputGroup({
  form,
  id,
  name,
  label,
  type,
  placeholder,
  validationOptions,
  options,
  disabled
}: Props) {
  function getElement() {
    switch (type || 'text') {
      case 'text':
      case 'password':
        return (
          <input
            id={id}
            type={type || 'text'}
            placeholder={placeholder}
            className={`${form && form.formState.errors[name] ? 'error' : ''}`}
            {...(form ? form.register(name, validationOptions) : {})}
            disabled={disabled}
          />
        );
      case 'select':
        return (
          <select
            id={id}
            {...(form ? form.register(name, validationOptions) : {})}
            disabled={disabled}
          >
            {placeholder && <option value="">({placeholder})</option>}
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        );
      case 'textarea':
        return (
          <textarea
            id={id}
            className={`${form && form.formState.errors[name] ? 'error' : ''}`}
            placeholder={placeholder}
            {...(form ? form.register(name, validationOptions) : {})}
            rows={5}
          />
        );
      case 'checkbox':
      case 'file':
        return (
          <input
            id={id}
            type={type}
            {...(form ? form.register(name, validationOptions) : {})}
          />
        );
    }
  }

  return (
    <div className="input-group">
      {label && type !== 'checkbox' && (
        <label htmlFor={id}>
          {label}
          {validationOptions && validationOptions.required && (
            <span className="required">*</span>
          )}
        </label>
      )}

      {getElement()}

      {type === 'checkbox' && (
        <label htmlFor={id}>
          {label}
          {validationOptions && validationOptions.required && (
            <span className="required">*</span>
          )}
        </label>
      )}

      {form && form.getFieldState(name).error && (
        <span className="input-group-error">
          {form && form.getFieldState(name).error?.message}
        </span>
      )}
    </div>
  );
}
