import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { Modal } from '../../../shared/components/Modal';
import {
  DEVICE_ENDPOINT,
  PRODUCT_ENDPOINT,
  SERVICE_ENDPOINT
} from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import { AttributeEditor } from '../../../shared/components/AttributeEditor';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { Attribute } from '../../../shared/types/provisioning/Attribute';
import { parseAttributes } from '../../../helpers';
import { Device } from '../../../shared/types/provisioning/Device';
import { RemoteDropdownSelect } from '../../../shared/components/RemoteDropdownSelect';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Service } from '../../../shared/types/provisioning/Service';
import { Product } from '../../../shared/types/provisioning/Product';
import { Interface } from '../../../shared/types/provisioning/Interface';

type Props = {
  onClose: (id?: number) => void;
  serviceId?: string;
};

type Form = {
  productId: number;
  deviceId: number;
  interfaceId: number;
  externalReference: string;
};

export function ServiceModal({ onClose, serviceId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const watch = useWatch({
    control: form.control,
    name: 'deviceId'
  });

  useEffect(() => {
    async function get() {
      if (serviceId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<Service>>(
            `${SERVICE_ENDPOINT}/${serviceId}`
          );

          if (response.data.result.attributes) {
            setAttributes(parseAttributes(response.data.result.attributes));
          }

          const service = response.data.result;
          form.setValue('productId', service.productId);
          form.setValue('deviceId', service.deviceId);
          form.setValue('interfaceId', service.interfaceId);
          form.setValue('externalReference', service.externalReference || '');
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    void get();
  }, [serviceId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request<ObjectResponse<Service>>({
        url: serviceId ? `${SERVICE_ENDPOINT}/${serviceId}` : SERVICE_ENDPOINT,
        method: serviceId ? 'PATCH' : 'POST',
        data: { ...data, attributes }
      });

      console.log(response.data);

      onClose(serviceId ? undefined : response.data.result.id);

      createAlert(
        `Service ${serviceId ? 'Updated' : 'Added'}`,
        `Service ${serviceId ? 'updated' : 'added'} successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${serviceId ? 'Edit' : 'Add'} Service`}
      onClose={() => onClose()}
      size="xl"
    >
      <div className="flex gap-3">
        <div className="flex-1">
          <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
            <RemoteDropdownSelect
              form={form}
              name="productId"
              label="Product"
              searchPrompt="Find a product..."
              endpoint={`${PRODUCT_ENDPOINT}`}
              generateItem={(item: Product) => ({
                key: `${ProvisioningIdPrefix.PRD}-${item.id} (${item.name})`,
                value: `${item.id}`
              })}
              placeholder="Select a product"
              validationOptions={{
                required: 'Please select a product'
              }}
            />

            <RemoteDropdownSelect
              form={form}
              name="deviceId"
              label="Device"
              searchPrompt="Find a device..."
              endpoint={`${DEVICE_ENDPOINT}`}
              generateItem={(item: Device) => ({
                key: `${ProvisioningIdPrefix.DEV}-${item.id} (${item.serial})`,
                value: `${item.id}`
              })}
              placeholder="Select a device"
              validationOptions={{
                required: 'Please select a device'
              }}
            />

            {watch && (
              <RemoteDropdownSelect
                form={form}
                name="interfaceId"
                label="Interface"
                searchPrompt="Find an interface..."
                endpoint={
                  serviceId
                    ? `${DEVICE_ENDPOINT}/${watch}/interfaces?serviceId=${serviceId}`
                    : `${DEVICE_ENDPOINT}/${watch}/interfaces`
                }
                generateItem={(item: Interface) => ({
                  key: item.name,
                  value: `${item.id || ''}`
                })}
                placeholder="Select an interface"
                validationOptions={{
                  required: 'Please select an interface'
                }}
                disablePagination
              />
            )}

            <InputGroup
              form={form}
              name="externalReference"
              type="textarea"
              label="External Reference"
              placeholder="Service External Reference"
              validationOptions={{
                required: 'Please enter a service external reference'
              }}
            />
          </form>
        </div>
        <div className="flex-2">
          <AttributeEditor
            attributes={attributes}
            setAttributes={setAttributes}
          />
        </div>
      </div>

      <div className="flex justify-end mt-2 gap-1">
        <Button type="secondary" disabled={loading} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
