import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  type?: 'green' | 'red' | 'yellow' | 'blue' | 'grey' | 'omni';
  size?: 'sm' | 'md';
  title?: string;
  className?: string;
};

export function Badge({ children, type, size, title, className }: Props) {
  return (
    <div
      className={`badge badge-${type || 'grey'} badge-${size || 'md'} ${
        className ?? ''
      }`}
      title={title}
    >
      {children}
    </div>
  );
}
