import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SERVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { hasAccess } from '../../../../helpers';
import { Badge } from '../../../../shared/components/Badge';
import { Button } from '../../../../shared/components/Button';
import { alertError } from '../../../../shared/helpers';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';
import { AuthState } from '../../../../store/reducers/AuthReducer';
import { RootState } from '../../../../store/store';

type Props = {
  service: Service;
  setService: React.Dispatch<React.SetStateAction<Service | undefined>>;
};

export function Details({ service, setService }: Props) {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { createAlert } = useAlerts();
  const [serviceState, setServiceState] = useState<ServiceState>(service.state);
  const [isOverridingState, setIsOverridingState] = useState<boolean>(false);
  const [isPostingStateUpdate, setIsPostingStateUpdate] =
    useState<boolean>(false);

  async function updateState(e: ChangeEvent<HTMLSelectElement>): Promise<void> {
    const oldState = serviceState;
    const newState = e.currentTarget.value as ServiceState;
    setIsPostingStateUpdate(true);
    setServiceState(newState);

    try {
      const response = await axios.patch(`${SERVICE_ENDPOINT}/${service.id}`, {
        deviceId: service.deviceId,
        productId: service.productId,
        interfaceId: service.interfaceId,
        attributes: Object.entries(service.attributes ?? {})?.map(
          ([id, value]) => ({
            id,
            value
          })
        ),
        externalReference: service.externalReference,
        state: newState
      });

      if (!response?.data) {
        throw new Error('No response received. Please try again');
      }

      setService({ ...service, state: newState });

      createAlert(
        'Service Updated',
        `${ProvisioningIdPrefix.SVC}-${service.id} state has been overridden from ${oldState} to ${newState}`,
        'success'
      );

      setIsOverridingState(false);
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
      setServiceState(oldState);
    } finally {
      setIsPostingStateUpdate(false);
    }
  }

  return (
    <div>
      <div className="card flex flex-col gap-2 sidepanel">
        <div>
          <div className="mb-1">
            <strong>Service State</strong>
          </div>
          <div className="flex gap-2 items-center">
            {isOverridingState ? (
              <>
                <select
                  onChange={async (e) => {
                    await updateState(e);
                  }}
                  disabled={isPostingStateUpdate}
                  className="px-1"
                >
                  {Object.values(ServiceState).map((state) => (
                    <option
                      value={state}
                      key={state}
                      selected={state === serviceState}
                    >
                      {state}
                    </option>
                  ))}
                </select>
                <div className="ml-auto">
                  <Button
                    onClick={() => setIsOverridingState(false)}
                    type="secondary"
                    disabled={isPostingStateUpdate}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Badge
                  type={
                    service.state === ServiceState.NOT_PROVISIONED
                      ? undefined
                      : service.state === ServiceState.ERROR
                      ? 'red'
                      : service.state === ServiceState.PROVISIONED
                      ? 'green'
                      : 'blue'
                  }
                >
                  {service.state}
                </Badge>
                {!!auth.account &&
                  hasAccess(auth.account, [
                    'provisioning:service.override-state'
                  ]) && (
                    <div className="ml-auto">
                      <Button onClick={() => setIsOverridingState(true)}>
                        Override
                      </Button>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
        <div>
          <div className="mb-1">
            <strong>Product</strong>
          </div>
          <Link to={`/provisioning/products/${service.productId}`}>
            {service.product?.name}{' '}
          </Link>
          <span className="text-muted">
            ({ProvisioningIdPrefix.PRD}-{service.productId})
          </span>
        </div>
        <div>
          <div className="mb-1">
            <strong>Device</strong>
          </div>
          <Link to={`/provisioning/devices/${service.deviceId}`}>
            {service.device?.serial}{' '}
          </Link>
          <span className="text-muted">
            ({ProvisioningIdPrefix.DEV}-{service.deviceId})
          </span>
        </div>
        <div>
          <div className="mb-1">
            <strong>Device Type</strong>
          </div>
          <Link
            to={`/provisioning/device-types/${
              service.device?.deviceTypeId || ''
            }`}
          >
            {service.device?.deviceType?.name}{' '}
          </Link>
          <span className="text-muted">
            ({ProvisioningIdPrefix.DVT}-{service.device?.deviceTypeId})
          </span>
        </div>
      </div>
    </div>
  );
}
