import { ReactNode, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

type Props = {
  className?: string;
  items: {
    id: string;
    title: ReactNode;
    subTitle?: ReactNode;
    content: ReactNode;
    right?: ReactNode;
    className?: string;
    closed?: boolean;
  }[];
};

export function CollapsibleItemList({ className, items }: Props) {
  const [open, setOpen] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    const state: { [id: string]: boolean } = {};

    for (const item of items) {
      state[item.id] = !item.closed;
    }

    setOpen(state);
  }, []);

  return (
    <div className={`ml-3 flex-1 ${className ?? ''}`}>
      {items.map((x, i) => (
        <div key={x.id}>
          <div className={`${i > 0 ? 'mt-2' : ''}`}>
            <div
              className="flex-1 flex hover"
              onClick={() => {
                setOpen({ ...open, [x.id]: !open[x.id] });
              }}
            >
              <div className="flex-1 flex items-center gap-1">
                <Icon
                  icon={
                    open[x.id]
                      ? 'codicon:triangle-down'
                      : 'codicon:triangle-right'
                  }
                />{' '}
                <div className="flex-1">{x.title}</div>
              </div>
              <div>{x.right}</div>
            </div>
            {x.subTitle && (
              <div className="ml-3 text-muted text-sm">{x.subTitle}</div>
            )}
          </div>
          {open[x.id] && <div className="mt-1 ml-3">{x.content}</div>}
          {i < items.length - 1 && <div className="separator mt-1"></div>}
        </div>
      ))}
    </div>
  );
}
