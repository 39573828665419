import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { Button } from '../../shared/components/Button';

export function AwaitingApproval() {
  const navigate = useNavigate();

  return (
    <div className="auth-page-wrapper">
      <div className="auth-page">
        <div>
          <img src={logo} alt="logo" width="80" />
        </div>
        <div className="content">
          <h3>Awaiting Approval</h3>
          <p>
            Your Omni account has not yet been activated by an administrator.
            <br />
            If you have been waiting for more than 2 days, please contact{' '}
            <a className="email" href="mailto:itsupport@netomnia.com">
              itsupport@netomnia.com
            </a>
          </p>
          <Button onClick={() => navigate('/auth/signout')}>Sign out</Button>
        </div>
      </div>
    </div>
  );
}
