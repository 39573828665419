import { Icon } from '@iconify/react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Badge } from '../../../shared/components/Badge';
import { CheckSolutionModal } from '../../../shared/components/CheckSolutionModal';
import { CollapsibleCard } from '../../../shared/components/CollapsibleCard';
import { CheckResult } from '../../../shared/types/provisioning/CheckResult';
import { ServiceState } from '../../../shared/types/provisioning/Service';
import { CheckTable } from './CheckTable';

type Props = {
  state: ServiceState;
  timestamp?: string;
  checks?: Record<string, CheckResult>;
};

export function ServiceHealth({ state, timestamp, checks }: Props) {
  const [showSolution, setShowSolution] = useState<CheckResult>();

  function canShow() {
    return state === ServiceState.PROVISIONED;
  }

  function hasCheckResult(result: string) {
    if (checks) {
      return !!Object.keys(checks).find((x) => checks[x].result === result);
    }

    return false;
  }

  return (
    <>
      <CollapsibleCard
        title={<strong>Service Health</strong>}
        right={
          <div className="flex items-center gap-1">
            {timestamp && canShow() && (
              <div className="text-muted">
                {DateTime.fromISO(timestamp).toFormat('HH:mm dd/MM/yyyy')}
              </div>
            )}
            {canShow() && (
              <>
                {hasCheckResult('ERROR') ? (
                  <Badge type="red">ERROR</Badge>
                ) : hasCheckResult('WARNING') ? (
                  <Badge type="yellow">WARNING</Badge>
                ) : (
                  <Badge type="green">HEALTHY</Badge>
                )}
              </>
            )}
          </div>
        }
      >
        {checks && canShow() ? (
          <CheckTable checks={checks} onClick={setShowSolution} />
        ) : state === ServiceState.CHECKING ? (
          <Icon className="loader" icon="codicon:loading" />
        ) : (
          'Provision to view details.'
        )}
      </CollapsibleCard>

      {showSolution && (
        <CheckSolutionModal
          check={showSolution}
          onClose={() => setShowSolution(undefined)}
        />
      )}
    </>
  );
}
