import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SETTINGS_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { hasAccess } from '../../../helpers';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { Settings } from '../../../shared/types/auth/Settings';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { AuthState } from '../../../store/reducers/AuthReducer';
import { RootState } from '../../../store/store';
import { EmailList } from './view/EmailList';
import { GroupList } from './view/GroupList';

export function SettingsPage() {
  useDocumentTitle('Settings');
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { createAlert } = useAlerts();
  const [settings, setSettings] = useState<Settings>();

  // retrieve settings
  useEffect(() => {
    async function getSettings(): Promise<void> {
      try {
        const response = await axios.get<ObjectResponse<Settings>>(
          `${SETTINGS_ENDPOINT}`
        );
        setSettings(response.data.result);
      } catch (err) {
        console.error('Error getting admin settings', err);
        alertError(createAlert, err);
      }
    }

    void getSettings();
  }, []);

  async function updateSettings(
    newSettings: Partial<Settings>,
    alertTitle?: string
  ): Promise<void> {
    try {
      const response = await axios.patch<ObjectResponse<Settings>>(
        `${SETTINGS_ENDPOINT}`,
        { updatedFields: newSettings }
      );

      setSettings(response.data.result);

      createAlert(
        alertTitle || 'Settings Updated',
        `Settings have been updated successfully`,
        'success'
      );
    } catch (err) {
      console.error(`Error updating admin settings`, err);
      alertError(createAlert, err);
    }
  }

  const noPatchPermissions =
    !auth.account || !hasAccess(auth.account, ['auth:settings.patch']);

  return (
    <>
      <div className="page-wrapper">
        <h1>Settings</h1>

        {noPatchPermissions ? (
          <>You do not have permissions to update settings.</>
        ) : settings ? (
          <>
            <div className="card">
              <h2 className="mt-1">Incident raised notifications</h2>
              <p>
                Select the groups to be sent an email and SMS notification when
                an incident is first raised.
              </p>
              <GroupList
                groupIds={settings.incidentRaisedNotificationGroupIds}
                updateSettings={updateSettings}
              />
            </div>

            <div className="card mt-3">
              <h2 className="mt-1">Incident update notifications</h2>
              <p>
                Enter the email addresses to be emailed an incident CSV when an
                incident is confirmed, resolved, reopened, or on update request.
              </p>
              <p>Can be comma-separated list.</p>
              <EmailList
                emails={settings.incidentConfirmedNotificationEmails}
                updateSettings={updateSettings}
              />
            </div>
          </>
        ) : (
          <p>Loading settings...</p>
        )}
      </div>
    </>
  );
}
