import axios from 'axios';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { NETBOX_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { InputGroup } from '../../../shared/components/InputGroup';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { Prefix } from '../../../shared/types/Prefix';
import { Response } from '../../../shared/types/response/Response';
import { Template } from '../../../shared/types/Template';
import { PrefixBoxTemplate } from './PrefixBoxTemplate';

export type SitePrefix = {
  prefixId?: string;
  cidr?: string;
};

type Form = {
  siteName: string;
  siteTemplate: string;
  prefixIds: SitePrefix[];
};

export function Clone() {
  useDocumentTitle('Clone Netbox Template');
  const { createAlert } = useAlerts();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [prefixes, setPrefixes] = useState<Prefix[]>([]);
  const [sitePrefixes, setSitePrefixes] = useState<SitePrefix[]>([]);
  const form = useForm<Form>({
    defaultValues: {
      prefixIds: []
    }
  });
  const { handleSubmit, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prefixIds'
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        const responseTemplate = await axios.get<
          Response & {
            templates: Template[];
          }
        >(`${NETBOX_ENDPOINT}/templates`);
        const responsePrefixes = await axios.get<
          Response & {
            prefixes: Prefix[];
          }
        >(`${NETBOX_ENDPOINT}/prefixes`);

        if (responseTemplate && responseTemplate.data) {
          if (responseTemplate.data.success) {
            setTemplates(responseTemplate.data.templates);
          }
        }

        if (responsePrefixes && responsePrefixes.data) {
          if (responsePrefixes.data.success) {
            setPrefixes(responsePrefixes.data.prefixes);
          }
        }
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }

    void load();
  }, []);

  function addNewPrefixContainer() {
    setSitePrefixes([...sitePrefixes, {}]);

    append({
      prefixId: '',
      cidr: ''
    });
  }

  async function onSubmit(data: Form) {
    console.log(data);

    try {
      setLoading(true);
      const response = await axios.post(`${NETBOX_ENDPOINT}/copy-site`, data);

      if (response && response.data) {
        createAlert(
          'Cloning a site',
          `A new site is being cloned, you will receive a notification when cloning is complete.`,
          'notice'
        );

        form.reset();
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="page-wrapper">
      <div className="page-form">
        <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
          <InputGroup
            form={form}
            name="siteName"
            label="Site Name"
            placeholder="Site Name"
            validationOptions={{ required: 'Please enter a site name' }}
          />

          <InputGroup
            form={form}
            name="templateId"
            label="Template"
            type="select"
            placeholder="Select a template"
            options={templates.map((x) => ({
              label: x.name,
              value: `${x.id}`
            }))}
            validationOptions={{ required: 'Please select a template' }}
          />

          {fields.map((field, index) => (
            <PrefixBoxTemplate
              key={field.id}
              form={form}
              index={index}
              prefixes={prefixes}
              onClose={() => remove(index)}
            />
          ))}

          <div className="flex gap-1 mt-2">
            <div className="flex-1">
              <Button
                type="secondary"
                onClick={() => {
                  addNewPrefixContainer();
                }}
                full={true}
              >
                {form.getValues('prefixIds').length > 0
                  ? 'Add another prefix'
                  : 'Add prefix'}
              </Button>
            </div>

            <div>
              <Button submit disabled={loading}>
                Clone
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
