export enum AuditObjectType {
  AUTH_ACCOUNT = 'auth:account',
  AUTH_APPLICATION = 'auth:application',
  AUTH_GROUP = 'auth:group',
  AUTH_ROLE = 'auth:role',
  PROVISIONING_ATTRIBUTE = 'provisioning:attribute',
  PROVISIONING_ATTRIBUTE_VALUE = 'provisioning:attribute_value',
  PROVISIONING_DEVICE = 'provisioning:device',
  PROVISIONING_DEVICETYPE = 'provisioning:device_type',
  PROVISIONING_INTERFACE = 'provisioning:interface',
  PROVISIONING_MANUFACTURER = 'provisioning:manufacturer',
  PROVISIONING_PRODUCT = 'provisioning:product',
  PROVISIONING_SERVICE = 'provisioning:service',
  PROVISIONING_SERVICE_CHECK = 'provisioning:service_check',
  PROVISIONING_DEVICE_CHECK = 'provisioning:device_check',
  PROVISIONING_OLT = 'provisioning:olt',
  MONITORING_INCIDENT = 'monitoring:incident'
}
