import axios from 'axios';
import { useEffect, useState } from 'react';
import { AUDIT_ENDPOINT } from '../../../constants';
import { AuditAction } from '../../types/audit/AuditAction';
import { AuditLogEntry } from '../../types/audit/AuditLogEntry';
import { AuditQuery } from '../../types/audit/AuditQuery';
import { PagedResponse } from '../../types/response/PagedResponse';
import { Button } from '../Button';
import { AuditLogListFull } from './AuditLogListFull';
import { AuditLogListSmall } from './AuditLogListSmall';

export const actionDict = {
  [AuditAction.CREATE]: {
    color: 'green',
    icon: 'clarity:add-line',
    display: 'created'
  },
  [AuditAction.UPDATE]: {
    color: 'yellow',
    icon: 'clarity:pencil-line',
    display: 'updated'
  },
  [AuditAction.DELETE]: {
    color: 'red',
    icon: 'clarity:minus-line',
    display: 'deleted'
  }
};

type Props = {
  title: string;
  query: AuditQuery;
};

export function Audit({ title, query }: Props) {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [results, setResults] = useState<AuditLogEntry[]>([]);
  const [full, setFull] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function get() {
      setIsLoading(true);

      try {
        const response = await axios.post<PagedResponse<AuditLogEntry>>(
          `${AUDIT_ENDPOINT}?page=${page}&limit=${limit}`,
          {
            query
          }
        );

        setTotalResults(response.data.pagination.total);
        setResults(response.data.results);
      } catch (error) {
        console.error('Error loading audit logs', error);
      } finally {
        setIsLoading(false);
      }
    }
    void get();
  }, [query, page]);

  return (
    <>
      <div className="card">
        <strong>{title}</strong>

        <AuditLogListSmall results={results} />

        {results.length > 0 ? (
          <Button type="secondary" full onClick={() => setFull(true)}>
            View All
          </Button>
        ) : isLoading ? (
          <p>Loading history...</p>
        ) : (
          <p>No history found.</p>
        )}
      </div>

      {full && (
        <AuditLogListFull
          title={title}
          results={results}
          page={page}
          setPage={setPage}
          pageSize={limit}
          totalResults={totalResults}
          onClose={() => setFull(false)}
        />
      )}
    </>
  );
}
