import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { DeviceHealth } from '../../../pages/provisioning/shared/DeviceHealth';
import { ServiceHealth } from '../../../pages/provisioning/shared/ServiceHealth';
import { AuditActorType } from '../../types/audit/AuditActorType';
import { AuditLogEntry } from '../../types/audit/AuditLogEntry';
import { AuditObjectType } from '../../types/audit/AuditObjectType';
import { CheckResult } from '../../types/provisioning/CheckResult';
import { DeviceState } from '../../types/provisioning/Device';
import { ServiceState } from '../../types/provisioning/Service';
import { Button } from '../Button';
import { Paginator } from '../Paginator';
import { actionDict } from './Audit';
import { AuditLogDiff } from './AuditLogDiff';

type Props = {
  title: string;
  results: AuditLogEntry[];
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  totalResults: number;
  onClose: VoidFunction;
};

export function AuditLogListFull({
  title,
  results,
  page,
  setPage,
  pageSize,
  totalResults,
  onClose
}: Props) {
  const [selected, setSelected] = useState<AuditLogEntry>();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    setSelected(results[0]);

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [results]);

  return createPortal(
    <div className="audit-log-full-wrapper">
      <div className="audit-log-header-wrapper">
        <div className="audit-log-header">
          <h2>{title}</h2>
          <div>
            <Button onClick={onClose}>Close</Button>
          </div>
        </div>
      </div>
      <div className="audit-log-full">
        <div className="audit-log-content">
          <div className="audit-log-list">
            <strong>Logs</strong>
            <ul>
              {results.map((log) => (
                <li
                  key={log.id}
                  className={`${
                    selected && selected.id === log.id ? 'selected' : ''
                  }`}
                  onClick={() => setSelected(log)}
                >
                  <span
                    className={`audit-icon text-${
                      actionDict[log.actionId].color
                    }`}
                  >
                    <Icon icon={actionDict[log.actionId].icon} />
                  </span>
                  <div>
                    <div>
                      {log.actorTypeId === AuditActorType.ACCOUNT ||
                      log.actorTypeId === AuditActorType.APPLICATION ? (
                        <Link
                          to={
                            log.actorTypeId === AuditActorType.ACCOUNT
                              ? `/admin/users/${log.actorId}/view`
                              : `/admin/applications/${log.actorId}`
                          }
                        >
                          {log.actorName}
                        </Link>
                      ) : log.actorTypeId === AuditActorType.OMNI ? (
                        <span className="text-primary">
                          <strong>{log.actorName}</strong>
                        </span>
                      ) : (
                        <span className="text-primary">{log.actorName}</span>
                      )}{' '}
                      {actionDict[log.actionId].display}{' '}
                      {log.objectType.split(':')[1]}
                    </div>
                    {log?.summary ? (
                      <div>
                        <span className="text-muted">{log.summary}</span>
                      </div>
                    ) : null}
                    <div>
                      <span className="text-muted">
                        {DateTime.fromISO(log.timestamp).toFormat(
                          'dd/MM/yyyy, HH:mm:ss'
                        )}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <Paginator
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              totalResults={totalResults}
            />
          </div>
          {selected?.objectType ===
          AuditObjectType.PROVISIONING_DEVICE_CHECK ? (
            <DeviceHealth
              state={DeviceState.PROVISIONED}
              timestamp={selected.timestamp}
              checks={
                JSON.parse(
                  (
                    JSON.parse(selected.updated) as {
                      id: string;
                      seviceId: number;
                      inSync: boolean;
                      result: string;
                      createdAt: string;
                    }
                  ).result
                ) as Record<string, CheckResult>
              }
            />
          ) : selected?.objectType ===
            AuditObjectType.PROVISIONING_SERVICE_CHECK ? (
            <ServiceHealth
              state={ServiceState.PROVISIONED}
              timestamp={selected.timestamp}
              checks={
                JSON.parse(
                  (
                    JSON.parse(selected.updated) as {
                      id: string;
                      seviceId: number;
                      inSync: boolean;
                      result: string;
                      createdAt: string;
                    }
                  ).result
                ) as Record<string, CheckResult>
              }
            />
          ) : (
            <div className="audit-log-diff">
              {selected ? (
                <AuditLogDiff log={selected} />
              ) : (
                <>Please select a log</>
              )}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('root') as HTMLElement
  );
}
