export type Product = {
  id: number;
  name: string;
  type: ProductType;
  attributes?: { [key: string]: string };
};

export enum ProductType {
  DATA = 'DATA',
  VOICE = 'VOICE'
}
