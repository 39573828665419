import axios from 'axios';
import { useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ACCOUNT_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { Account } from '../../../shared/types/auth/Account';
import { UserModal } from './UserModal';

export function Users() {
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(id ? 'Editing User' : 'Users');
  const navigate = useNavigate();
  const { createAlert } = useAlerts();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [deleting, setDeleting] = useState<Account>();

  async function deleteAccount(account: Account) {
    try {
      await axios.delete(`${ACCOUNT_ENDPOINT}/${account.id}`);

      createAlert(
        'Account Deleted',
        `Account ${account.id} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          reloader={reloader}
          title="Users"
          endpoint={ACCOUNT_ENDPOINT}
          columns={['Full name', 'Email address', 'Phone number', 'Group', '']}
          generateRow={(result: Account) => [
            <>
              <Link to={`/admin/users/${result.id}/view`}>
                {result.firstName} {result.lastName}
              </Link>
            </>,
            result.email,
            result.phoneNumber,
            result.group?.name || '',
            <div key="" className="table-controls">
              <Button
                type="secondary"
                onClick={() => navigate(`/admin/users/${result.id}`)}
              >
                Edit
              </Button>
              <Button type="secondary" onClick={() => setDeleting(result)}>
                Delete
              </Button>
            </div>
          ]}
        />
      </div>

      {id && (
        <UserModal
          userId={id}
          onClose={(shouldReload: boolean) => {
            navigate('/admin/users');

            if (shouldReload) {
              reload();
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Account"
          prompt={
            <>
              Are you sure you want to delete account{' '}
              <strong>
                {deleting.firstName} {deleting.lastName}
              </strong>
              ?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteAccount(deleting);
              reload();
            }

            setDeleting(undefined);
          }}
        />
      )}
    </>
  );
}
