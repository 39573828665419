import axios from 'axios';
import { useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROLE_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { Role } from '../../../shared/types/auth/Role';
import { RoleModal } from './RoleModal';

export function Roles() {
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(id ? 'Editing Role' : 'Roles');
  const navigate = useNavigate();
  const { createAlert } = useAlerts();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState<Role>();

  async function deleteRole(role: Role) {
    try {
      await axios.delete(`${ROLE_ENDPOINT}/${role.id}`);

      createAlert(
        'Role Deleted',
        `Role ${role.id} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          reloader={reloader}
          title="Roles"
          endpoint={ROLE_ENDPOINT}
          columns={['Name', '']}
          generateRow={(result: Role) => [
            result.name,
            <div key="" className="table-controls">
              <Button
                type="secondary"
                onClick={() => navigate(`/admin/roles/${result.id}`)}
              >
                Edit
              </Button>
              <Button type="secondary" onClick={() => setDeleting(result)}>
                Delete
              </Button>
            </div>
          ]}
          newButtonText="New Role"
          onNewButtonClick={() => setAdding(true)}
        />
      </div>

      {(adding || id) && (
        <RoleModal
          roleId={id}
          onClose={(shouldReload: boolean) => {
            setAdding(false);
            navigate('/admin/roles');

            if (shouldReload) {
              reload();
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Role"
          prompt={
            <>
              Are you sure you want to delete role{' '}
              <strong>{deleting.name}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteRole(deleting);
              reload();
            }

            setDeleting(undefined);
          }}
        />
      )}
    </>
  );
}
