import { Icon } from '@iconify/react';
import { Fragment } from 'react';
import { CheckResult } from '../../../shared/types/provisioning/CheckResult';

type Props = {
  checks: Record<string, CheckResult>;
  onClick: (result: CheckResult) => void;
};

const options = {
  SUCCESS: {
    icon: 'codicon:pass',
    color: 'green'
  },
  WARNING: {
    icon: 'codicon:warning',
    color: 'yellow'
  },
  ERROR: {
    icon: 'codicon:error',
    color: 'red'
  }
};

export function CheckTable({ checks, onClick }: Props) {
  return (
    <table>
      <tbody>
        {Object.keys(checks).map((check) => (
          <tr key={check}>
            <td>{check}</td>
            <td>
              <Icon
                className={`text-${options[checks[check].result].color} ${
                  checks[check].technicalSolution ? 'hover' : ''
                }`}
                icon={options[checks[check].result].icon}
                width={24}
                onClick={() =>
                  checks[check].technicalSolution ? onClick(checks[check]) : {}
                }
              />
            </td>
            <td>
              {typeof checks[check].value === 'object'
                ? JSON.stringify(checks[check].value)
                : checks[check].value
                ? checks[check].value
                : Array.isArray(checks[check].message)
                ? (checks[check].message as string[]).map((line, index) => (
                    <Fragment key={index}>
                      {line}
                      <br />
                    </Fragment>
                  ))
                : checks[check].message}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
