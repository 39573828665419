import axios from 'axios';
import { useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { APPLICATION_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { Modal } from '../../../shared/components/Modal';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { Application } from '../../../shared/types/auth/Application';
import { ApplicationModal } from './ApplicationModal';

export function Applications() {
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(id ? 'Editing Application' : 'Applications');
  const navigate = useNavigate();
  const { createAlert } = useAlerts();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState<Application>();
  const [showSecret, setShowSecret] = useState<Application>();

  async function deleteApplication(application: Application) {
    try {
      await axios.delete(`${APPLICATION_ENDPOINT}/${application.id}`);

      createAlert(
        'Application Deleted',
        `Application ${application.id} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          reloader={reloader}
          title="Applications"
          endpoint={APPLICATION_ENDPOINT}
          columns={['Name', 'Client ID']}
          generateRow={(result: Application) => [
            result.name,
            result.clientId,
            <div key="" className="table-controls">
              <Button type="secondary" onClick={() => setShowSecret(result)}>
                View Secret
              </Button>
              <Button
                type="secondary"
                onClick={() => navigate(`/admin/applications/${result.id}`)}
              >
                Edit
              </Button>
              <Button type="secondary" onClick={() => setDeleting(result)}>
                Delete
              </Button>
            </div>
          ]}
          newButtonText="New Application"
          onNewButtonClick={() => setAdding(true)}
        />
      </div>

      {(adding || id) && (
        <ApplicationModal
          applicationId={id}
          onClose={(shouldReload: boolean) => {
            setAdding(false);
            navigate('/admin/applications');

            if (shouldReload) {
              reload();
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Application"
          prompt={
            <>
              Are you sure you want to delete application{' '}
              <strong>{deleting.name}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteApplication(deleting);
              reload();
            }

            setDeleting(undefined);
          }}
        />
      )}

      {showSecret && (
        <Modal
          title="Client Secret"
          onClose={() => setShowSecret(undefined)}
          controls={[
            {
              text: 'Copy Secret',
              type: 'secondary',
              onClick: () => {
                navigator.clipboard
                  .writeText(showSecret.clientSecret)
                  .then(() => {
                    alert('Client Secret has been copied');
                  })
                  .catch(() => {
                    alert('Could not copy the client secret');
                  });
              }
            },
            {
              text: 'Done',
              type: 'primary',
              onClick: () => setShowSecret(undefined)
            }
          ]}
        >
          <span style={{ wordWrap: 'break-word' }}>
            {showSecret.clientSecret}
          </span>
        </Modal>
      )}
    </>
  );
}
