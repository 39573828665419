export const API_URL = window.appConfig.BASE_API_URL || 'http://localhost';

// audit api
export const AUDIT_ENDPOINT = `${API_URL}/api/v1/audit`;

// auth api
export const ACCOUNT_ENDPOINT = `${API_URL}/api/v1/auth/accounts`;
export const APPLICATION_ENDPOINT = `${API_URL}/api/v1/auth/applications`;
export const GROUP_ENDPOINT = `${API_URL}/api/v1/auth/groups`;
export const PERMISSION_ENDPOINT = `${API_URL}/api/v1/auth/permissions`;
export const ROLE_ENDPOINT = `${API_URL}/api/v1/auth/roles`;
export const SESSION_ENDPOINT = `${API_URL}/api/v1/auth/sessions`;
export const SETTINGS_ENDPOINT = `${API_URL}/api/v1/auth/settings`;

// netbox api
export const NETBOX_ENDPOINT = `${API_URL}/api/v1/netbox`;

// notifications api
export const NOTIFICATIONS_ENDPOINT = `${API_URL}/api/v1/notifications`;

// provisioning api
export const ATTRIBUTE_ENDPOINT = `${API_URL}/api/v1/provisioning/attribute`;
export const DEVICE_ENDPOINT = `${API_URL}/api/v1/provisioning/device`;
export const DEVICE_TYPE_ENDPOINT = `${API_URL}/api/v1/provisioning/devicetype`;
export const INTERFACE_ENDPOINT = `${API_URL}/api/v1/provisioning/interface`;
export const MANUFACTURER_ENDPOINT = `${API_URL}/api/v1/provisioning/manufacturer`;
export const OLT_ENDPOINT = `${API_URL}/api/v1/provisioning/olt`;
export const PRODUCT_ENDPOINT = `${API_URL}/api/v1/provisioning/product`;
export const SERVICE_ENDPOINT = `${API_URL}/api/v1/provisioning/service`;
export const SWITCH_ENDPOINT = `${API_URL}/api/v1/provisioning/switch`;

// monitoring api
export const ALARM_ENDPOINT = `${API_URL}/api/v1/monitoring/alarm`;
export const INCIDENT_ENDPOINT = `${API_URL}/api/v1/monitoring/incident`;

// external api
export const NETBOX_DCIM_ENDPOINT = `https://netbox.network.netomnia.com/dcim`;
