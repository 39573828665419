import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { INCIDENT_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { Button } from '../../../../shared/components/Button';
import { InputGroup } from '../../../../shared/components/InputGroup';
import { Modal } from '../../../../shared/components/Modal';
import { alertError } from '../../../../shared/helpers';
import { Incident } from '../../../../shared/types/monitoring/Incident';
import { MonitoringIdPrefix } from '../../../../shared/types/monitoring/MonitoringIdPrefix';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';

type Props = {
  onClose: (id?: number) => void;
  incidentId: number;
};

type Form = {
  rootCauseAnalysis?: string;
};

export function IncidentModal({ onClose, incidentId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function get() {
      setLoading(true);

      try {
        const response = await axios.get<ObjectResponse<Incident>>(
          `${INCIDENT_ENDPOINT}/${incidentId}`
        );

        const incident = response.data.result;
        form.setValue(
          'rootCauseAnalysis',
          incident.rootCauseAnalysis ?? undefined
        );
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      } finally {
        setLoading(false);
      }
    }

    void get();
  }, [incidentId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request<ObjectResponse<Incident>>({
        url: `${INCIDENT_ENDPOINT}/${incidentId}`,
        method: 'PATCH',
        data
      });

      console.log(response.data);

      onClose(incidentId ? undefined : response.data.result.id);

      createAlert(
        `Incident Updated`,
        `Incident updated successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title={`Edit ${MonitoringIdPrefix.INC}-${incidentId}`}
      onClose={() => onClose()}
      size="xl"
    >
      <div className="flex gap-3">
        <div className="flex-1">
          <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
            <InputGroup
              form={form}
              name="rootCauseAnalysis"
              label="Root Cause Analysis"
            />
          </form>
        </div>
      </div>

      <div className="flex justify-end mt-2 gap-1">
        <Button type="secondary" disabled={loading} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
