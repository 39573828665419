import { Icon } from '@iconify/react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { RegisterOptions, UseFormReturn, useWatch } from 'react-hook-form';
import { useAlerts } from '../../context/AlertContext';
import { alertError } from '../helpers';
import { ObjectResponse } from '../types/response/ObjectResponse';
import { PagedResponse } from '../types/response/PagedResponse';
import { Paginator } from './Paginator';

type Props<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  name: string;
  label?: string;
  searchPrompt: string;
  reloader?: number;
  placeholder: string;
  endpoint: string;
  generateItem: (result: T) => { key: ReactNode; value: string };
  validationOptions?: RegisterOptions;
  disablePagination?: boolean;
};

export function RemoteDropdownSelect<T>({
  form,
  name,
  label,
  searchPrompt,
  reloader,
  placeholder,
  endpoint,
  generateItem,
  validationOptions,
  disablePagination
}: Props<T>) {
  const { createAlert } = useAlerts();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [results, setResults] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [rawSearchTerm, setRawSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<{ key: ReactNode; value: string }>();
  const watch = useWatch({ control: form.control, name }) as string;
  const fieldRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const updateSearch = useMemo(
    () => debounce((term: string) => setSearchTerm(term), 500),
    []
  );

  useEffect(() => {
    form.register(name, validationOptions);

    function onClick(e: MouseEvent) {
      if (
        !fieldRef.current?.contains(e.target as Node) &&
        !dropdownRef.current?.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    }

    window.addEventListener('click', onClick);

    return () => {
      form.unregister(name);
      window.removeEventListener('click', onClick);
    };
  }, []);

  useEffect(() => {
    async function run() {
      const results = await get();

      if (watch) {
        const result = results.find(
          (x) => generateItem(x).value === `${watch}`
        );

        if (result) {
          setSelected(generateItem(result));
        } else if (!disablePagination) {
          try {
            const response = await axios.get<ObjectResponse<T>>(
              `${endpoint}/${watch}`
            );

            console.log(response.data.result);

            setSelected(generateItem(response.data.result));
          } catch (err) {
            console.error(err);
            alertError(createAlert, err);
          }
        }
      } else {
        setSelected(undefined);
      }
    }

    void run();
  }, [watch, reloader, page, searchTerm]);

  async function get() {
    setLoading(true);
    try {
      const response = await axios.get<PagedResponse<T>>(
        disablePagination
          ? endpoint
          : endpoint.indexOf('?') > -1
          ? `${endpoint}&page=${page}&search=${searchTerm}`
          : `${endpoint}?page=${page}&search=${searchTerm}`
      );

      if (!disablePagination) {
        setTotalResults(response.data.pagination.total);
        setPageSize(response.data.pagination.limit);
      }

      setResults(response.data.results);
      return response.data.results;
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
    setLoading(false);
    return [];
  }

  return (
    <div className="dropdown-wrapper input-group" ref={fieldRef}>
      {label && (
        <label className="dropdown-label">
          {label}
          {validationOptions?.required && <span className="required">*</span>}
        </label>
      )}
      <div className="dropdown-trigger" onClick={() => setOpen(!open)}>
        <div className="dropdown-placeholder">
          {selected ? selected.key : placeholder}
        </div>
        <div className="dropdown-icon">
          <Icon icon="eva:chevron-down-outline" />
        </div>
      </div>

      {open &&
        fieldRef.current &&
        createPortal(
          <div
            ref={dropdownRef}
            className="dropdown"
            style={{
              top:
                fieldRef.current.getBoundingClientRect().top +
                fieldRef.current.getBoundingClientRect().height +
                window.scrollY,
              right:
                (document.getElementById('root') as HTMLElement).clientWidth -
                fieldRef.current.getBoundingClientRect().x -
                fieldRef.current.getBoundingClientRect().width +
                window.scrollX,
              width: fieldRef.current.getBoundingClientRect().width
            }}
          >
            <div className="dropdown-search">
              <input
                type="text"
                placeholder={searchPrompt}
                value={rawSearchTerm}
                onChange={(e) => {
                  setRawSearchTerm(e.target.value);
                  updateSearch(e.target.value);
                }}
              />
            </div>
            {results.length > 0 ? (
              <ul>
                {results.map((x, i) => {
                  const item = generateItem(x);
                  return (
                    <li
                      key={i}
                      className={
                        selected && selected.value === item.value
                          ? 'active'
                          : ''
                      }
                      onClick={() => {
                        setOpen(false);
                        form.clearErrors();
                        form.setValue(name, item.value);
                      }}
                    >
                      {item.key}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="ml-2">No results found.</p>
            )}

            {results.length > 0 && (
              <Paginator
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                totalResults={totalResults}
                disabled={loading}
              />
            )}
          </div>,
          document.getElementById('root') as HTMLElement
        )}

      {form && name && form.getFieldState(name).error && (
        <span className="input-group-error">
          {form.getFieldState(name).error?.message}
        </span>
      )}
    </div>
  );
}
