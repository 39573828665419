import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OLT_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { MessageBanner } from '../../../../shared/components/MessageBanner';
import { alertError } from '../../../../shared/helpers';
import useDocumentTitle from '../../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import { CheckResult } from '../../../../shared/types/provisioning/CheckResult';
import { Device } from '../../../../shared/types/provisioning/Device';
import { Olt } from '../../../../shared/types/provisioning/Olt';
import { Service } from '../../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { ObjectsResponse } from '../../../../shared/types/response/ObjectsResponse';
import { OltHealth } from '../../shared/OltHealth';
import { Controls } from './Controls';
import { Details } from './Details';
import { ServiceList } from './ServiceList';

export function OltView() {
  const { createAlert } = useAlerts();
  const { id } = useParams<{ id: string }>();
  useDocumentTitle(`${ProvisioningIdPrefix.OLT}-${id || '?'}`);
  const [olt, setOlt] = useState<Olt>();
  const [lastCheckResult, setLastCheckResult] =
    useState<Record<string, CheckResult>>();
  const [services, setServices] = useState<Service[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);

  useEffect(() => {
    async function getServices() {
      if (id) {
        try {
          const response = await axios.get<ObjectsResponse<Service>>(
            `${OLT_ENDPOINT}/${id}/services`
          );

          setServices(response.data.results);
        } catch (err) {
          console.error(err);

          alertError(createAlert, err);
        }
      }
    }

    async function getDevices() {
      if (id) {
        try {
          const response = await axios.get<ObjectsResponse<Device>>(
            `${OLT_ENDPOINT}/${id}/devices`
          );

          setDevices(response.data.results);
        } catch (err) {
          console.error(err);

          alertError(createAlert, err);
        }
      }
    }

    void getServices();
    void getDevices();
  }, [olt]);

  useEffect(() => {
    async function get() {
      if (id) {
        try {
          const response = await axios.get<ObjectResponse<Olt>>(
            `${OLT_ENDPOINT}/${id}`
          );

          setOlt(response.data.result);

          if (response.data.result.lastCheck) {
            setLastCheckResult(
              JSON.parse(response.data.result.lastCheck.result) as Record<
                string,
                CheckResult
              >
            );
          }
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }
      }
    }

    const interval = setInterval(() => {
      void get();
    }, 5000);

    void get();

    return () => {
      clearInterval(interval);
    };
  }, [id]);

  if (!olt) {
    return null;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="flex items-center">
          <div className="flex items-center gap-1 flex-1">
            <h1>OLT</h1>
            <span className="text-muted mt-1">
              ({ProvisioningIdPrefix.OLT}-{olt.id})
            </span>
          </div>
          <div>
            <Controls olt={olt} setOlt={setOlt} services={services} />
          </div>
        </div>

        {olt.errorMessage && (
          <MessageBanner
            title="An error has occurred"
            message={olt.errorMessage}
            className="mb-2"
          />
        )}

        {olt.legacy && (
          <MessageBanner
            title="Warning: Legacy OLT"
            message="This is a legacy OLT and services are not provisioned via Omni. Deprovisioning or resetting this OLT will result in complete loss of any service config already applied."
            type="warning"
            className="mb-2"
          />
        )}

        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <Details olt={olt} />
          </div>

          <div className="flex-1">
            <div className="flex gap-2 flex-col">
              <OltHealth
                state={olt.state}
                timestamp={olt.lastCheck?.createdAt}
                checks={lastCheckResult}
              />
              <ServiceList services={services} devices={devices} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
