import { Route, Routes } from 'react-router-dom';
import { Admin } from '../pages/admin/Admin';
import { Build } from '../pages/build/Build';
import { NotFoundPage } from '../pages/error/NotFoundPage';
import { HomePage } from '../pages/home/HomePage';
import { Monitoring } from '../pages/monitoring/Monitoring';
import { Notifications } from '../pages/notifications/Notifications';
import { Provisioning } from '../pages/provisioning/Provisioning';
import { SearchResults } from '../pages/search/SearchResults';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/search" element={<SearchResults />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/notifications/*" element={<Notifications />} />
      <Route path="/provisioning/*" element={<Provisioning />} />
      <Route path="/monitoring/*" element={<Monitoring />} />
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/build/*" element={<Build />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
