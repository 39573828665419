import { DateTime } from 'luxon';
import { NETBOX_DCIM_ENDPOINT } from '../../../../constants';
import { Badge } from '../../../../shared/components/Badge';
import { Incident } from '../../../../shared/types/monitoring/Incident';
import { IncidentSiteBadge } from './IncidentSiteBadge';
import { IncidentStatusBadge } from './IncidentStatusBadge';

type Props = {
  incident: Incident;
  impactedCount: number;
};

export function Details({ incident, impactedCount }: Props) {
  return (
    <div className="card flex flex-col gap-2 sidepanel">
      <div>
        <div className="mb-1">
          <strong>Type</strong>
        </div>
        <Badge>{incident.type}</Badge>
      </div>

      <div>
        <div className="mb-1">
          <strong>Site</strong>
        </div>
        <a
          href={`${NETBOX_DCIM_ENDPOINT}/sites/${incident.siteId}`}
          target="_blank"
          rel="noreferrer"
        >
          {incident.siteName ?? `Site ${incident.siteId}`}
        </a>
      </div>

      <div>
        <div className="mb-1">
          <strong>
            Impacted {incident.isLegacySite ? 'devices' : 'services'}
          </strong>
        </div>
        {impactedCount}
      </div>

      <div>
        <div className="mb-1">
          <strong>Status</strong>
        </div>
        <IncidentStatusBadge
          resolvedTimestamp={incident.resolvedTimestamp}
          confirmedTimestamp={incident.confirmedTimestamp}
        />
      </div>

      <div>
        <div className="mb-1">
          <strong>Site type</strong>
        </div>
        <IncidentSiteBadge isLegacySite={incident.isLegacySite} />
      </div>

      <div>
        <div className="mb-1">
          <strong>Root Cause Analysis</strong>
        </div>
        {incident.rootCauseAnalysis ?? (
          <span className="text-muted">Not provided</span>
        )}
      </div>

      <div>
        <div className="mb-1">
          <strong>Created</strong>
        </div>
        {DateTime.fromISO(incident.timestamp).toFormat('dd/MM/yyyy, HH:mm:ss')}
      </div>

      <div>
        <div className="mb-1">
          <strong>Last added alarm</strong>
        </div>
        {DateTime.fromISO(incident.lastAlarmAddedTimestamp).toFormat(
          'dd/MM/yyyy, HH:mm:ss'
        )}
      </div>
    </div>
  );
}
