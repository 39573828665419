import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { Modal } from '../../../shared/components/Modal';
import { ACCOUNT_ENDPOINT, GROUP_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { InputGroup } from '../../../shared/components/InputGroup';
import { RemoteDropdownSelect } from '../../../shared/components/RemoteDropdownSelect';
import { Group } from '../../../shared/types/auth/Group';
import { Account } from '../../../shared/types/auth/Account';

type Props = {
  onClose: (reload: boolean) => void;
  userId?: string;
};

type Form = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  groupId?: string;
};

export function UserModal({ onClose, userId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function get() {
      if (userId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<Account>>(
            `${ACCOUNT_ENDPOINT}/${userId}`
          );

          const account = response.data.result;
          form.setValue('firstName', account.firstName);
          form.setValue('lastName', account.lastName);
          form.setValue('phoneNumber', account.phoneNumber);
          form.setValue('groupId', account.group?.id);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    void get();
  }, [userId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request({
        url: userId ? `${ACCOUNT_ENDPOINT}/${userId}` : ACCOUNT_ENDPOINT,
        method: userId ? 'PATCH' : 'POST',
        data
      });

      console.log(response.data);

      onClose(true);

      createAlert(
        `Account ${userId ? 'Updated' : 'Added'}`,
        `Account ${userId ? 'updated' : 'added'} successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${userId ? 'Edit' : 'Add'} Account`}
      onClose={() => onClose(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
        <InputGroup
          form={form}
          name="firstName"
          label="First Name"
          placeholder="First Name"
          validationOptions={{
            required: 'Please enter a first name'
          }}
        />

        <InputGroup
          form={form}
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          validationOptions={{
            required: 'Please enter a last name'
          }}
        />

        <InputGroup
          form={form}
          name="phoneNumber"
          label="Phone Number"
          placeholder="Phone Number"
        />

        <RemoteDropdownSelect
          form={form}
          name="groupId"
          label="Application Group"
          searchPrompt="Find a group..."
          endpoint={`${GROUP_ENDPOINT}`}
          generateItem={(item: Group) => ({
            key: item.name,
            value: `${item.id}`
          })}
          placeholder="Select a group"
          validationOptions={{
            required: 'Please select a group'
          }}
        />
      </form>

      <div className="flex justify-end mt-2 gap-1">
        <Button
          type="secondary"
          disabled={loading}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
