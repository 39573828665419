import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ALARM_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { hasAccess } from '../../../../helpers';
import { Button } from '../../../../shared/components/Button';
import { CollapsibleCard } from '../../../../shared/components/CollapsibleCard';
import { alertError } from '../../../../shared/helpers';
import { AlarmWithOltName } from '../../../../shared/types/monitoring/Alarm';
import { MonitoringIdPrefix } from '../../../../shared/types/monitoring/MonitoringIdPrefix';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { AuthState } from '../../../../store/reducers/AuthReducer';
import { RootState } from '../../../../store/store';

type Props = {
  incidentId: number;
  isLegacy: boolean;
  reload: React.DispatchWithoutAction;
};

export function NearbySiteAlarmsTable({ incidentId, isLegacy, reload }: Props) {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const [alarmCheckboxes, setAlarmCheckboxes] = useState<
    {
      alarm: AlarmWithOltName;
      isChecked: boolean;
    }[]
  >([]);
  const [idsToAttach, setIdsToAttach] = useState<string[]>([]);
  const [idsToDetach, setIdsToDetach] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createAlert } = useAlerts();

  // retrieve alarms
  useEffect(() => {
    void getAlarms();
  }, [incidentId]);

  async function getAlarms() {
    if (!incidentId) return;

    try {
      setIsLoading(true);
      const response = await axios.get<ObjectResponse<AlarmWithOltName[]>>(
        `${ALARM_ENDPOINT}/incident-alarms-with-surrounding-alarms?incidentId=${incidentId}`
      );

      setAlarmCheckboxes(
        response.data.result.map((alarm) => {
          return {
            alarm,
            isChecked: alarm.incidentId === incidentId
          };
        })
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setIsLoading(false);
    }
  }

  function handleCheckbox(id: string) {
    const newAlarmCheckboxes = [...alarmCheckboxes];
    const checkboxToUpdate = newAlarmCheckboxes.find(
      (checkboxData) => checkboxData.alarm.id === id
    );

    if (!checkboxToUpdate) return;

    // Flip check state
    checkboxToUpdate.isChecked = !checkboxToUpdate.isChecked;

    if (checkboxToUpdate.isChecked) {
      if (checkboxToUpdate.alarm.incidentId) {
        // Checking an alarm in the incident - make sure it isn't in detached
        setIdsToDetach(
          idsToDetach.filter((id) => id !== checkboxToUpdate.alarm.id)
        );
      } else {
        // Checking a non-incident alarm - add to attached
        setIdsToAttach([...idsToAttach, checkboxToUpdate.alarm.id]);
      }
    } else {
      if (checkboxToUpdate.alarm.incidentId) {
        // Unchecking an alarm in the incident - add it to detached
        setIdsToDetach([...idsToDetach, checkboxToUpdate.alarm.id]);
      } else {
        // Unchecking a non-incident alarm - make sure it isn't in attached
        setIdsToAttach(
          idsToAttach.filter((id) => id !== checkboxToUpdate.alarm.id)
        );
      }
    }

    setAlarmCheckboxes(newAlarmCheckboxes);
  }

  async function handleSubmit() {
    try {
      setIsSubmitting(true);

      await axios.patch(`${ALARM_ENDPOINT}/update-alarms-attachment`, {
        incidentId,
        isLegacy,
        attached: idsToAttach,
        detached: idsToDetach
      });

      await getAlarms();

      createAlert(
        'Alarms Updated',
        `Alarms for ${MonitoringIdPrefix.INC}-${incidentId} updated successfully`,
        'success'
      );

      setIdsToAttach([]);
      setIdsToDetach([]);
      reload();
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setIsSubmitting(false);
    }
  }

  if (
    !auth.account ||
    !hasAccess(auth.account, ['monitoring:incident.alarms-table-view'])
  ) {
    return null;
  }

  const canUpdateTable = hasAccess(auth.account, [
    'monitoring:incident.alarms-table-update'
  ]);

  return (
    <CollapsibleCard title={<strong>Alarms</strong>} closed={true}>
      <table>
        <thead>
          <tr>
            <th>Included</th>
            <th>Time</th>
            <th>Serial</th>
            <th>Interface</th>
            <th>OLT Name</th>
          </tr>
        </thead>
        <tbody>
          {alarmCheckboxes.length ? (
            alarmCheckboxes.map(({ alarm, isChecked }) => {
              const alarmIsPartOfOtherIncident =
                !!alarm?.incidentId && alarm.incidentId !== incidentId;
              const isBeingAttached = idsToAttach.includes(alarm.id);
              const isBeingDetached = idsToDetach.includes(alarm.id);

              return (
                <tr
                  key={alarm.id}
                  className={`${
                    alarmIsPartOfOtherIncident
                      ? 'text-muted'
                      : isBeingAttached
                      ? 'text-green'
                      : isBeingDetached
                      ? 'text-red'
                      : !alarm?.incidentId
                      ? 'text-muted'
                      : ''
                  }`}
                >
                  <td>
                    <label
                      title={
                        alarmIsPartOfOtherIncident
                          ? `This alarm must first be detached from ${
                              alarm.incidentId
                                ? `${
                                    MonitoringIdPrefix.INC
                                  }-${alarm.incidentId.toString()}`
                                : 'the existing incident'
                            }.`
                          : `${isChecked ? 'Detach from' : 'Attach to'} ${
                              MonitoringIdPrefix.INC
                            }-${incidentId}`
                      }
                    >
                      {canUpdateTable && (
                        <input
                          type="checkbox"
                          name="incident-alarms"
                          id={alarm.id}
                          checked={isChecked}
                          onChange={() => handleCheckbox(alarm.id)}
                          disabled={alarmIsPartOfOtherIncident}
                          className="mr-1"
                        />
                      )}
                      {alarm.incidentId ? (
                        alarm.incidentId === incidentId ? (
                          <>
                            {MonitoringIdPrefix.INC}-{alarm.incidentId}
                          </>
                        ) : (
                          <Link
                            to={`/monitoring/incidents/${alarm.incidentId}`}
                            target="_blank"
                          >
                            {MonitoringIdPrefix.INC}-{alarm.incidentId}
                          </Link>
                        )
                      ) : isBeingAttached ? (
                        <>
                          {MonitoringIdPrefix.INC}-{incidentId}
                        </>
                      ) : (
                        <span className="text-muted">N/A</span>
                      )}
                    </label>
                  </td>
                  <td>
                    {DateTime.fromISO(alarm.time).toFormat(
                      'dd/MM/yyyy, HH:mm:ss'
                    )}
                  </td>
                  <td>{alarm.serial}</td>
                  <td>{alarm.interface}</td>
                  <td>
                    <Link
                      to={`/provisioning/olts/${alarm.oltId}`}
                      target="_blank"
                    >
                      {alarm.oltName}
                    </Link>
                  </td>
                </tr>
              );
            })
          ) : isLoading ? (
            <p>Loading alarms...</p>
          ) : (
            <p>No alarms found.</p>
          )}
        </tbody>
      </table>
      {canUpdateTable && (
        <div className="mt-2">
          <Button
            submit
            onClick={() => handleSubmit()}
            disabled={
              (!idsToAttach.length && !idsToDetach.length) || isSubmitting
            }
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      )}
    </CollapsibleCard>
  );
}
