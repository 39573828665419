import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { Modal } from '../../../shared/components/Modal';
import { PERMISSION_ENDPOINT, ROLE_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Role } from '../../../shared/types/auth/Role';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';

type Props = {
  onClose: (reload: boolean) => void;
  roleId?: string;
};

type Form = {
  name: string;
  permissions: string[];
};

export function RoleModal({ onClose, roleId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>({ defaultValues: { permissions: [] } });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const permissions = useWatch({
    control: form.control,
    name: 'permissions'
  });

  useEffect(() => {
    async function get() {
      if (roleId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<Role>>(
            `${ROLE_ENDPOINT}/${roleId}`
          );

          const role = response.data.result;
          form.setValue('name', role.name);
          form.setValue('permissions', role.permissions);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    void get();
  }, [roleId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request({
        url: roleId ? `${ROLE_ENDPOINT}/${roleId}` : ROLE_ENDPOINT,
        method: roleId ? 'PATCH' : 'POST',
        data
      });

      console.log(response.data);

      onClose(true);

      createAlert(
        `Role ${roleId ? 'Updated' : 'Added'}`,
        `Role ${roleId ? 'updated' : 'added'} successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${roleId ? 'Edit' : 'Add'} Role`}
      onClose={() => onClose(false)}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
        <div className="flex gap-2">
          <div className="flex-1">
            <InputGroup
              form={form}
              name="name"
              label="Role Name"
              placeholder="Role Name"
              validationOptions={{
                required: 'Please enter a role name'
              }}
            />
          </div>
          <div className="flex-2">
            <PaginatedSearchTable
              title="Permissions"
              endpoint={PERMISSION_ENDPOINT}
              columns={['Name', '']}
              generateRow={(permission: string) => [
                permission,
                <div key="" className="table-controls">
                  <Button
                    type="secondary"
                    onClick={() =>
                      form.setValue(
                        'permissions',
                        permissions.includes(permission)
                          ? permissions.filter((x) => x !== permission)
                          : [...permissions, permission]
                      )
                    }
                  >
                    {permissions.includes(permission) ? 'Selected' : 'Select'}
                  </Button>
                </div>
              ]}
            />
          </div>
        </div>
      </form>

      <div className="flex justify-end mt-2 gap-1">
        <Button
          type="secondary"
          disabled={loading}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
