export enum IncidentState {
  IDLE = 'IDLE',
  CHECKING = 'CHECKING'
}

export enum IncidentType {
  LOS = 'LOS',
  POWER_OUTAGE = 'POWER_OUTAGE',
  UNKNOWN = 'UNKNOWN'
}

export type Incident = {
  id: number;
  timestamp: string;
  confirmedTimestamp: string | null;
  resolvedTimestamp: string | null;
  lastAlarmAddedTimestamp: string;
  initialTimestampGrouping: string | null;
  siteId: number;
  siteName: string | null;
  oltIdsInSite: number[];
  linkedDeviceIds: number[];
  isLegacySite: boolean;
  state: IncidentState;
  type: IncidentType;
  rootCauseAnalysis: string | null;
};

export interface IncidentWithServiceCount extends Incident {
  serviceCount: number;
}
