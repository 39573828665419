import { CheckResult } from '../types/provisioning/CheckResult';
import { Modal } from './Modal';

type Props = {
  check: CheckResult;
  onClose: VoidFunction;
};

export function CheckSolutionModal({ check, onClose }: Props) {
  return (
    <Modal
      title={`${check.result}: ${
        Array.isArray(check.message) ? check.message.join('\n') : check.message
      }`}
      onClose={onClose}
      controls={[
        {
          text: 'Ok',
          type: 'primary',
          onClick: onClose
        }
      ]}
    >
      <strong>Possible Solutions</strong>
      <br />
      {check.technicalSolution &&
        check.technicalSolution.map((x) => <p key={x}>{x}</p>)}
    </Modal>
  );
}
