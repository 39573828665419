import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DEVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { MessageBanner } from '../../../../shared/components/MessageBanner';
import { alertError } from '../../../../shared/helpers';
import useDocumentTitle from '../../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import { CheckResult } from '../../../../shared/types/provisioning/CheckResult';
import {
  Device,
  DeviceState
} from '../../../../shared/types/provisioning/Device';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { ObjectsResponse } from '../../../../shared/types/response/ObjectsResponse';
import { DeviceHealth } from '../../shared/DeviceHealth';
import { DeviceModal } from '../DeviceModal';
import { AuditLog } from './AuditLog';
import { Controls } from './Controls';
import { Details } from './Details';
import { ServiceList } from './ServiceList';

type Props = {
  edit?: boolean;
};

export function DeviceView({ edit }: Props) {
  const { createAlert } = useAlerts();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  useDocumentTitle(`${ProvisioningIdPrefix.DEV}-${id || '?'}`);
  const [device, setDevice] = useState<Device>();
  const [lastCheckResult, setLastCheckResult] =
    useState<Record<string, CheckResult>>();
  const [services, setServices] = useState<Service[]>([]);
  const [inSync, setInSync] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmReprovision, setConfirmReprovision] = useState(false);

  useEffect(() => {
    async function get() {
      if (id) {
        try {
          const response = await axios.get<ObjectsResponse<Service>>(
            `${DEVICE_ENDPOINT}/${id}/services`
          );

          setServices(response.data.results);
        } catch (err) {
          console.error(err);

          alertError(createAlert, err);
        }
      }
    }
    void get();
  }, [device]);

  useEffect(() => {
    async function get() {
      if (id) {
        try {
          const response = await axios.get<ObjectResponse<Device>>(
            `${DEVICE_ENDPOINT}/${id}`
          );
          setDevice(response.data.result);

          if (response.data.result.lastCheck) {
            setLastCheckResult(
              JSON.parse(response.data.result.lastCheck.result) as Record<
                string,
                CheckResult
              >
            );

            setInSync(response.data.result.lastCheck.inSync);
          }
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }
      }
    }

    const interval = setInterval(() => {
      void get();
    }, 5000);

    void get();

    return () => {
      clearInterval(interval);
    };
  }, [id]);

  async function reprovision() {
    if (!device) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post<ObjectResponse<Device>>(
        `${DEVICE_ENDPOINT}/${device.id}/reprovision`,
        {}
      );

      if (response && response.data) {
        if (device) {
          setDevice({
            ...device,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  if (!device) {
    return null;
  }

  return (
    <>
      <div className="page-wrapper">
        {!inSync && device.state === DeviceState.PROVISIONED && (
          <MessageBanner
            type="warning"
            title="Out of Sync"
            message="This device is out of sync with the latest configuration, please reprovision this device to update."
            actions={[
              {
                name: 'Reprovision',
                onClick: () => setConfirmReprovision(true),
                disabled: loading
              }
            ]}
          />
        )}

        <div className="flex items-center">
          <div className="flex items-center gap-1 flex-1">
            <h1>Device</h1>
            <span className="text-muted mt-1">
              ({ProvisioningIdPrefix.DEV}-{device.id})
            </span>
          </div>
          <div>
            <Controls
              device={device}
              setDevice={setDevice}
              services={services}
            />
          </div>
        </div>

        {device.state === DeviceState['ERROR'] && device.errorMessage && (
          <MessageBanner
            title="An error has occurred"
            message={device.errorMessage}
            className="mb-2"
          />
        )}

        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <Details device={device} setDevice={setDevice} />
            <AuditLog device={device} />
          </div>

          <div className="flex-1">
            <div className="flex gap-2 flex-col">
              <ServiceList services={services} />
              <DeviceHealth
                state={device.state}
                timestamp={device.lastCheck?.createdAt}
                checks={lastCheckResult}
              />
            </div>
          </div>
        </div>
      </div>

      {confirmReprovision && (
        <ConfirmationModal
          title="Reprovision Device"
          prompt={
            <>
              Are you sure you want to reprovision this device?
              {services &&
                services.filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                  .length > 0 && (
                  <>
                    <br />
                    These services will be unavailable while reprovisioning:{' '}
                    <ul>
                      {services
                        .filter((x) => x.state !== ServiceState.NOT_PROVISIONED)
                        .map((x) => (
                          <li key={x.id}>
                            <Link to={`/provisioning/services/${x.id}`}>
                              {ProvisioningIdPrefix.SVC}-{x.id}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setConfirmReprovision(false);
              void reprovision();
            } else {
              setConfirmReprovision(false);
            }
          }}
        />
      )}

      {edit && device && (
        <DeviceModal
          onClose={() => navigate(`/provisioning/devices/${device.id}`)}
          deviceId={device.id}
        />
      )}
    </>
  );
}
