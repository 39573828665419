import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';

type Props = {
  totalResults: number;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
  disabled?: boolean;
};

export function Paginator({
  totalResults,
  pageSize,
  page,
  setPage,
  disabled
}: Props) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(
      totalResults % pageSize === 0
        ? totalResults / pageSize
        : Math.floor(totalResults / pageSize) + 1
    );
  }, [totalResults, pageSize]);

  function renderButtons() {
    const qty = 5;
    const half = Math.floor(qty / 2);
    const pages = [];
    let first = page - half;
    let last = page + half;

    if (page <= half) {
      first = 1;
      last = totalPages >= qty ? qty : totalPages;
    } else if (page >= totalPages - half) {
      last = totalPages;
      first = totalPages - qty + 1;
    }

    if (first < 1) first = 1;
    if (last > totalPages) last = totalPages;

    for (let i = first; i <= last; i++) {
      pages.push(i);
    }

    return pages.map((i) => (
      <div
        key={i}
        className={`paginator-button ${page === i ? 'active' : ''}`}
        onClick={() => (!disabled ? setPage(i) : {})}
      >
        {i}
      </div>
    ));
  }

  return (
    <div className="paginator">
      <div
        className={`paginator-button ${
          disabled || page === 1 ? 'disabled' : ''
        }`}
        onClick={() =>
          page !== 1 && !disabled
            ? page > 1
              ? setPage(page - 1)
              : setPage(1)
            : {}
        }
      >
        <Icon className="icon" icon="ic:baseline-keyboard-arrow-left" />
      </div>

      {renderButtons()}

      <div
        className={`paginator-button ${
          disabled || page >= totalPages ? 'disabled' : ''
        }`}
        onClick={() =>
          page < totalPages && !disabled
            ? page < totalPages
              ? setPage(page + 1)
              : setPage(totalPages)
            : {}
        }
      >
        <Icon className="icon" icon="ic:baseline-keyboard-arrow-right" />
      </div>
    </div>
  );
}
