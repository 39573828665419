import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { INCIDENT_ENDPOINT, NETBOX_DCIM_ENDPOINT } from '../../../constants';
import { Badge } from '../../../shared/components/Badge';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import {
  IncidentType,
  IncidentWithServiceCount
} from '../../../shared/types/monitoring/Incident';
import { MonitoringIdPrefix } from '../../../shared/types/monitoring/MonitoringIdPrefix';
import { IncidentSiteBadge } from './view/IncidentSiteBadge';
import { IncidentStatusBadge } from './view/IncidentStatusBadge';

const filters = [
  {
    label: 'Site Type',
    key: 'isLegacySite',
    options: [
      {
        label: 'Omni',
        value: 'false'
      },
      {
        label: 'Legacy',
        value: 'true'
      }
    ],
    parseBool: true
  },
  {
    label: 'Detected Cause',
    key: 'type',
    options: [
      {
        label: IncidentType.LOS,
        value: IncidentType.LOS,
        isDefaultSelected: true
      },
      {
        label: IncidentType.POWER_OUTAGE,
        value: IncidentType.POWER_OUTAGE
      }
    ]
  }
];

export function Incidents() {
  useDocumentTitle('Incidents');

  return (
    <div className="page-wrapper">
      <PaginatedSearchTable
        title="Incidents"
        searchPrompt="Find an incident..."
        endpoint={INCIDENT_ENDPOINT}
        columns={[
          'ID',
          'Site',
          'Impacted Services',
          'Detected Alarm Time',
          'Detected Cause',
          'Status',
          'Site Type'
        ]}
        generateRow={(incident: IncidentWithServiceCount) => [
          // ID
          <>
            <Link to={`/monitoring/incidents/${incident.id}`}>
              {MonitoringIdPrefix.INC}-{incident.id}
            </Link>
          </>,
          // Site
          <>
            <a
              href={`${NETBOX_DCIM_ENDPOINT}/sites/${incident.siteId}`}
              target="_blank"
              rel="noreferrer"
            >
              {incident.siteName ?? `ID ${incident.siteId}`}
            </a>
          </>,
          // Impacted Services
          <>{incident.serviceCount}</>,
          // Detected Alarm Time
          <>
            {incident.initialTimestampGrouping
              ? DateTime.fromISO(incident.initialTimestampGrouping).toFormat(
                  'dd/MM/yyyy, HH:mm:ss'
                )
              : 'Not found'}
          </>,
          // Detected Cause
          <>
            <Badge>{incident.type}</Badge>
          </>,
          // Status
          <>
            <IncidentStatusBadge
              resolvedTimestamp={incident.resolvedTimestamp}
              confirmedTimestamp={incident.confirmedTimestamp}
            />
          </>,
          // Site Type
          <>
            <IncidentSiteBadge isLegacySite={incident.isLegacySite} />
          </>
        ]}
        filters={filters}
      />
    </div>
  );
}
