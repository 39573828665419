import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { Modal } from '../../../shared/components/Modal';
import { GROUP_ENDPOINT, ROLE_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError } from '../../../shared/helpers';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Role } from '../../../shared/types/auth/Role';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { Group } from '../../../shared/types/auth/Group';

type Props = {
  onClose: (reload: boolean) => void;
  groupId?: string;
};

type Form = {
  name: string;
  roles: Role[];
};

export function GroupModal({ onClose, groupId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>({ defaultValues: { roles: [] } });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const roles = useWatch({
    control: form.control,
    name: 'roles'
  });

  useEffect(() => {
    async function get() {
      if (groupId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<Group>>(
            `${GROUP_ENDPOINT}/${groupId}`
          );

          const group = response.data.result;
          form.setValue('name', group.name);
          form.setValue('roles', group.roles);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    void get();
  }, [groupId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    try {
      const response = await axios.request({
        url: groupId ? `${GROUP_ENDPOINT}/${groupId}` : GROUP_ENDPOINT,
        method: groupId ? 'PATCH' : 'POST',
        data: {
          name: data.name,
          roles: data.roles.map((x) => x.id)
        }
      });

      console.log(response.data);

      onClose(true);

      createAlert(
        `Group ${groupId ? 'Updated' : 'Added'}`,
        `Group ${groupId ? 'updated' : 'added'} successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <Modal
      title={`${groupId ? 'Edit' : 'Add'} Group`}
      onClose={() => onClose(false)}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
        <div className="flex gap-2">
          <div className="flex-1">
            <InputGroup
              form={form}
              name="name"
              label="Role Name"
              placeholder="Role Name"
              validationOptions={{
                required: 'Please enter a role name'
              }}
            />
          </div>
          <div className="flex-2">
            <PaginatedSearchTable
              title="Permissions"
              endpoint={ROLE_ENDPOINT}
              columns={['Name', '']}
              generateRow={(role: Role) => [
                role.name,
                <div key="" className="table-controls">
                  <Button
                    type="secondary"
                    onClick={() =>
                      form.setValue(
                        'roles',
                        roles.find((x) => x.id === role.id)
                          ? roles.filter((x) => x.id !== role.id)
                          : [...roles, role]
                      )
                    }
                  >
                    {roles.find((x) => x.id === role.id)
                      ? 'Selected'
                      : 'Select'}
                  </Button>
                </div>
              ]}
            />
          </div>
        </div>
      </form>

      <div className="flex justify-end mt-2 gap-1">
        <Button
          type="secondary"
          disabled={loading}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
