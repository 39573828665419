import axios from 'axios';
import { useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GROUP_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { Group } from '../../../shared/types/auth/Group';
import { GroupModal } from './GroupModal';

export function Groups() {
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(id ? 'Editing Group' : 'Groups');
  const navigate = useNavigate();
  const { createAlert } = useAlerts();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState<Group>();

  async function deleteGroup(group: Group) {
    try {
      await axios.delete(`${GROUP_ENDPOINT}/${group.id}`);

      createAlert(
        'Group Deleted',
        `Group ${group.id} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          reloader={reloader}
          title="Groups"
          endpoint={GROUP_ENDPOINT}
          columns={['Name', '']}
          generateRow={(result: Group) => [
            result.name,
            <div key="" className="table-controls">
              <Button
                type="secondary"
                onClick={() => navigate(`/admin/groups/${result.id}`)}
              >
                Edit
              </Button>
              <Button type="secondary" onClick={() => setDeleting(result)}>
                Delete
              </Button>
            </div>
          ]}
          newButtonText="New Group"
          onNewButtonClick={() => setAdding(true)}
        />
      </div>

      {(adding || id) && (
        <GroupModal
          groupId={id}
          onClose={(shouldReload: boolean) => {
            setAdding(false);
            navigate('/admin/groups');

            if (shouldReload) {
              reload();
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Group"
          prompt={
            <>
              Are you sure you want to delete group{' '}
              <strong>{deleting.name}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteGroup(deleting);
              reload();
            }

            setDeleting(undefined);
          }}
        />
      )}
    </>
  );
}
