import axios, { AxiosError, AxiosResponse } from 'axios';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { CustomAxiosRequestConfig } from '../shared/types/CustomAxiosRequestConfig';
import { asyncRefresh, logout } from '../store/actions/AuthActions';
import { AuthState } from '../store/reducers/AuthReducer';
import { RootState } from '../store/store';
import { useAlerts } from './AlertContext';

type HeaderProps = {
  Authorization: string;
};

export function AxiosProvider({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const navigate = useNavigate();

  const { createAlert } = useAlerts();

  const dispatch = useDispatch();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    /**
     * Handles requests and adding authorization and content headers
     */
    const requestInterceptor = axios.interceptors.request.use((config) => {
      if (!auth) {
        return config;
      }

      if (!config.headers) {
        config.headers = {};
      }

      config.headers['Content-Type'] = 'application/json';
      config.headers['Accept'] = 'application/json';

      if (auth.token) {
        config.headers['Authorization'] = `Bearer ${auth.token}`;
      }

      if (auth.sessionId) {
        config.headers['SessionId'] = auth.sessionId;
      }

      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const status = error.response?.status || 500;
        switch (status) {
          case 401: {
            // Stop if more than 3 attempts have been made to the api
            const config = error.config as CustomAxiosRequestConfig;
            if (config.__retryCount && config.__retryCount >= 3) {
              dispatch(logout() as unknown as AnyAction);
              return;
            }

            if (config.__retryCount) {
              config.__retryCount++;
            } else {
              config.__retryCount = 1;
            }

            // Unauthorized
            const newAuth = await asyncRefresh(auth, dispatch);

            if (newAuth && newAuth.token) {
              (
                error.config.headers as HeaderProps
              ).Authorization = `Bearer ${newAuth.token}`;

              return await axios.request(error.config);
            }
            throw error;
          }
          case 403: {
            createAlert(
              `Access Denied`,
              'You do not have permission to access this',
              'attention'
            );
            navigate(-1);
          }
        }

        throw error;
      }
    );

    setLoading(false);

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [auth]);

  return loading ? (
    <div className="mx-2 my-2">Loading...</div>
  ) : (
    <>{children}</>
  );
}
