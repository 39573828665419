import { useSelector } from 'react-redux';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { useEffect, useState } from 'react';
import { alertError } from '../../shared/helpers';
import { useAlerts } from '../../context/AlertContext';
import axios from 'axios';
import { API_URL } from '../../constants';
import { DateTime } from 'luxon';

type Stats = {
  olts: number;
  devices: number;
  services: number;
  history: {
    deviceSuccess: number;
    deviceFail: number;
    serviceSuccess: number;
    serviceFail: number;
  }[];
};

export function HomePage() {
  const { createAlert } = useAlerts();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const [stats, setStats] = useState<Stats>();

  useEffect(() => {
    async function get() {
      try {
        const response = await axios.get<Stats>(
          `${API_URL}/api/v1/provisioning/stat`
        );
        setStats(response.data);
      } catch (err) {
        alertError(createAlert, err);
      }
    }

    void get();
  }, []);

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = Array.from(Array(7).keys())
    .reverse()
    .map((x) =>
      DateTime.now()
        .minus({
          days: x
        })
        .toFormat('EEE dd')
    );

  return (
    <>
      <div className="page-wrapper">
        <h1>Welcome back, {auth.account?.firstName}!</h1>

        <div className="flex gap-2">
          <div className="card flex-1 text-center">
            <strong>OLTs Registered</strong>
            <br />
            <div style={{ fontSize: 20 }} className="mt-1">
              {stats?.olts}
            </div>
          </div>
          <div className="card flex-1 text-center">
            <strong>Devices Provisioned</strong>
            <br />
            <div style={{ fontSize: 20 }} className="mt-1">
              {stats?.devices}
            </div>
          </div>
          <div className="card flex-1 text-center">
            <strong>Services Provisioned</strong>
            <br />
            <div style={{ fontSize: 20 }} className="mt-1">
              {stats?.services}
            </div>
          </div>
        </div>

        <div className="mt-3 flex gap-2">
          <div className="flex-1">
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top' as const
                  },
                  title: {
                    display: true,
                    text: 'Device Provisions'
                  }
                },
                scales: {
                  y: {
                    ticks: {
                      precision: 0
                    }
                  }
                }
              }}
              data={{
                labels,
                datasets: [
                  {
                    label: 'Success',
                    data: stats?.history.map((x) => x.deviceSuccess),
                    borderColor: 'rgb(61, 209, 111)',
                    backgroundColor: 'rgba(61, 209, 111, 0.5)'
                  },
                  {
                    label: 'Fail',
                    data: stats?.history.map((x) => x.deviceFail),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                  }
                ]
              }}
            />
          </div>

          <div className="flex-1">
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top' as const
                  },
                  title: {
                    display: true,
                    text: 'Service Provisions'
                  }
                },
                scales: {
                  y: {
                    ticks: {
                      precision: 0
                    }
                  }
                }
              }}
              data={{
                labels,
                datasets: [
                  {
                    label: 'Success',
                    data: stats?.history.map((x) => x.serviceSuccess),
                    borderColor: 'rgb(61, 209, 111)',
                    backgroundColor: 'rgba(61, 209, 111, 0.5)'
                  },
                  {
                    label: 'Fail',
                    data: stats?.history.map((x) => x.serviceFail),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                  }
                ]
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
