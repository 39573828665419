import { Button, ButtonType } from './Button';
import ReactDOM from 'react-dom';

type Props = {
  title: string;
  size?: 'sm' | 'lg' | 'xl';
  footerAlignment?: 'center' | 'right';
  children: React.ReactNode;
  controls?: {
    type: ButtonType;
    text: string;
    onClick: (() => Promise<void>) | (() => void);
  }[];
  onClose: () => void;
  zIndex?: number;
};

export function Modal({
  title,
  size,
  footerAlignment,
  children,
  controls,
  zIndex
}: Props) {
  return ReactDOM.createPortal(
    <div className="modal-wrapper" style={{ zIndex: zIndex || 1000 }}>
      <div className={`modal modal-${size || 'sm'}`}>
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-body">{children}</div>
        {controls && (
          <div className={`modal-footer ${footerAlignment || 'right'}`}>
            {controls.map((control, i) => (
              <Button key={i} type={control.type} onClick={control.onClick}>
                {control.text}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>,
    document.getElementById('root') as Element
  );
}
