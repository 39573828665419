import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEVICE_ENDPOINT } from '../../../constants';
import { Badge } from '../../../shared/components/Badge';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { CheckResult } from '../../../shared/types/provisioning/CheckResult';
import { Device, DeviceState } from '../../../shared/types/provisioning/Device';
import { DeviceModal } from './DeviceModal';

export function Devices() {
  useDocumentTitle('Devices');
  const navigate = useNavigate();
  const [adding, setAdding] = useState(false);

  function hasCheckResult(device: Device, result: string) {
    if (device.lastCheck) {
      const checks = JSON.parse(device.lastCheck?.result) as Record<
        string,
        CheckResult
      >;

      if (checks) {
        return !!Object.keys(checks).find((x) => checks[x].result === result);
      }
    }

    return false;
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          title="Devices"
          searchPrompt="Find a device..."
          endpoint={DEVICE_ENDPOINT}
          columns={[
            'ID',
            'Device Type',
            'Serial',
            'Status',
            'Service Status',
            'Health',
            ''
          ]}
          generateRow={(result: Device) => [
            <>
              <Link to={`/provisioning/devices/${result.id}`}>
                {ProvisioningIdPrefix.DEV}-{result.id}
              </Link>{' '}
              {result.test && <Badge>TEST</Badge>}
            </>,
            result.deviceTypeId ? (
              <>
                <Link to={`/provisioning/device-types/${result.deviceTypeId}`}>
                  {ProvisioningIdPrefix.DVT}-{result.deviceTypeId}
                </Link>{' '}
                <span className="text-muted">({result.deviceType?.name})</span>
              </>
            ) : (
              <Badge>UNKNOWN</Badge>
            ),
            result.serial,
            <>
              <Badge
                type={
                  result.state === DeviceState.IN_DISCOVERY
                    ? undefined
                    : result.state === DeviceState.ERROR
                    ? 'red'
                    : result.state === DeviceState.PROVISIONED
                    ? 'green'
                    : 'blue'
                }
              >
                {result.state === DeviceState.IN_DISCOVERY
                  ? 'DISABLED'
                  : result.state === DeviceState.DEPROVISIONING
                  ? 'DISABLING'
                  : result.state}
              </Badge>
            </>,
            <>
              <Badge type={result.serviceActive ? 'green' : undefined}>
                {result.serviceActive ? 'ACTIVE' : 'INACTIVE'}
              </Badge>
            </>,
            <>
              {result.lastCheck && result.lastCheck.result && (
                <>
                  {hasCheckResult(result, 'ERROR') ? (
                    <Badge type="red">ERROR</Badge>
                  ) : hasCheckResult(result, 'WARNING') ? (
                    <Badge type="yellow">WARNING</Badge>
                  ) : (
                    <Badge type="green">HEALTHY</Badge>
                  )}
                </>
              )}
            </>
          ]}
        />
      </div>

      {adding && (
        <DeviceModal
          onClose={(newId?: number) => {
            setAdding(false);

            if (newId) {
              navigate(`/provisioning/devices/${newId}`);
            } else {
              navigate('/provisioning/devices');
            }
          }}
        />
      )}
    </>
  );
}
