import { AuthState } from './reducers/AuthReducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import {
  createStateSyncMiddleware,
  initMessageListener
} from 'redux-state-sync';

import {
  persistStore,
  persistReducer,
  PERSIST,
  PURGE,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

import { AuthReducer } from './reducers/AuthReducer';

const persistConfig = {
  key: 'root',
  storage
};

export type StoreState = {
  auth: AuthState;
};

const persistedReducers = persistReducer(
  persistConfig,
  combineReducers<StoreState>({
    auth: AuthReducer
  })
);

// Create a new store with reducers enabled
export const store = createStore(
  persistedReducers,
  {},
  composeWithDevTools(
    applyMiddleware(
      thunk,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
      createStateSyncMiddleware({
        blacklist: [PERSIST, PURGE, REHYDRATE]
      })
    )
  )
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
