import { Icon } from '@iconify/react';
import axios from 'axios';
import { useState } from 'react';
import { SWITCH_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { Button } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { alertError } from '../../../../shared/helpers';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import {
  Switch,
  SwitchState
} from '../../../../shared/types/provisioning/Switch';

type Props = {
  swich: Switch;
  setSwitch: (swich: Switch) => void;
};

export function Controls({ swich, setSwitch }: Props) {
  const { createAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showForceProvision, setShowForceProvision] = useState(false);
  const [showDeprovision, setShowDeprovision] = useState(false);

  const disableControls =
    loading ||
    swich.state === SwitchState.CHECKING ||
    swich.state === SwitchState.REBOOTING ||
    swich.state === SwitchState.UPGRADING ||
    swich.state === SwitchState.PROVISIONING ||
    swich.state === SwitchState.DEPROVISIONING;

  async function createJob(
    type: 'check' | 'update' | 'provision' | 'deprovision'
  ) {
    try {
      setLoading(true);
      const response = await axios.post<ObjectResponse<Switch>>(
        `${SWITCH_ENDPOINT}/${swich.id}/${type}`
      );

      if (response && response.data) {
        if (swich) {
          setSwitch({
            ...swich,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="flex gap-1">
        {swich.state !== SwitchState.DEPROVISIONED &&
          swich.state !== SwitchState.PROVISIONING && (
            <>
              <Button
                type="secondary"
                onClick={() => setShowDeprovision(true)}
                disabled={disableControls}
              >
                {swich.state === SwitchState.DEPROVISIONING ? (
                  <>
                    Deprovisioning{' '}
                    <Icon className="loader" icon="codicon:loading" />
                  </>
                ) : (
                  <>Deprovision</>
                )}
              </Button>

              <Button
                type="secondary"
                onClick={() => setShowUpdateModal(true)}
                disabled={disableControls}
              >
                {swich.state === SwitchState.UPGRADING ? (
                  <>
                    {swich.updateState}{' '}
                    <Icon className="loader" icon="codicon:loading" />
                  </>
                ) : (
                  <>Upgrade</>
                )}
              </Button>

              <Button
                type="primary"
                onClick={() => void createJob('check')}
                disabled={disableControls}
              >
                {swich.state === SwitchState.CHECKING ? (
                  <>
                    Checking <Icon className="loader" icon="codicon:loading" />
                  </>
                ) : (
                  <>Check</>
                )}
              </Button>
            </>
          )}

        {(swich.state === SwitchState.DEPROVISIONED ||
          swich.state === SwitchState.PROVISIONING ||
          swich.state === SwitchState.ERROR) && (
          <>
            <Button
              onClick={() => setShowForceProvision(true)}
              disabled={disableControls}
            >
              {swich.state === SwitchState.PROVISIONING ? (
                <>
                  Provisioning{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Provision</>
              )}
            </Button>
          </>
        )}
      </div>

      {showUpdateModal && (
        <ConfirmationModal
          title="Upgrade Device"
          prompt={
            <>
              Are you sure you want to upgrade this Switch? This may take
              several minutes.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowUpdateModal(false);
              void createJob('update');
            } else {
              setShowUpdateModal(false);
            }
          }}
        />
      )}

      {showForceProvision && (
        <ConfirmationModal
          title="Manual Provision"
          prompt={
            <>
              Are you sure you want to force a manual provision attempt on this
              Switch? Provisions are usually performed automatically by Omni,
              only use this option if you know what you are doing!
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowForceProvision(false);
              void createJob('provision');
            } else {
              setShowForceProvision(false);
            }
          }}
        />
      )}

      {showDeprovision && (
        <ConfirmationModal
          title="Deprovision"
          prompt={
            <>
              Are you sure you want to deprovision switch? This will reset the
              switch to factory settings and deprovision all ONUs and services!.
              If the switch is still reachable after this operation is complete,
              Omni will run ZTP on it again.
              <br />
              <br />
              <strong>Warning:</strong> This is for decommissioning an switch
              and all devices and services attached to it.
              <br />
              If the switch is being replaced, click &quot;Replace switch&quot;
              instead.
              <br />
              If the switch needs ZTP to be ran again, click &quot;Reset switch
              State&quot; instead.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowDeprovision(false);
              void createJob('deprovision');
            } else {
              setShowDeprovision(false);
            }
          }}
        />
      )}
    </>
  );
}
