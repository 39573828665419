// All relevant types for store
enum StoreTypes {
  // Auth
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',

  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

  REFRESH_SUCCESS = 'REFRESH_SUCCESS',
  REFRESH_FAILED = 'REFRESH_FAILED',

  GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS',
  GET_GROUP_FAILED = 'GET_GROUP_FAILED',

  ERRORS = 'ERRORS'
}

export default StoreTypes;
