import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import Showdown from 'showdown';
import { useAlerts } from '../../context/AlertContext';
import { alertError } from '../../shared/helpers';
import { Notification } from '../../shared/types/Notification';
import { PagedResponse } from '../../shared/types/response/PagedResponse';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';

// Base api url
const baseUrl = window.appConfig.BASE_API_URL || 'http://localhost';

// Must have id parsed to the end for the account
const NOTIFICATIONS_ENDPOINT = `${baseUrl}/api/v1/notifications`;

export function Notifications() {
  const { id } = useParams<{ id: string }>();
  const { createAlert } = useAlerts();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [selected, setSelected] = useState(id);

  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  useEffect(() => {
    async function load() {
      if (!auth || !auth.token || !auth.account) {
        return;
      }

      try {
        const response = await axios.get<PagedResponse<Notification>>(
          `${NOTIFICATIONS_ENDPOINT}/notification/account/${auth.account.id}`
        );

        setNotifications(response.data.results);
        setLoading(false);

        if (!id && notifications.length > 0) {
          navigate(`/notifications/${notifications[0].id}`);
          setSelected(notifications[0].id);
        }
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }

    void load();
  }, [history, id]);

  const RenderView = () => {
    const notification = notifications.find((x) => x.id === selected);

    if (!notification) {
      setSelected(notifications[0].id);
      return null;
    }

    return (
      <div className="notification-view-content">
        <div className="notification-view-header">
          <h1 className="notification-view-title">{notification.title}</h1>
          <p className="notification-view-timestamp" style={{ float: 'right' }}>
            {DateTime.fromISO(notification.createdAt).toFormat(
              'dd/MM/yyyy HH:mm'
            )}
          </p>
        </div>
        <div
          className="notification-view-markdown"
          dangerouslySetInnerHTML={{
            __html: new Showdown.Converter().makeHtml(notification.body)
          }}
        ></div>
      </div>
    );
  };

  function select(id: string) {
    setSelected(id);
    navigate(`/notifications/${id}`);
  }

  return (
    <div className="page-wrapper">
      {loading ? (
        <p>Loading...</p>
      ) : notifications.length === 0 ? (
        <p>You dont have any notifications</p>
      ) : (
        <div className="notification-page">
          <div className="notification-list">
            {notifications.map((notification) => (
              <div
                key={notification.id}
                className={`notification-list-item ${
                  selected === notification.id ? 'active' : ''
                }`}
                onClick={() => select(notification.id)}
              >
                <div className="notification-list-item-header">
                  <span
                    className={`notification-list-item-title-${notification.state}`}
                  >
                    {notification.title}
                  </span>
                  <span className="notification-list-item-timestamp">
                    {DateTime.fromISO(notification.createdAt).toFormat(
                      'dd/MM/yyyy HH:mm'
                    )}
                  </span>
                </div>
                <div className="notification-list-item-body">
                  {notification.summary}
                </div>
              </div>
            ))}
          </div>
          <div className="notification-view">{selected && <RenderView />}</div>
        </div>
      )}
    </div>
  );
}
