import axios from 'axios';
import { useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DEVICE_TYPE_ENDPOINT } from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { Button } from '../../../shared/components/Button';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import { alertError } from '../../../shared/helpers';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { DeviceType } from '../../../shared/types/provisioning/DeviceType';
import { DeviceTypeModal } from './DeviceTypeModal';

export function DeviceTypes() {
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(id ? `${ProvisioningIdPrefix.DVT}-${id}` : 'Device Types');
  const navigate = useNavigate();
  const { createAlert } = useAlerts();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState<DeviceType>();

  async function deleteDeviceType(deviceType: DeviceType) {
    try {
      await axios.delete(`${DEVICE_TYPE_ENDPOINT}/${deviceType.id}`);

      createAlert(
        'Device Type Deleted',
        `Device type ${deviceType.name} deleted successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          reloader={reloader}
          title="Device Types"
          searchPrompt="Find a device type..."
          endpoint={DEVICE_TYPE_ENDPOINT}
          columns={['ID', 'Name', 'Manufacturer', 'Software Version', '']}
          generateRow={(result: DeviceType) => [
            `${ProvisioningIdPrefix.DVT}-${result.id}`,
            result.name,
            <>
              <Link to={`/provisioning/manufacturers/${result.manufacturerId}`}>
                {ProvisioningIdPrefix.MAN}-{result.id}
              </Link>{' '}
              <span className="text-muted">({result.manufacturer?.name})</span>
            </>,
            result.softwareVersion,
            <div key="" className="table-controls">
              <Button
                type="secondary"
                onClick={() =>
                  navigate(`/provisioning/device-types/${result.id}`)
                }
              >
                Edit
              </Button>
              <Button type="secondary" onClick={() => setDeleting(result)}>
                Delete
              </Button>
            </div>
          ]}
          newButtonText="New Device Type"
          onNewButtonClick={() => setAdding(true)}
        />
      </div>

      {(adding || id) && (
        <DeviceTypeModal
          deviceTypeId={id}
          onClose={(shouldReload: boolean) => {
            setAdding(false);
            navigate('/provisioning/device-types');

            if (shouldReload) {
              reload();
            }
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Device Type"
          prompt={
            <>
              Are you sure you want to delete device type{' '}
              <strong>{deleting.name}</strong>?
            </>
          }
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deleteDeviceType(deleting);
              reload();
            }

            setDeleting(undefined);
          }}
        />
      )}
    </>
  );
}
