import { Route, Routes } from 'react-router-dom';
import { AwaitingApproval } from '../pages/auth/AwaitingApproval';
import { SignInPage } from '../pages/auth/SignInPage';
import { SignOutPage } from '../pages/auth/SignOutPage';
import { NotFoundPage } from '../pages/error/NotFoundPage';

export function AuthRoutes() {
  return (
    <Routes>
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signout" element={<SignOutPage />} />
      <Route path="/approval" element={<AwaitingApproval />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
