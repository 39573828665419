import axios from 'axios';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { OLT_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { Button } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { Modal } from '../../../../shared/components/Modal';
import { alertError } from '../../../../shared/helpers';
import { Olt, OltState } from '../../../../shared/types/provisioning/Olt';
import { Service } from '../../../../shared/types/provisioning/Service';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';

type Props = {
  olt: Olt;
  setOlt: (olt: Olt) => void;
  services: Service[];
};

export function Controls({ olt, setOlt, services }: Props) {
  const { createAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showForceProvision, setShowForceProvision] = useState(false);
  const [showDeprovision, setShowDeprovision] = useState(false);
  const [showReplace, setShowReplace] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [netboxId, setNetboxId] = useState(olt.netboxId);
  const [showRebootModal, setShowRebootModal] = useState(false);

  const disableControls =
    loading ||
    olt.state === OltState.CHECKING ||
    olt.state === OltState.REBOOTING ||
    olt.state === OltState.UPGRADING ||
    olt.state === OltState.PROVISIONING ||
    olt.state === OltState.DEPROVISIONING;

  async function createJob(
    type: 'check' | 'update' | 'provision' | 'deprovision' | 'reboot'
  ) {
    try {
      setLoading(true);
      const response = await axios.post<ObjectResponse<Olt>>(
        `${OLT_ENDPOINT}/${olt.id}/${type}`
      );

      if (response && response.data) {
        if (olt) {
          setOlt({
            ...olt,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function resetOlt() {
    try {
      setLoading(true);
      const response = await axios.patch<ObjectResponse<Olt>>(
        `${OLT_ENDPOINT}/${olt.id}`,
        {
          errorMessage: null,
          state: OltState.DEPROVISIONED
        }
      );

      if (response && response.data) {
        if (olt) {
          setOlt({
            ...olt,
            state: response.data.result.state
          });

          await createJob('provision');
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function replaceOlt() {
    try {
      setLoading(true);
      const response = await axios.patch<ObjectResponse<Olt>>(
        `${OLT_ENDPOINT}/${olt.id}`,
        {
          errorMessage: null,
          state: OltState.DEPROVISIONED,
          netboxId
        }
      );

      if (response && response.data) {
        if (olt) {
          setOlt({
            ...olt,
            state: response.data.result.state
          });
        }
      }
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="flex gap-1">
        {olt.state !== OltState.DEPROVISIONED &&
        olt.state !== OltState.PROVISIONING ? (
          <>
            <Button
              type="secondary"
              onClick={() => setShowDeprovision(true)}
              disabled={disableControls}
            >
              {olt.state === OltState.DEPROVISIONING ? (
                <>
                  Deprovisioning{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Deprovision</>
              )}
            </Button>

            <Button
              type="secondary"
              onClick={() => setShowReset(true)}
              disabled={disableControls}
            >
              {olt.fullConfigProvisioning ? 'Sync Config' : 'Reset OLT State'}
            </Button>

            <Button
              type="secondary"
              onClick={() => setShowReplace(true)}
              disabled={disableControls}
            >
              Replace OLT
            </Button>

            <Button
              type="secondary"
              onClick={() => setShowUpdateModal(true)}
              disabled={disableControls}
            >
              {olt.state === OltState.UPGRADING ? (
                <>
                  {olt.updateState}{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Upgrade</>
              )}
            </Button>

            <Button
              type="secondary"
              onClick={() => setShowRebootModal(true)}
              disabled={disableControls}
            >
              Reboot
            </Button>

            <Button
              type="primary"
              onClick={() => void createJob('check')}
              disabled={disableControls}
            >
              {olt.state === OltState.CHECKING ? (
                <>
                  Checking <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Check</>
              )}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => setShowForceProvision(true)}
              disabled={disableControls}
            >
              {olt.state === OltState.PROVISIONING ? (
                <>
                  Provisioning{' '}
                  <Icon className="loader" icon="codicon:loading" />
                </>
              ) : (
                <>Provision</>
              )}
            </Button>
          </>
        )}
      </div>

      {showUpdateModal && (
        <ConfirmationModal
          title="Upgrade Device"
          prompt={
            <>
              Are you sure you want to upgrade this OLT? This may take several
              minutes.
              {services.length} services will be unavailable during this time.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowUpdateModal(false);
              void createJob('update');
            } else {
              setShowUpdateModal(false);
            }
          }}
        />
      )}

      {showRebootModal && (
        <ConfirmationModal
          title="Reboot OLT"
          prompt={
            <>
              Are you sure you want to reboot this OLT? This may take several
              minutes. {services.length} services will be unavailable during
              this time.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowRebootModal(false);
              void createJob('reboot');
            } else {
              setShowRebootModal(false);
            }
          }}
        />
      )}

      {showForceProvision && (
        <ConfirmationModal
          title="Manual Provision"
          prompt={
            <>
              Are you sure you want to force a manual provision attempt on this
              OLT? Provisions are usually performed automatically by Omni, only
              use this option if you know what you are doing!
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowForceProvision(false);
              void createJob('provision');
            } else {
              setShowForceProvision(false);
            }
          }}
        />
      )}

      {showDeprovision && (
        <ConfirmationModal
          title="Deprovision"
          prompt={
            <>
              Are you sure you want to deprovision OLT? This will reset the OLT
              to factory settings and deprovision all ONUs and services!. If the
              OLT is still reachable after this operation is complete, Omni will
              run ZTP on it again.
              <br />
              <br />
              <strong>Warning:</strong> This is for decommissioning an OLT and
              all devices and services attached to it.
              <br />
              If the OLT is being replaced, click &quot;Replace OLT&quot;
              instead.
              <br />
              If the OLT needs ZTP to be ran again, click &quot;Reset OLT
              State&quot; instead.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowDeprovision(false);
              void createJob('deprovision');
            } else {
              setShowDeprovision(false);
            }
          }}
        />
      )}

      {showReset && (
        <ConfirmationModal
          title="Reset OLT State"
          prompt={
            <>
              Are you sure you want to reset the state of this OLT? This option
              is for if the OLT has been manually reset, ZTP will be performed
              again after resetting.
            </>
          }
          onResolve={(confirmed) => {
            if (confirmed) {
              setShowReset(false);
              void resetOlt();
            } else {
              setShowReset(false);
            }
          }}
        />
      )}

      {showReplace && (
        <Modal
          title="Replace OLT"
          onClose={() => setShowReset(false)}
          controls={[
            {
              type: 'secondary',
              text: 'No',
              onClick: () => {
                setShowReplace(false);
              }
            },
            {
              type: 'primary',
              text: 'Yes',
              onClick: () => {
                setShowReplace(false);
                void replaceOlt();
              }
            }
          ]}
        >
          Are you sure you want to replace this OLT? This option is for if a new
          OLT has been added in Netbox and is replacing an OLT with existing
          service config.
          <br />
          <br />
          <div className="input-group">
            <div className="mb-1">Netbox Device ID</div>
            <input
              type="number"
              placeholder="Enter the id of the device from Netbox"
              value={netboxId}
              onChange={(e) => setNetboxId(parseInt(e.target.value))}
            />
          </div>
          <br />
          <br />
          The OLT state will be updated, new information will be pulled from
          Netbox and the ZTP process will start. This entire process will take
          several minutes and the OLT will appear deprovisioned.
        </Modal>
      )}
    </>
  );
}
