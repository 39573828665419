import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AuthRoute from './routes/ProtectedRoute';
import { AlertProvider } from './context/AlertContext';
import { AxiosProvider } from './context/AxiosContext';
import { SignInCallback } from './pages/auth/SignInCallback';
import { NotFoundPage } from './pages/error/NotFoundPage';
import { persistor, store } from './store/store';
import './styles/global.scss';
import { AppRoutes } from './routes/AppRoutes';
import { AuthRoutes } from './routes/AuthRoutes';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <SignInCallback>
            <AlertProvider>
              <AxiosProvider>
                <Routes>
                  <Route
                    path="/*"
                    element={
                      <AuthRoute>
                        <AppRoutes />
                      </AuthRoute>
                    }
                  />
                  <Route path="/auth/*" element={<AuthRoutes />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </AxiosProvider>
            </AlertProvider>
          </SignInCallback>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
