import axios from 'axios';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from '../../../shared/components/Button';
import { InputGroup } from '../../../shared/components/InputGroup';
import { Modal } from '../../../shared/components/Modal';
import {
  DEVICE_TYPE_ENDPOINT,
  INTERFACE_ENDPOINT,
  MANUFACTURER_ENDPOINT
} from '../../../constants';
import { useAlerts } from '../../../context/AlertContext';
import { alertError, toFormData } from '../../../shared/helpers';
import { AttributeEditor } from '../../../shared/components/AttributeEditor';
import { ObjectResponse } from '../../../shared/types/response/ObjectResponse';
import { Attribute } from '../../../shared/types/provisioning/Attribute';
import { parseAttributes } from '../../../helpers';
import { DeviceType } from '../../../shared/types/provisioning/DeviceType';
import { RemoteDropdownSelect } from '../../../shared/components/RemoteDropdownSelect';
import { Manufacturer } from '../../../shared/types/provisioning/Manufacturer';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import {
  Interface,
  InterfaceType
} from '../../../shared/types/provisioning/Interface';
import { InterfaceBox } from './InterfaceBox';
import { ProductType } from '../../../shared/types/provisioning/Product';
import { ObjectsResponse } from '../../../shared/types/response/ObjectsResponse';

type Props = {
  onClose: (reload: boolean) => void;
  deviceTypeId?: string;
};

type Form = {
  name: string;
  manufacturer: string;
  software?: FileList;
  partNumbers: string;
  interfaces: {
    id?: number;
    name: string;
    type: InterfaceType;
    supportedProductTypes: { [type: string]: boolean };
  }[];
};

export function DeviceTypeModal({ onClose, deviceTypeId }: Props) {
  const { createAlert } = useAlerts();
  const form = useForm<Form>({
    defaultValues: {
      interfaces: []
    }
  });
  const { handleSubmit, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'interfaces'
  });
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [deviceType, setDeviceType] = useState<DeviceType>();

  useEffect(() => {
    async function getDeviceType() {
      if (deviceTypeId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectResponse<DeviceType>>(
            `${DEVICE_TYPE_ENDPOINT}/${deviceTypeId}`
          );

          if (response.data.result.attributes) {
            setAttributes(parseAttributes(response.data.result.attributes));
          }

          const deviceType = response.data.result;
          form.setValue('name', deviceType.name);
          form.setValue('manufacturer', `${deviceType.manufacturerId}`);
          form.setValue('partNumbers', deviceType.partNumbers.join('\n'));

          setDeviceType(deviceType);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    async function getInterfaces() {
      if (deviceTypeId) {
        setLoading(true);

        try {
          const response = await axios.get<ObjectsResponse<Interface>>(
            `${INTERFACE_ENDPOINT}?deviceTypeId=${deviceTypeId}`
          );

          console.log(response.data);

          if (response.data.results) {
            for (let i = 0; i < response.data.results.length; i++) {
              const _interface = response.data.results[i];

              console.log(_interface);

              if (!form.getValues(`interfaces.${i}.supportedProductTypes`)) {
                const supportedProductTypes: { [type: string]: boolean } = {};

                Object.keys(ProductType).forEach(
                  (x) =>
                    (supportedProductTypes[x] =
                      _interface.supportedProductTypes.indexOf(
                        x as ProductType
                      ) > -1)
                );

                append({
                  id: _interface.id,
                  name: _interface.name,
                  type: _interface.type,
                  supportedProductTypes
                });
              }
            }
          }
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }

        setLoading(false);
      }
    }

    async function get() {
      await getDeviceType();
      await getInterfaces();
    }

    void get();
  }, [deviceTypeId]);

  async function onSubmit(data: Form) {
    setLoading(true);

    const _data = {
      ...data,
      interfaces: data.interfaces.map((_interface) => ({
        ..._interface,
        supportedProductTypes: Object.keys(
          _interface.supportedProductTypes
        ).filter((key) => _interface.supportedProductTypes[key])
      })),
      partNumbers: data.partNumbers.split(/\r?\n/),
      attributes
    };

    delete _data.software;

    console.log(_data);

    const formData = new FormData();
    toFormData(formData, _data);

    if (data.software && data.software.length > 0) {
      formData.append(
        'software',
        new File([data.software[0]], data.software[0].name, {
          type: 'text/plain'
        })
      );
    }

    try {
      await axios.request<ObjectResponse<DeviceType>>({
        url: deviceTypeId
          ? `${DEVICE_TYPE_ENDPOINT}/${deviceTypeId}`
          : DEVICE_TYPE_ENDPOINT,
        method: deviceTypeId ? 'PATCH' : 'POST',
        data: formData
      });

      onClose(true);

      createAlert(
        `Device Type ${deviceTypeId ? 'Updated' : 'Added'}`,
        `Device type ${data.name} ${
          deviceTypeId ? 'updated' : 'added'
        } successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }

    setLoading(false);
  }

  return (
    <Modal
      title={`${deviceTypeId ? 'Edit' : 'Add'} Device Type`}
      onClose={() => onClose(false)}
      size="xl"
    >
      <div className="flex gap-3">
        <div className="flex-1">
          <form onSubmit={handleSubmit(onSubmit)} className="input-group-list">
            <InputGroup
              form={form}
              name="name"
              label="Name"
              placeholder="Device Type Name"
              validationOptions={{
                required: 'Please enter a device type name'
              }}
            />

            <RemoteDropdownSelect
              form={form}
              name="manufacturer"
              label="Manufacturer"
              searchPrompt="Find a manufacturer..."
              endpoint={`${MANUFACTURER_ENDPOINT}`}
              generateItem={(item: Manufacturer) => ({
                key: `${ProvisioningIdPrefix.MAN}-${item.id} (${item.name})`,
                value: `${item.id}`
              })}
              placeholder="Select a manufacturer"
              validationOptions={{
                required: 'Please select a manufacturer'
              }}
            />

            <InputGroup
              form={form}
              type="file"
              name="software"
              label="Software"
              placeholder="Device Type Software"
              validationOptions={
                deviceType &&
                (!deviceType.softwareFilename || !deviceType.softwareVersion)
                  ? {
                      required: 'Please select a device type software to upload'
                    }
                  : {}
              }
            />

            <div>
              <strong>Software Filename:</strong>{' '}
              {deviceType?.softwareFilename || 'Not Uploaded'}
              <br />
              <strong>Software Version:</strong>{' '}
              {deviceType?.softwareVersion || 'Not Uploaded'}
            </div>

            <InputGroup
              form={form}
              type="textarea"
              name="partNumbers"
              label="Valid Part Numbers (one per line)"
              placeholder="Valid Part Numbers"
              validationOptions={{
                required: 'Please enter valid part numbers'
              }}
            />

            {fields.map((field, index) => (
              <InterfaceBox
                key={field.id}
                form={form}
                index={index}
                onClose={() => remove(index)}
              />
            ))}

            <Button
              type="secondary"
              onClick={() => {
                append({
                  name: '',
                  type: InterfaceType.HundredMegEthernet,
                  supportedProductTypes: {}
                });
              }}
              full={true}
            >
              {form.getValues('interfaces').length > 0
                ? 'Add another interface'
                : 'Add interface'}
            </Button>
          </form>
        </div>
        <div className="flex-2">
          <AttributeEditor
            attributes={attributes}
            setAttributes={setAttributes}
          />
        </div>
      </div>

      <div className="flex justify-end mt-2 gap-1">
        <Button
          type="secondary"
          disabled={loading}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={async () => await handleSubmit(onSubmit)()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
