import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { ATTRIBUTE_ENDPOINT } from '../../constants';
import { useAlerts } from '../../context/AlertContext';
import { alertError } from '../helpers';
import { Attribute } from '../types/provisioning/Attribute';
import { ObjectsResponse } from '../types/response/ObjectsResponse';
import { Button } from './Button';
import { InputGroup } from './InputGroup';

type Props = {
  attributes: Attribute[];
  setAttributes: (attributes: Attribute[]) => void;
};

type Form = {
  key: string;
  value: string;
  newKey?: string;
};

export function AttributeEditor({ attributes, setAttributes }: Props) {
  const { createAlert } = useAlerts();
  const [attributeKeys, setAttributeKeys] = useState<string[]>([]);
  const [editing, setEditing] = useState<string>();
  const form = useForm<Form>();
  const { handleSubmit } = form;

  useEffect(() => {
    async function load() {
      try {
        const response = await axios.get<
          ObjectsResponse<{
            key: string;
          }>
        >(ATTRIBUTE_ENDPOINT);

        if (response && response.data) {
          setAttributeKeys(response.data.results.map((x) => x.key));

          if (response.data.results && response.data.results.length > 0) {
            form.setValue('key', response.data.results[0].key);
          } else {
            form.setValue('key', 'omni_internal_new');
          }
        }
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }
    void load();
  }, []);

  async function onSubmit(data: Form) {
    console.log(data);

    if (editing) {
      setAttributes(
        attributes.map((x) =>
          x.id === data.key ? { ...x, value: data.value } : x
        )
      );

      setEditing(undefined);
      form.reset();

      if (attributeKeys && attributeKeys.length > 0) {
        form.setValue('key', attributeKeys[0]);
      } else {
        form.setValue('key', 'omni_internal_new');
      }

      return;
    }

    if (data.key === 'omni_internal_new') {
      try {
        await axios.post(ATTRIBUTE_ENDPOINT, {
          key: data.newKey
        });
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }

    setAttributes([
      ...attributes,
      {
        id: data.key === 'omni_internal_new' ? data.newKey || '' : data.key,
        value: data.value
      }
    ]);
    form.reset();

    if (attributeKeys && attributeKeys.length > 0) {
      form.setValue('key', attributeKeys[0]);
    } else {
      form.setValue('key', 'omni_internal_new');
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="attributes-table">
        <table>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!editing && (
              <RenderForm
                form={form}
                attributeKeys={attributeKeys}
                attributes={attributes}
              />
            )}
            {attributes.map((attribute) =>
              editing === attribute.id ? (
                <RenderForm
                  key={attribute.id}
                  form={form}
                  attributeKeys={attributeKeys}
                  attributes={attributes}
                  current={attribute.id}
                />
              ) : (
                <tr key={attribute.id}>
                  <td>{attribute.id}</td>
                  <td>
                    {attribute.value || (
                      <span className="text-muted">(unspecified)</span>
                    )}
                  </td>
                  <td className="td-minimum td-no-padding">
                    <div className="table-controls">
                      <Button
                        type="secondary"
                        onClick={() => {
                          setAttributes(
                            attributes.filter((x) => x.id !== attribute.id)
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                  <td className="td-minimum">
                    <div className="table-controls">
                      <Button
                        type="secondary"
                        onClick={() => {
                          setEditing(attribute.id);
                          form.setValue('key', attribute.id);
                          form.setValue('value', attribute.value || '');
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </form>
  );
}

type RenderFormProps = {
  attributes: Attribute[];
  form: UseFormReturn<Form>;
  attributeKeys: string[];
  current?: string;
};

function RenderForm({
  form,
  attributeKeys,
  attributes,
  current
}: RenderFormProps) {
  const watch = useWatch({
    control: form.control,
    name: 'key'
  });

  return (
    <tr>
      <td>
        <InputGroup
          form={form}
          name="key"
          type="select"
          options={[
            ...attributeKeys
              .filter(
                (x) => current === x || !attributes.find((y) => y.id === x)
              )
              .map((key) => ({
                label: key,
                value: key
              })),
            {
              label: '(create new attribute)',
              value: 'omni_internal_new'
            }
          ]}
          validationOptions={{
            required: 'Please select a valid attribute'
          }}
          disabled={!!current}
        />
        {watch === 'omni_internal_new' && (
          <div className="mt-1">
            <InputGroup
              form={form}
              name="newKey"
              placeholder="Enter a key for the attribute"
              validationOptions={{
                required: 'Please enter an attribute key',
                validate: (value) =>
                  value === 'omni_internal_new'
                    ? 'This is an invalid attribute name'
                    : true
              }}
            />
          </div>
        )}
      </td>
      <td colSpan={2}>
        <InputGroup form={form} name="value" placeholder="Attribute Value" />
      </td>
      <td className="td-minimum">
        <div className="table-controls">
          <Button submit>Save</Button>
        </div>
      </td>
    </tr>
  );
}
