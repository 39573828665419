import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../error/NotFoundPage';
import { DeviceTypes } from './device-types/DeviceTypes';
import { Devices } from './devices/Devices';
import { DeviceView } from './devices/view/DeviceView';
import { Manufacturers } from './manufacturers/Manufacturers';
import { Products } from './products/Products';
import { Services } from './services/Services';
import { ServiceView } from './services/view/ServiceView';
import { Olts } from './olts/Olts';
import { OltView } from './olts/view/OltView';
import { Switches } from './switches/Switches';
import { SwitchView } from './switches/view/SwitchView';

export function Provisioning() {
  return (
    <Routes>
      <Route path="/services" element={<Services />} />
      <Route path="/services/:id/edit" element={<ServiceView edit />} />
      <Route path="/services/:id" element={<ServiceView />} />
      <Route path="/devices" element={<Devices />} />
      <Route path="/devices/:id/edit" element={<DeviceView edit />} />
      <Route path="/devices/:id" element={<DeviceView />} />
      <Route path="/olts" element={<Olts />} />
      <Route path="/olts/:id" element={<OltView />} />
      <Route path="/device-types" element={<DeviceTypes />} />
      <Route path="/device-types/:id" element={<DeviceTypes />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<Products />} />
      <Route path="/manufacturers" element={<Manufacturers />} />
      <Route path="/manufacturers/:id" element={<Manufacturers />} />
      <Route path="/switches" element={<Switches />} />
      <Route path="/switches/:id" element={<SwitchView />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
