import { useEffect, useState } from 'react';
import { useAlerts } from '../../../../context/AlertContext';
import { Audit } from '../../../../shared/components/audit/Audit';
import { alertError } from '../../../../shared/helpers';
import { AuditObjectType } from '../../../../shared/types/audit/AuditObjectType';
import { AuditQuery } from '../../../../shared/types/audit/AuditQuery';
import { Incident } from '../../../../shared/types/monitoring/Incident';

type Props = {
  incident: Incident;
};

export function AuditLog({ incident }: Props) {
  const { createAlert } = useAlerts();
  const [query, setQuery] = useState<AuditQuery>();

  useEffect(() => {
    function load() {
      try {
        const query = [
          {
            objectId: incident.id.toString(),
            objectType: AuditObjectType.MONITORING_INCIDENT
          }
        ];

        setQuery(query);
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      }
    }
    void load();
  }, []);

  if (query) {
    return <Audit title="Incident History" query={query} />;
  }

  return null;
}
