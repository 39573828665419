import { Link } from 'react-router-dom';
import { OLT_ENDPOINT } from '../../../constants';
import { Badge } from '../../../shared/components/Badge';
import { PaginatedSearchTable } from '../../../shared/components/PaginatedSearchTable';
import useDocumentTitle from '../../../shared/hooks/useDocumentTitle';
import { ProvisioningIdPrefix } from '../../../shared/types/ProvisionIdPrefix';
import { Olt, OltState } from '../../../shared/types/provisioning/Olt';

export function Olts() {
  useDocumentTitle('OLTs');

  return (
    <>
      <div className="page-wrapper">
        <PaginatedSearchTable
          title="OLTs"
          searchPrompt="Find an OLT..."
          endpoint={OLT_ENDPOINT}
          columns={['ID', 'Name', 'IP', 'Model', 'Manufacturer']}
          generateRow={(result: Olt) => [
            <>
              <Link to={`/provisioning/olts/${result.id}`}>
                {ProvisioningIdPrefix.OLT}-{result.id}
              </Link>
            </>,
            result.name,
            result.ip,
            result.model,
            result.manufacturer,
            <>
              <Badge
                type={
                  result.state === OltState.DEPROVISIONED
                    ? undefined
                    : result.state === OltState.ERROR
                    ? 'red'
                    : result.state === OltState.PROVISIONED
                    ? 'green'
                    : 'blue'
                }
              >
                {result.state}
              </Badge>
            </>
          ]}
        />
      </div>
    </>
  );
}
