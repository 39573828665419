import { Badge } from '../../../../shared/components/Badge';
import { CollapsibleCard } from '../../../../shared/components/CollapsibleCard';
import { CollapsibleItemList } from '../../../../shared/components/CollapsibleItemList';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import { Service } from '../../../../shared/types/provisioning/Service';
import { LegacyOltDeviceGroups, OltDeviceGroups } from '../types';
import { DeviceInfoBar } from './DeviceInfoBar';
import { ServicesTable } from './ServicesTable';

type Props = {
  oltDeviceGroups: OltDeviceGroups | LegacyOltDeviceGroups;
  services: Service[];
  isLegacySite: boolean;
  isLoading: boolean;
};

export function RelatedDevices({
  oltDeviceGroups,
  services,
  isLegacySite,
  isLoading
}: Props) {
  const oltDeviceGroupEntries = Object.entries(oltDeviceGroups) as [
    string,
    OltDeviceGroups['device'] | LegacyOltDeviceGroups['device']
  ][];

  function getPonPortFromInterface(itf?: string): string {
    if (itf && itf.indexOf('.') > -1) {
      // If it's 0, we want to render it as port 16. Otherwise just show channel partition from interface
      const port = itf.split('.')[1];
      return port === '0' ? '16' : port;
    }

    return 'Not Found';
  }

  return (
    <>
      <CollapsibleCard
        title={<strong>Related {isLegacySite ? 'Devices' : 'Services'}</strong>}
      >
        {oltDeviceGroupEntries.length ? (
          oltDeviceGroupEntries.map(([oltId, oltInfo], i) => (
            <CollapsibleCard
              title={
                <div className="flex gap-2 items-center">
                  <a href={`/provisioning/olts/${oltId}`}>
                    {ProvisioningIdPrefix.OLT}-{oltId}
                  </a>{' '}
                  ({oltInfo.name})
                  <Badge className="ml-auto">
                    {oltInfo.devices.length} device
                    {oltInfo.devices.length === 1 ? '' : 's'}
                  </Badge>
                </div>
              }
              key={oltId}
              className={`${i > 0 ? 'mt-2' : ''}`}
              closed={true}
            >
              {isLegacySite ? (
                // Legacy site - just show alarm list
                (
                  oltInfo.devices as LegacyOltDeviceGroups['device']['devices']
                ).map((alarm, i) => (
                  <div className={`card ${i > 0 ? 'mt-2' : ''}`} key={alarm.id}>
                    <div className={'flex gap-2 items-center'}>
                      {alarm.serial}
                      <div>Port {getPonPortFromInterface(alarm.interface)}</div>
                      <Badge
                        type={
                          !alarm.legacyOperStatus
                            ? 'grey'
                            : alarm.legacyOperStatus.toLowerCase() === 'up'
                            ? 'green'
                            : alarm.legacyOperStatus.toLowerCase() === 'down'
                            ? 'red'
                            : 'yellow'
                        }
                        className="ml-auto"
                        title={
                          alarm.legacyLastChecked
                            ? `Last checked at ${alarm.legacyLastChecked}`
                            : `Last check unknown`
                        }
                      >
                        {alarm.legacyLastChecked
                          ? alarm.legacyOperStatus || 'not found'
                          : 'not checked'}
                      </Badge>
                    </div>
                    <div>
                      {alarm.onuDescription && (
                        <span className="text-muted text-sm">
                          {alarm.onuDescription.indexOf(' - ') > -1
                            ? alarm.onuDescription.split(' - ')[1]
                            : alarm.onuDescription}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {/* Omni site - show devices with nested services */}
                  <CollapsibleItemList
                    items={(
                      oltInfo.devices as OltDeviceGroups['device']['devices']
                    ).map((device) => ({
                      id: `${device.id}`,
                      title: <DeviceInfoBar device={device} />,
                      subTitle: device.address,
                      content: (
                        <ServicesTable
                          services={services}
                          deviceId={device.id}
                        />
                      ),
                      closed: true
                    }))}
                  />
                </>
              )}
            </CollapsibleCard>
          ))
        ) : isLoading ? (
          <p>Loading...</p>
        ) : (
          <p>No related {isLegacySite ? 'devices' : 'services'} found.</p>
        )}
      </CollapsibleCard>
    </>
  );
}
