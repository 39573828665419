import { ReactNode } from 'react';
import { Modal } from './Modal';

type Props = {
  title: string;
  prompt: ReactNode;
  onResolve: (confirmed: boolean) => void;
  confirmText?: string;
  denyText?: string;
};

export function ConfirmationModal({
  title,
  prompt,
  onResolve,
  confirmText,
  denyText
}: Props) {
  return (
    <Modal
      title={title}
      onClose={() => onResolve(false)}
      controls={[
        {
          text: denyText || 'No',
          type: 'secondary',
          onClick: () => onResolve(false)
        },
        {
          text: confirmText || 'Yes',
          type: 'primary',
          onClick: () => onResolve(true)
        }
      ]}
    >
      {prompt}
    </Modal>
  );
}
