import axios from 'axios';
import { Attachment } from 'nodemailer/lib/mailer';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ALARM_ENDPOINT, INCIDENT_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { hasAccess } from '../../../../helpers';
import { Button } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { alertError, downloadCsv } from '../../../../shared/helpers';
import {
  Incident,
  IncidentState
} from '../../../../shared/types/monitoring/Incident';
import { MonitoringIdPrefix } from '../../../../shared/types/monitoring/MonitoringIdPrefix';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { AuthState } from '../../../../store/reducers/AuthReducer';
import { RootState } from '../../../../store/store';
import { LegacyOltDeviceGroups, OltDeviceGroups } from '../types';

type Props = {
  incident: Incident;
  oltDeviceGroups: OltDeviceGroups | LegacyOltDeviceGroups;
  reload: React.DispatchWithoutAction;
};

export function Controls({ incident, oltDeviceGroups, reload }: Props) {
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { createAlert } = useAlerts();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [resolving, setResolving] = useState<boolean>(false);
  const [reopening, setReopening] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>(false);
  const [isCheckingOlts, setIsCheckingOlts] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

  const disableControls = loading || isCsvLoading || isCheckingOlts;

  async function deleteIncident(): Promise<void> {
    if (!incident) return;

    try {
      setLoading(true);
      await axios.delete(`${INCIDENT_ENDPOINT}/${incident.id}`);

      reload();

      createAlert(
        'Incident Deleted',
        `Incident ${incident.id} deleted successfully`,
        'success'
      );
      setLoading(false);
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
      setLoading(false);
    }
  }

  async function confirmIncident(): Promise<void> {
    if (!incident) return;
    setLoading(true);

    try {
      await axios.patch(
        `${INCIDENT_ENDPOINT}/${incident.id}/confirmedTimestamp`
      );

      reload();

      createAlert(
        'Incident Confirmed',
        `${MonitoringIdPrefix.INC}-${incident.id} confirmed successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function resolveIncident(): Promise<void> {
    if (!incident) return;
    setLoading(true);

    try {
      await axios.patch(
        `${INCIDENT_ENDPOINT}/${incident.id}/resolvedTimestamp`
      );

      reload();

      createAlert(
        'Incident Resolved',
        `${MonitoringIdPrefix.INC}-${incident.id} resolved successfully`,
        'success'
      );
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function reopenIncident(): Promise<void> {
    if (!incident) return;
    setLoading(true);

    try {
      await axios.patch(`${INCIDENT_ENDPOINT}/${incident.id}/reopenIncident`);

      reload();

      createAlert(
        'Incident Reopened',
        `${MonitoringIdPrefix.INC}-${incident.id} reopened successfully`,
        'success'
      );

      await checkOlts();
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    } finally {
      setLoading(false);
    }
  }

  async function checkOlts(): Promise<void> {
    setIsCheckingOlts(true);

    try {
      if (incident.isLegacySite) {
        // Legacy will check the registered alarms against the current interface state
        await axios.post<Response>(
          `${ALARM_ENDPOINT}/update-legacy-alarm-status`,
          {
            incidentId: incident.id
          }
        );
      } else {
        // Omni will run the regular checks against the OLT services
        await axios.post<Response>(`${INCIDENT_ENDPOINT}/check-olts`, {
          incidentId: incident.id
        });
      }

      reload();

      createAlert(
        'Incident Check Initiated',
        `OLTs in incident ${MonitoringIdPrefix.INC}-${incident.id} will now be checked by the system.`,
        'success'
      );
    } catch (err) {
      console.error('Error checking OLTs', err);
      alertError(createAlert, err);
    } finally {
      setIsCheckingOlts(false);
    }
  }

  async function sendUpdateEmail(): Promise<void> {
    setIsSendingEmail(true);

    try {
      await axios.post<Response>(`${INCIDENT_ENDPOINT}/send-update-email`, {
        incidentId: incident.id
      });

      createAlert(
        'Update Email Sent',
        `An incident update email has been sent to the configured email addresses.`,
        'success'
      );
    } catch (err) {
      console.error('Error sending update email', err);
      alertError(createAlert, err);
    } finally {
      setIsSendingEmail(false);
    }
  }

  async function downloadIncidentCsv(): Promise<void> {
    setIsCsvLoading(true);

    try {
      const response = await axios.get<ObjectResponse<Attachment>>(
        `${INCIDENT_ENDPOINT}/${incident.id}/csv`
      );

      if (!response.data.result.content || !response.data.result.filename) {
        throw new Error('CSV response is malformed');
      }

      downloadCsv(
        response.data.result.content as string,
        response.data.result.filename
      );
    } catch (err) {
      console.error('Error retrieving CSV', err);
      alertError(createAlert, err);
    } finally {
      setIsCsvLoading(false);
    }
  }

  const minutesToCheck = 20;
  const earliestTime = new Date(Date.now() - minutesToCheck * 60000);
  const allCheckedInTimerange = incident.isLegacySite
    ? Object.values(oltDeviceGroups as LegacyOltDeviceGroups).every((group) =>
        group.devices?.every(
          (alarm) =>
            alarm?.legacyLastChecked &&
            new Date(alarm?.legacyLastChecked) > earliestTime
        )
      )
    : Object.values(oltDeviceGroups as OltDeviceGroups).every((group) =>
        group.devices?.every(
          (device) =>
            device?.lastCheck?.createdAt &&
            new Date(device.lastCheck.createdAt) > earliestTime
        )
      );

  if (!auth.account) {
    return null;
  }

  return (
    <>
      <div className="flex gap-1">
        <Button
          type="secondary"
          onClick={async () => await downloadIncidentCsv()}
          disabled={disableControls}
        >
          {isCsvLoading ? 'Exporting CSV...' : 'Export to CSV'}
        </Button>

        {!incident.resolvedTimestamp && (
          <Button
            type="secondary"
            onClick={async () => await checkOlts()}
            disabled={
              disableControls || incident.state === IncidentState.CHECKING
            }
          >
            {isCheckingOlts ? 'Initiating checks...' : 'Check all OLTs'}
          </Button>
        )}

        {hasAccess(auth.account, ['monitoring:incident.send-update']) &&
          !incident.resolvedTimestamp && (
            <Button
              type="secondary"
              onClick={async () => await sendUpdateEmail()}
              disabled={
                disableControls ||
                !allCheckedInTimerange ||
                incident.state === IncidentState.CHECKING
              }
              title={
                allCheckedInTimerange
                  ? undefined
                  : `All devices must have been checked within the last ${minutesToCheck} minutes to send an update email.`
              }
            >
              {isSendingEmail ? 'Sending update email...' : 'Send update email'}
            </Button>
          )}

        {hasAccess(auth.account, ['monitoring:incident.patch']) && (
          <Button
            type="secondary"
            url={`/monitoring/incidents/${incident.id}/edit`}
            disabled={disableControls}
          >
            Edit
          </Button>
        )}

        {hasAccess(auth.account, ['monitoring:incident.delete']) &&
          !incident.resolvedTimestamp &&
          !incident.confirmedTimestamp && (
            <Button
              type="secondary"
              onClick={() => setDeleting(true)}
              disabled={
                disableControls || incident.state === IncidentState.CHECKING
              }
            >
              Delete
            </Button>
          )}

        {hasAccess(auth.account, ['monitoring:incident.confirm']) &&
          !incident.confirmedTimestamp && (
            <Button
              onClick={() => setConfirming(true)}
              disabled={disableControls}
            >
              Confirm
            </Button>
          )}

        {hasAccess(auth.account, ['monitoring:incident.resolve']) &&
          !incident.resolvedTimestamp && (
            <Button
              onClick={() => setResolving(true)}
              disabled={disableControls}
            >
              Resolve
            </Button>
          )}

        {
          // can only reopen after resolving
          hasAccess(auth.account, ['monitoring:incident.reopen']) &&
            incident.confirmedTimestamp &&
            incident.resolvedTimestamp && (
              <Button
                onClick={() => setReopening(true)}
                disabled={disableControls}
              >
                Reopen
              </Button>
            )
        }
      </div>

      {confirming && (
        <ConfirmationModal
          title={`Confirm Incident`}
          prompt={
            <>
              Are you sure you want to mark incident{' '}
              <strong>
                {MonitoringIdPrefix.INC}-{incident.id}
              </strong>{' '}
              as confirmed?
            </>
          }
          onResolve={async (confirmed) => {
            setConfirming(false);
            if (confirmed) {
              await confirmIncident();
              navigate(`/monitoring/incidents/${incident.id}`);
            }
          }}
          denyText="Cancel"
          confirmText="Confirm Incident"
        />
      )}

      {resolving && (
        <ConfirmationModal
          title={`Resolve Incident`}
          prompt={
            <>
              Are you sure you want to mark incident{' '}
              <strong>
                {MonitoringIdPrefix.INC}-{incident.id}
              </strong>{' '}
              as resolved?
              {!incident.isLegacySite && (
                <>
                  <br />
                  <br />
                  This will automatically remove the current error message from
                  the attached devices.
                </>
              )}
            </>
          }
          onResolve={async (resolved) => {
            setResolving(false);
            if (resolved) {
              await resolveIncident();
              navigate(`/monitoring/incidents/${incident.id}`);
            }
          }}
          denyText="Cancel"
          confirmText="Resolve Incident"
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Delete Incident"
          prompt={
            <>
              Are you sure you want to delete incident{' '}
              <strong>
                {MonitoringIdPrefix.INC}-{incident.id}
              </strong>
              ?
            </>
          }
          onResolve={async (confirmed) => {
            setDeleting(false);
            if (confirmed) {
              await deleteIncident();
              navigate(`/monitoring/incidents`);
            }
          }}
          denyText="Cancel"
          confirmText="Delete Incident"
        />
      )}

      {reopening && (
        <ConfirmationModal
          title={`Reopen Incident`}
          prompt={
            <>
              Are you sure you want to mark incident{' '}
              <strong>
                {MonitoringIdPrefix.INC}-{incident.id}
              </strong>{' '}
              as reopened?
              <br />
              <br />
              This will automatically run a check on the OLTs.
            </>
          }
          onResolve={async (reopened) => {
            setReopening(false);
            if (reopened) {
              await reopenIncident();
              navigate(`/monitoring/incidents/${incident.id}`);
            }
          }}
          denyText="Cancel"
          confirmText="Reopen Incident"
        />
      )}
    </>
  );
}
