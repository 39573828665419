import { Icon } from '@iconify/react';
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NOTIFICATIONS_ENDPOINT } from '../../constants';
import { useAlerts } from '../../context/AlertContext';
import { AuthState } from '../../store/reducers/AuthReducer';
import { RootState } from '../../store/store';
import { alertError } from '../helpers';
import { Notification } from '../types/Notification';
import { PagedResponse } from '../types/response/PagedResponse';

export function UserBar() {
  const { createAlert } = useAlerts();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);

  const loadNotifications = React.useCallback(async () => {
    if (!auth || !auth.token || !auth.account) {
      return;
    }

    try {
      // ONLY GET STATE 0 (UNREAD)
      const response = await axios.get<PagedResponse<Notification>>(
        `${NOTIFICATIONS_ENDPOINT}/notification/account/${auth.account.id}?state=0`
      );

      setNotifications(response.data.results);
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }, []);

  useEffect(() => {
    void loadNotifications();
    if (!auth || !auth.token || !auth.sessionId) {
      return;
    }
  }, [loadNotifications]);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!notificationRef.current?.contains(e.target as Node)) {
        setShowNotifications(false);
      }
    }

    window.addEventListener('click', onClick);
  }, [notificationRef]);

  async function setNotificationState(
    notificationId: string,
    notificationState: number
  ) {
    if (!auth || !auth.token || !auth.account) {
      return;
    }
    await axios.post<Response>(
      `${NOTIFICATIONS_ENDPOINT}/${notificationId}/state`,
      { state: notificationState }
    );

    setNotifications([]);
    await loadNotifications();
  }

  return (
    <>
      <div className="user-bar-wrapper">
        <div className="user-bar-content flex">
          <div className="flex-1">
            {/* network is <span className="text-green">healthy</span> */}
          </div>
          <div className="flex gap-2">
            <div
              className="notification-dropdown-wrapper"
              ref={notificationRef}
            >
              <Icon
                icon="clarity:notification-solid"
                onClick={() => setShowNotifications(!showNotifications)}
                className={`notification-icon ${
                  showNotifications ? 'active' : ''
                }`}
              />
              {showNotifications && (
                <div className="notification-dropdown">
                  <div className="notification-inner-dropdown">
                    <div className="header">
                      <strong>Notifications</strong>
                    </div>

                    <div className="horizontal-separator" />

                    {notifications.length === 0 && (
                      <div className="notification">
                        You don&apos;t have any new notifications.
                      </div>
                    )}

                    {notifications.map((notification) => {
                      return (
                        <div className="notification" key={notification.id}>
                          <div className="header">
                            <div className="left">
                              <Link
                                to={`/notifications/${notification.id}`}
                                onClick={() => {
                                  void (async () => {
                                    await setNotificationState(
                                      notification.id,
                                      1
                                    );
                                  })();
                                }}
                              >
                                {notification.title}
                              </Link>
                            </div>
                            <div className="right">
                              {DateTime.fromISO(
                                notification.createdAt
                              ).toFormat('dd/MM/yyyy HH:mm')}
                            </div>
                          </div>

                          <div className="content">{notification.summary}</div>
                        </div>
                      );
                    })}

                    <div className="notifications-link">
                      <Link to="/notifications">View all notifications</Link>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {auth.account?.email}
            <Link to="/auth/signout" className="link-white">
              sign out
            </Link>
          </div>
        </div>
      </div>
      <div className="user-bar-border"></div>
    </>
  );
}
