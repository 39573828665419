import axios from 'axios';
import { useEffect, useState } from 'react';
import { GROUP_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { Button } from '../../../../shared/components/Button';
import { alertError } from '../../../../shared/helpers';
import { Group } from '../../../../shared/types/auth/Group';
import { Settings } from '../../../../shared/types/auth/Settings';
import { ObjectsResponse } from '../../../../shared/types/response/ObjectsResponse';

type Props = {
  groupIds: string[];
  updateSettings: (
    newSettings: Partial<Settings>,
    alertTitle?: string
  ) => Promise<void>;
};

export function GroupList({ groupIds, updateSettings }: Props) {
  const { createAlert } = useAlerts();
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const selectedGroupIds = Array.from(
      new FormData(event.target as HTMLFormElement).values()
    ).map((groupId) => groupId.toString());

    await updateSettings(
      {
        incidentRaisedNotificationGroupIds: selectedGroupIds
      },
      'Groups updated'
    );
  }

  useEffect(() => {
    if (groups.length) return;
    async function getAllGroups() {
      setIsLoading(true);

      try {
        const response = await axios.get<ObjectsResponse<Group>>(
          `${GROUP_ENDPOINT}/all`
        );

        setGroups(response.data.results);
      } catch (err) {
        console.error(err);
        alertError(createAlert, err);
      } finally {
        setIsLoading(false);
      }
    }

    void getAllGroups();
  }, []);

  if (isLoading) {
    return <>Loading groups...</>;
  }

  const checkedSet = new Set(groupIds);

  return (
    <form onSubmit={async (e) => await handleSubmit(e)} className="my-1">
      <ul className="email-list">
        {groups.map((group) => (
          <li key={group.id} className="flex">
            <label>
              <input
                type="checkbox"
                name="groups"
                value={group.id}
                defaultChecked={checkedSet.has(group.id)}
                className="mr-1"
              />
              {group.name}
            </label>
          </li>
        ))}
      </ul>
      <Button submit disabled={false}>
        Save changes
      </Button>
    </form>
  );
}
