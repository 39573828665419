export enum ChangeType {
  UNCHANGED,
  ADDED,
  MODIFIED,
  DELETED
}

export type ChangeData = {
  key: string;
  type: ChangeType;
  original: unknown;
  updated: unknown;
  children?: { [key: string]: ChangeData };
};
