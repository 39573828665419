import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ACCOUNT_ENDPOINT, SESSION_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { Button } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { alertError } from '../../../../shared/helpers';
import useDocumentTitle from '../../../../shared/hooks/useDocumentTitle';
import { Account } from '../../../../shared/types/auth/Account';
import { Session } from '../../../../shared/types/auth/Session';
import { ObjectResponse } from '../../../../shared/types/response/ObjectResponse';
import { ObjectsResponse } from '../../../../shared/types/response/ObjectsResponse';

export function UserView() {
  const { createAlert } = useAlerts();
  const { id } = useParams<{ id: string }>();
  const [account, setAccount] = useState<Account>();
  useDocumentTitle(
    account
      ? `Editing ${account.firstName} ${account.lastName}`
      : 'Editing User'
  );
  const [sessions, setSessions] = useState<Session[]>([]);
  const [deactivating, setDeactivating] = useState<Session>();

  useEffect(() => {
    async function getAccount() {
      if (id) {
        try {
          const response = await axios.get<ObjectResponse<Account>>(
            `${ACCOUNT_ENDPOINT}/${id}`
          );
          setAccount(response.data.result);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }
      }
    }

    async function getSessions() {
      if (id) {
        try {
          const response = await axios.get<ObjectsResponse<Session>>(
            `${SESSION_ENDPOINT}/${id}`
          );
          setSessions(response.data.results);
        } catch (err) {
          console.error(err);
          alertError(createAlert, err);
        }
      }
    }

    void getAccount();
    void getSessions();
  }, [id]);

  async function deactivateSession(session: Session) {
    try {
      await axios.post(`${SESSION_ENDPOINT}/${session.id}/deactivate`);
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
    }
  }

  if (!account) {
    return null;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="card flex flex-col gap-2">
          <h2 className="mt-1 mb-2">Contact Information</h2>
          <div>
            <div className="mb-1">
              <strong>Full Name</strong>
            </div>
            {account.firstName} {account.lastName}
          </div>
          <div>
            <div className="mb-1">
              <strong>Email</strong>
            </div>
            {account.email}
          </div>
          <div>
            <div className="mb-1">
              <strong>Phone Number</strong>
            </div>
            {account.phoneNumber || 'None set'}
          </div>
          <div>
            <div className="mb-1">
              <strong>Group</strong>
            </div>
            {account.group ? account.group.name : 'None'}
          </div>
        </div>
        <div className="card flex flex-col gap-2 mt-2">
          <h2 className="mt-1 mb-2">Sessions</h2>
          <table>
            <thead>
              <tr>
                <th>IP Address</th>
                <th>User Agent</th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.id}>
                  <td>{session.ipAddress}</td>
                  <td>{session.userAgent}</td>
                  <td>
                    <div className="table-controls">
                      <Button
                        type="secondary"
                        onClick={() => setDeactivating(session)}
                      >
                        Deactivate
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {deactivating && (
        <ConfirmationModal
          title="Deactivate Session"
          prompt="Are you sure you want to deactivate this session?"
          onResolve={async (confirmed) => {
            if (confirmed) {
              await deactivateSession(deactivating);
            }

            setDeactivating(undefined);
          }}
        />
      )}
    </>
  );
}
