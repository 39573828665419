import { Link } from 'react-router-dom';
import { Badge } from '../../../../shared/components/Badge';
import { CollapsibleCard } from '../../../../shared/components/CollapsibleCard';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';

type Props = {
  services: Service[];
};

export function ServiceList({ services }: Props) {
  return (
    <>
      <CollapsibleCard title={<strong>Related Services</strong>}>
        {services && services.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Product</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.id}>
                  <td>
                    <Link to={`/provisioning/services/${service.id}`}>
                      {ProvisioningIdPrefix.SVC}-{service.id}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/provisioning/products/${service.productId}/view`}
                    >
                      {ProvisioningIdPrefix.PRD}-{service.productId}
                    </Link>{' '}
                    <span className="text-muted">
                      ({service.product?.name})
                    </span>
                  </td>
                  <td>
                    <Badge
                      type={
                        service.state === ServiceState.NOT_PROVISIONED
                          ? undefined
                          : service.state === ServiceState.ERROR
                          ? 'red'
                          : service.state === ServiceState.PROVISIONED
                          ? 'green'
                          : 'blue'
                      }
                    >
                      {service.state}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          'There are no services related to this device'
        )}
      </CollapsibleCard>
    </>
  );
}
