import { Icon } from '@iconify/react';
import { ReactNode, useState } from 'react';

type Props = {
  title: ReactNode;
  subTitle?: ReactNode;
  children: ReactNode;
  right?: ReactNode;
  className?: string;
  closed?: boolean;
};

export function CollapsibleCard({
  title,
  subTitle,
  children,
  right,
  className,
  closed
}: Props) {
  const [open, setOpen] = useState(!closed);

  return (
    <div className={`card flex-1 ${className ?? ''}`}>
      <div>
        <div className="flex-1 flex hover" onClick={() => setOpen(!open)}>
          <div className="flex-1 flex items-center gap-1">
            <Icon
              icon={open ? 'codicon:triangle-down' : 'codicon:triangle-right'}
            />{' '}
            <div className="flex-1">{title}</div>
          </div>
          <div>{right}</div>
        </div>
        {subTitle && <div className="ml-3 text-muted text-sm">{subTitle}</div>}
      </div>
      {open && <div className="mt-2">{children}</div>}
    </div>
  );
}
