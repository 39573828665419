import { Link } from 'react-router-dom';
import { Badge } from '../../../../shared/components/Badge';
import { CollapsibleCard } from '../../../../shared/components/CollapsibleCard';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';
import {
  Device,
  DeviceState
} from '../../../../shared/types/provisioning/Device';

type Props = {
  services: Service[];
  devices: Device[];
};

export function ServiceList({ services, devices }: Props) {
  function portList() {
    return Array.from(new Set(devices.map((x) => x.ponPort ?? '')));
  }

  return (
    <div>
      <CollapsibleCard title={<strong>Devices</strong>}>
        {devices && devices.length > 0
          ? portList().map((port) => (
              <CollapsibleCard
                key={port}
                className="mb-2"
                title={`Pon Port ${port}`}
              >
                {devices
                  .filter((x) => x.ponPort === port)
                  .map((device) => (
                    <CollapsibleCard
                      key={device.id}
                      closed
                      title={
                        <div className="flex gap-2 items-center">
                          <Link to={`/provisioning/devices/${device.id}`}>
                            {ProvisioningIdPrefix.DEV}-{device.id}
                          </Link>
                          <div>
                            <div>
                              {device.deviceTypeId ? (
                                <>
                                  <Link
                                    to={`/provisioning/device-types/${device.deviceTypeId}`}
                                  >
                                    {ProvisioningIdPrefix.DVT}-
                                    {device.deviceTypeId}
                                  </Link>{' '}
                                  <span className="text-muted">
                                    ({device.deviceType?.name})
                                  </span>
                                </>
                              ) : (
                                <Badge>UNKNOWN</Badge>
                              )}
                            </div>
                          </div>
                          <div className="flex-1">{device.serial}</div>
                          <div>
                            <Badge
                              type={
                                device.state === DeviceState.IN_DISCOVERY
                                  ? undefined
                                  : device.state === DeviceState.ERROR
                                  ? 'red'
                                  : device.state === DeviceState.PROVISIONED
                                  ? 'green'
                                  : 'blue'
                              }
                            >
                              {device.state === DeviceState.IN_DISCOVERY
                                ? 'DISABLED'
                                : device.state === DeviceState.DEPROVISIONING
                                ? 'DISABLING'
                                : device.state}
                            </Badge>
                          </div>
                        </div>
                      }
                      className="mb-2"
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Product</th>
                            <th>State</th>
                          </tr>
                        </thead>
                        <tbody>
                          {services.filter(
                            (x) =>
                              x.device &&
                              x.device.id === device.id &&
                              x.device.ponPort === port
                          ).length > 0 ? (
                            services
                              .filter(
                                (x) =>
                                  x.device &&
                                  x.device.id === device.id &&
                                  x.device.ponPort === port
                              )
                              .map((service) => (
                                <tr key={service.id}>
                                  <td>
                                    <Link
                                      to={`/provisioning/services/${service.id}`}
                                    >
                                      {ProvisioningIdPrefix.SVC}-{service.id}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/provisioning/products/${service.productId}/view`}
                                    >
                                      {ProvisioningIdPrefix.PRD}-
                                      {service.productId}
                                    </Link>{' '}
                                    <span className="text-muted">
                                      ({service.product?.name})
                                    </span>
                                  </td>
                                  <td>
                                    <Badge
                                      type={
                                        service.state ===
                                        ServiceState.NOT_PROVISIONED
                                          ? undefined
                                          : service.state === ServiceState.ERROR
                                          ? 'red'
                                          : service.state ===
                                            ServiceState.PROVISIONED
                                          ? 'green'
                                          : 'blue'
                                      }
                                    >
                                      {service.state}
                                    </Badge>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td className="my-2" colSpan={3}>
                                There are no services related to this device
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </CollapsibleCard>
                  ))}
              </CollapsibleCard>
            ))
          : 'There are no devices related to this OLT'}
      </CollapsibleCard>
    </div>
  );
}
