import { Badge } from '../../../../shared/components/Badge';

type Props = {
  isLegacySite: boolean;
};

export function IncidentSiteBadge({ isLegacySite }: Props) {
  return (
    <>
      {isLegacySite ? (
        <Badge type="grey">Legacy</Badge>
      ) : (
        <Badge type="omni">Omni</Badge>
      )}
    </>
  );
}
