import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DEVICE_ENDPOINT } from '../../../../constants';
import { useAlerts } from '../../../../context/AlertContext';
import { hasAccess } from '../../../../helpers';
import { Badge } from '../../../../shared/components/Badge';
import { Button } from '../../../../shared/components/Button';
import { alertError } from '../../../../shared/helpers';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Device,
  DeviceState
} from '../../../../shared/types/provisioning/Device';
import { AuthState } from '../../../../store/reducers/AuthReducer';
import { RootState } from '../../../../store/store';

type Props = {
  device: Device;
  setDevice: React.Dispatch<React.SetStateAction<Device | undefined>>;
};

export function Details({ device, setDevice }: Props) {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { createAlert } = useAlerts();
  const [deviceState, setDeviceState] = useState<DeviceState>(device.state);
  const [isOverridingState, setIsOverridingState] = useState<boolean>(false);
  const [isPostingStateUpdate, setIsPostingStateUpdate] =
    useState<boolean>(false);

  async function updateState(e: ChangeEvent<HTMLSelectElement>): Promise<void> {
    const oldState = deviceState;
    const newState = e.currentTarget.value as DeviceState;
    setIsPostingStateUpdate(true);
    setDeviceState(newState);

    try {
      const response = await axios.patch(`${DEVICE_ENDPOINT}/${device.id}`, {
        deviceTypeId: device.deviceTypeId,
        externalReference: device.externalReference,
        address: device.address,
        serial: device.serial,
        attributes: Object.entries(device.attributes ?? {})?.map(
          ([id, value]) => ({
            id,
            value
          })
        ),
        state: newState
      });

      if (!response?.data) {
        throw new Error('No response received. Please try again');
      }

      setDevice({ ...device, state: newState });

      createAlert(
        'Device Updated',
        `${ProvisioningIdPrefix.DEV}-${device.id} state has been overridden from ${oldState} to ${newState}`,
        'success'
      );

      setIsOverridingState(false);
    } catch (err) {
      console.error(err);
      alertError(createAlert, err);
      setDeviceState(oldState);
    } finally {
      setIsPostingStateUpdate(false);
    }
  }

  return (
    <div>
      <div className="card flex flex-col gap-2 sidepanel">
        <div>
          <div className="mb-1">
            <strong>Device State</strong>
          </div>
          <div className="flex gap-2 items-center">
            {isOverridingState ? (
              <>
                <select
                  onChange={async (e) => {
                    await updateState(e);
                  }}
                  disabled={isPostingStateUpdate}
                  className="px-1"
                >
                  {Object.values(DeviceState).map((state) => (
                    <option
                      value={state}
                      key={state}
                      selected={state === deviceState}
                    >
                      {state}
                    </option>
                  ))}
                </select>
                <div className="ml-auto">
                  <Button
                    onClick={() => setIsOverridingState(false)}
                    type="secondary"
                    disabled={isPostingStateUpdate}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Badge
                  type={
                    device.state === DeviceState.IN_DISCOVERY
                      ? undefined
                      : device.state === DeviceState.ERROR
                      ? 'red'
                      : device.state === DeviceState.PROVISIONED
                      ? 'green'
                      : 'blue'
                  }
                >
                  {device.state === DeviceState.IN_DISCOVERY
                    ? 'DISABLED'
                    : device.state === DeviceState.DEPROVISIONING
                    ? 'DISABLING'
                    : device.state}
                </Badge>
                {!!auth.account &&
                  hasAccess(auth.account, [
                    'provisioning:device.override-state'
                  ]) && (
                    <div className="ml-auto">
                      <Button onClick={() => setIsOverridingState(true)}>
                        Override
                      </Button>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
        <div>
          <div className="mb-1">
            <strong>Service State</strong>
          </div>
          <Badge type={device.serviceActive ? 'green' : undefined}>
            {device.serviceActive ? 'ACTIVE' : 'INACTIVE'}
          </Badge>
        </div>
        <div>
          <div className="mb-1">
            <strong>Device Type</strong>
          </div>
          {device.deviceTypeId ? (
            <>
              <Link to={`/provisioning/device-types/${device.deviceTypeId}`}>
                {device.deviceType?.name}{' '}
              </Link>
              <span className="text-muted">
                ({ProvisioningIdPrefix.DVT}-{device.deviceTypeId})
              </span>{' '}
            </>
          ) : (
            <Badge>UNKNOWN</Badge>
          )}
        </div>
        <div>
          <div className="mb-1">
            <strong>Serial</strong>
          </div>
          {device.serial}
        </div>
        <div>
          <div className="mb-1">
            <strong>PON Port</strong>
          </div>
          {device.ponPort ? device.ponPort : <Badge>UNKNOWN</Badge>}
        </div>
        <div>
          <div className="mb-1">
            <strong>ONU Id</strong>
          </div>
          {device.onuId ? device.onuId : <Badge>UNKNOWN</Badge>}
        </div>
        <div>
          <div className="mb-1">
            <strong>OLT</strong>
          </div>
          <Link to={`/provisioning/olts/${device.oltId}`}>
            {device.olt.name}{' '}
          </Link>
          <span className="text-muted">
            ({ProvisioningIdPrefix.OLT}-{device.oltId})
          </span>{' '}
        </div>
      </div>
    </div>
  );
}
