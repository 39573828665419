import { Link } from 'react-router-dom';
import { Badge } from '../../../../shared/components/Badge';
import { ProvisioningIdPrefix } from '../../../../shared/types/ProvisionIdPrefix';
import {
  Service,
  ServiceState
} from '../../../../shared/types/provisioning/Service';

type Props = {
  services: Service[];
  deviceId: number;
};

export function ServicesTable({ services, deviceId }: Props) {
  const filteredServices = services.filter(
    (service) => service.device?.id === deviceId
  );
  return (
    <table>
      <tbody>
        {filteredServices.length > 0 ? (
          filteredServices.map((service) => (
            <tr key={service.id}>
              <td>
                <Link to={`/provisioning/services/${service.id}`}>
                  {ProvisioningIdPrefix.SVC}-{service.id.toString()}
                </Link>
              </td>
              <td>
                {service.product ? (
                  <>
                    <Link
                      to={`/provisioning/products/${service.product.id.toString()}/view`}
                    >
                      {ProvisioningIdPrefix.PRD}-{service.product.id.toString()}
                    </Link>{' '}
                    <span className="text-muted">
                      ({service.product?.name})
                    </span>
                  </>
                ) : (
                  <>(Not found)</>
                )}
              </td>
              <td>
                <Badge
                  type={
                    service.state === ServiceState.NOT_PROVISIONED
                      ? undefined
                      : service.state === ServiceState.ERROR
                        ? 'red'
                        : service.state === ServiceState.PROVISIONED
                          ? 'green'
                          : 'blue'
                  }
                >
                  {service.state}
                </Badge>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="my-2" colSpan={3}>
              No impacted services found.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
